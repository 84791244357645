import { useStore } from '../../helpers/use-store';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Series as SeriesType } from '@monorepo/types';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { SeriesInformation } from './series-information';
import EmptySeries from './create-series/empty-series';

const Series = () => {
  const navigate = useNavigate();
  const {
    dataStore: { seriesStore },
  } = useStore();

  if (seriesStore.items.length === 0) {
    return <EmptySeries />;
  }

  const handleViewSeries = (item: SeriesType) => {
    navigate(`/series/${item._id}`);
  };

  return (
    <Row gutter={[25, 25]}>
      {seriesStore.items.map((item) => (
        <Col sm={24} lg={12} key={item._id}>
          <SeriesInformation
            actions={
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewSeries(item)}
              />
            }
            columns={1}
            series={item}
          />
        </Col>
      ))}
    </Row>
  );
};

export default observer(Series);
