import React, { FunctionComponent } from 'react';

import './voiceover.scss';
import { useStore } from '../../../helpers/use-store';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { VoiceoverList } from '../../../components/voiceover-list/voiceover-list';
import { useNotification } from '../../../hooks/use-notification';

interface Props {
  voiceoverId: string;
}

const VideoVoiceover: FunctionComponent<Props> = ({ voiceoverId }) => {
  const {
    dataStore: { voiceoverStore },
  } = useStore();
  const { updateVoiceover } = useVideoContext();
  const { updateLoadingState, loadingState } = useLoading();
  const notificationApi = useNotification();

  const handleVoiceoverUpdate = async (selectedVoiceId: string) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await updateVoiceover({ voiceoverId: selectedVoiceId });

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Voiceover updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Not enough credits.',
      });

      console.error('failed updating voiceover', e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <VoiceoverList
      voiceoverId={voiceoverId}
      voiceovers={voiceoverStore.items}
      onButtonClick={handleVoiceoverUpdate}
      showUpdateButton
      updateButtonText="Update"
      updateDescription="Updating the voice will regenerate all voices in the video."
      loading={loadingState === LoadingState.Loading}
    />
  );
};

export default VideoVoiceover;
