import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface HttpServiceConfig {
  baseURL?: string;
}

export class HttpService {
  private token = '';
  private axiosInstance: AxiosInstance;
  private readonly MAX_REFRESH_RETRIES = 5;

  constructor({ baseURL }: HttpServiceConfig) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error(`Response error - ${error.code}:`, {
          code: error.code,
          message: error.message,
          url: error.config?.url,
          config: error.config?.data,
          response: JSON.stringify(error.response?.data),
        });
        return Promise.reject(error);
      }
    );
  }

  async get<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
    const response = await this.axiosInstance.get(url, options);

    return response.data as T;
  }

  async post<T, R>(url: string, data?: T, options: AxiosRequestConfig = {}) {
    const response = await this.axiosInstance.post(url, data, options);

    return response.data as R;
  }

  async put<T, R>(url: string, data: T) {
    const response = await this.axiosInstance.put(url, data);

    return response.data as R;
  }

  async delete<R>(url: string) {
    const response = await this.axiosInstance.delete(url);

    return response.data as R;
  }

  removeToken() {
    this.token = '';
    this.axiosInstance.defaults.headers.common['Authorization'] = ``;
  }

  setToken(
    token: string,
    refreshToken: () => Promise<string>,
    adminToken = ''
  ) {
    let isRetried = false,
      retryCounter = 0;
    this.token = token;

    this.axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${this.token}`;

    this.axiosInstance.defaults.headers['X-ADMIN-TOKEN'] = adminToken;

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
          retryCounter++;

          if (isRetried || retryCounter > this.MAX_REFRESH_RETRIES) {
            return Promise.reject(error);
          }

          isRetried = true;

          const newToken = await refreshToken();

          if (!newToken) {
            return;
          }

          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          this.token = newToken;
          isRetried = false;
          retryCounter = 0;
          this.axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${this.token}`;
          return await this.axiosInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }
}
