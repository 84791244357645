import { HttpService } from '@monorepo/client-common';
import UserService from './entities/user-service';
import AuthService from './entities/auth-service';
import SeriesService from './entities/series-service';
import VoiceoverService from './entities/voiceover-service';
import SocialAccountService from './entities/social-account-service';
import VideoService from './entities/video-service';
import AssetService from './entities/asset-service';
import MusicService from './entities/music-service';
import PlanService from './entities/plan-service';
import PaymentService from './entities/payment-service';

export interface ApiFactoryParams {
  httpService: HttpService;
}

class ApiFactory {
  userService: UserService;
  authService: AuthService;
  seriesService: SeriesService;
  voiceoverService: VoiceoverService;
  socialAccountService: SocialAccountService;
  videoService: VideoService;
  assetService: AssetService;
  musicService: MusicService;
  planService: PlanService;
  paymentsService: PaymentService;

  constructor({ httpService }: ApiFactoryParams) {
    this.userService = new UserService({ httpService });
    this.authService = new AuthService({ httpService });
    this.seriesService = new SeriesService({ httpService });
    this.voiceoverService = new VoiceoverService({ httpService });
    this.socialAccountService = new SocialAccountService({ httpService });
    this.videoService = new VideoService({ httpService });
    this.assetService = new AssetService({ httpService });
    this.musicService = new MusicService({ httpService });
    this.planService = new PlanService({ httpService });
    this.paymentsService = new PaymentService({ httpService });
  }
}

export default ApiFactory;
