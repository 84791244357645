import { Avatar, Button, Upload } from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useStore } from '../../../helpers/use-store';
import { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useNotification } from '../../../hooks/use-notification';
import { LoadingState, useLoading } from '@monorepo/react-components';

const AvatarUpload: FunctionComponent = () => {
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { userStore },
  } = useStore();
  const notificationApi = useNotification();

  const handleUpload = async (file: File) => {
    try {
      updateLoadingState(LoadingState.Loading);

      const formData = new FormData();
      formData.append('avatar', file);
      await userStore.uploadAvatar(formData);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Profile image updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed uploading new profile image',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <div className="avatar-upload-container">
      <Avatar
        src={`${userStore.currentUser.profileImage}?date=${Date.now()}`}
        size={100}
        icon={<UserOutlined />}
      />
      <Upload
        accept="image/*"
        showUploadList={false}
        beforeUpload={(file) => {
          handleUpload(file);
          return false; // Prevent default upload behavior
        }}
      >
        <Button
          loading={loadingState === LoadingState.Loading}
          icon={<UploadOutlined />}
        >
          Change Avatar
        </Button>
      </Upload>
    </div>
  );
};

export default observer(AvatarUpload);
