import { IdIdentifier, Timestamps, UserIdentifier } from '../common';

export type Scene = IdIdentifier &
  Timestamps &
  UserIdentifier & {
    videoId: string;
    index: number;
    text: string;
    audioUrl: string;
    audioDuration: number;
    audioFormat: string;
    words: SceneWord[];
    checksum: string;
    assetId: string;
    assetPrompt: string;
    transition?: TransitionEffect;
    panDirection?: PanDirection;
    zoomType?: ZoomType;
  };

export interface SceneWord {
  word: string;
  start: number;
  end: number;
}

export enum TransitionEffect {
  Fade = 'fade',
  Slide = 'slide',
  Wipe = 'wipe',
  Flip = 'flip',
  ClockWipe = 'clockWipe',
  ClockWise = 'clock-wise',
  TestGL = 'test-gl',
  PullIn = 'pull-in',
  ZoomIn = 'zoom-in',
  None = 'none',
}

export enum PanDirection {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
  Circular = 'circular',
  // Shake = 'shake',
  Idle = 'idle',
}

export enum ZoomType {
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
  ZoomInOut = 'zoom-in-out',
  ZoomOutIn = 'zoom-out-in',
  Idle = 'idle',
}
