import { UseFormReturn } from 'react-hook-form';
import {
  AssetOrigin,
  LANGUAGE_METADATA,
  Languages,
  Music,
  VideoSettings,
  Voiceover,
} from '@monorepo/types';
import { Col, Collapse, Row, Typography } from 'antd';
import {
  FormRadioButtonGroup,
  FormSelect,
  FormTextArea,
  Space,
} from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { FormVoiceover } from '../../components/form/form-voiceover';
import {
  AudioOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  GlobalOutlined,
  PictureOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { SAMPLE_SCRIPT } from './video-form-types';
import AssetStyles from '../../components/asset-styles/asset-styles';

import './base-video-form.scss';
import { FormMusic } from '../../components/form/form-music';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { startCase } from 'lodash';
import { useResponsiveReady } from '../../hooks/use-responive-ready';

const { Text } = Typography;
const { Panel } = Collapse;

// Style constants
const sectionTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '8px',
  color: '#1f1f1f',
  fontSize: '16px',
  fontWeight: 500,
};

const iconStyle = {
  fontSize: '20px',
  color: '#1890ff',
};

const sectionStyle = {
  marginBottom: '24px',
};

export const SectionTitle = ({ icon, text }) => (
  <div style={sectionTitleStyle}>
    {icon}
    <span>{text}</span>
  </div>
);

export const SectionBorder = ({ children, style = {} }) => {
  return (
    <div
      style={{
        border: `1px solid #e3e3e3`,
        padding: `5px 0`,
        borderRadius: `6px`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const getLanguageOptions = () => {
  return Object.entries(Languages).map(([name, code]) => ({
    label: `${LANGUAGE_METADATA[code].flag} ${LANGUAGE_METADATA[code].nativeText} (${code})`,
    value: code,
  }));
};

const getSectionsText = (
  form: UseFormReturn<BaseVideoFormType & { subjectType?: string }>,
  voiceOver?: Voiceover,
  music?: Music
) => {
  const contentSectionText = form.getValues('subjectType')
    ? startCase(form.getValues('subjectType'))
    : '';
  const languageSectionText = form.getValues('language')
    ? Object.keys(Languages).find(
        (key) => Languages[key] === form.getValues('language')
      )
    : '';
  const visualStylesSectionText = form.getValues('assetStyle')
    ? startCase(form.getValues('assetStyle'))
    : '';
  const musicSectionText =
    music && form.getValues('backgroundMusicId') ? startCase(music.name) : '';
  const voiceOverSectionText =
    voiceOver && form.getValues('voiceoverId') ? startCase(voiceOver.name) : '';

  return {
    contentSectionText,
    languageSectionText,
    visualStylesSectionText,
    musicSectionText,
    voiceOverSectionText,
  };
};

export type BaseVideoFormType = VideoSettings & { script?: string };

interface Props {
  form: UseFormReturn<BaseVideoFormType & { subjectType?: string }>;
  hideScript?: boolean;
  collapsed?: boolean;
  sectionText?: boolean;
  extraContentSection?: ReactNode;
}

const sections = [
  'content',
  'language',
  'visual-style',
  'voiceover',
  // 'background-music',
];
const BaseVideoForm: FunctionComponent<Props> = ({
  form,
  collapsed,
  hideScript,
  extraContentSection,
  sectionText,
}) => {
  const [expandedSection, setExpandedSections] = useState<string[]>(
    collapsed ? [] : sections
  );
  const {
    dataStore: { voiceoverStore, musicStore },
  } = useStore();
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    if (collapsed) {
      setExpandedSections([]);
    } else {
      setExpandedSections(sections);
    }
  }, [collapsed]);

  const music = musicStore.get(form.getValues('backgroundMusicId') || '');
  const voiceOver = voiceoverStore.get(form.getValues('voiceoverId'));
  const {
    contentSectionText,
    languageSectionText,
    visualStylesSectionText,
    musicSectionText,
    voiceOverSectionText,
  } = getSectionsText(form, voiceOver, music);

  return (
    <div className="base-video-form">
      <Row gutter={[24, 24]} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {/* Main Content Column */}
        <Col
          xs={24}
          lg={24}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {/* Script Section */}
          <Collapse
            activeKey={expandedSection}
            ghost
            onChange={(keys) => setExpandedSections(keys)}
            items={[
              {
                key: 'content',
                label: (
                  <SectionTitle
                    icon={<SettingOutlined style={iconStyle} />}
                    text="Content"
                  />
                ),
                extra: sectionText && (
                  <Text type="secondary">{contentSectionText}</Text>
                ),
                children: (
                  <Space direction="vertical">
                    {extraContentSection}
                    {!hideScript ? (
                      <>
                        <SectionTitle
                          icon={<FileTextOutlined style={iconStyle} />}
                          text="Script"
                        />
                        <FormTextArea
                          form={form}
                          controllerProps={{
                            name: 'script',
                            rules: {
                              required: true,
                              minLength: {
                                message:
                                  'Script must be at least 15 characters',
                                value: 15,
                              },
                            },
                          }}
                          textareaProps={{
                            showCount: true,
                            maxLength: 1500,
                            placeholder: SAMPLE_SCRIPT,
                            rows: 12,
                            style: { width: '100%' },
                          }}
                        />
                      </>
                    ) : null}
                  </Space>
                ),
              },
              {
                key: 'language',
                label: (
                  <SectionTitle
                    icon={<GlobalOutlined style={iconStyle} />}
                    text="Language"
                  />
                ),
                extra: sectionText && (
                  <Text type="secondary">{languageSectionText}</Text>
                ),
                children: (
                  <FormSelect
                    form={form}
                    placeholder="Select language"
                    options={getLanguageOptions()}
                    controllerProps={{
                      name: 'language',
                      rules: { required: true },
                    }}
                  />
                ),
              },
              {
                key: 'visual-style',
                label: (
                  <SectionTitle
                    icon={<PictureOutlined style={iconStyle} />}
                    text="Visual Style"
                  />
                ),
                extra: sectionText && (
                  <Text type="secondary">{visualStylesSectionText}</Text>
                ),
                style: { flex: 1, display: 'flex', flexDirection: 'column' },
                children: (
                  <Space
                    direction="vertical"
                    size="large"
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <FormRadioButtonGroup
                      options={[
                        {
                          text: '🎨 AI Generated Images',
                          value: AssetOrigin.AIImages,
                        },
                      ]}
                      form={form}
                      controllerProps={{
                        name: 'assetOrigin',
                        rules: { required: true },
                      }}
                    />
                    <div>
                      <AssetStyles
                        assetOrigin={form.watch('assetOrigin')}
                        value={form.watch('assetStyle')}
                        onChange={(value) => form.setValue('assetStyle', value)}
                      />
                    </div>
                  </Space>
                ),
              },
              {
                key: 'voiceover',
                label: (
                  <SectionTitle
                    icon={<AudioOutlined style={iconStyle} />}
                    text="Voiceover"
                  />
                ),
                extra: sectionText && (
                  <Text type="secondary">{voiceOverSectionText}</Text>
                ),
                children: (
                  <SectionBorder>
                    <FormVoiceover
                      gridColumns={isMobile ? 1 : 2}
                      form={form}
                      voiceovers={voiceoverStore.items}
                      controllerProps={{
                        name: 'voiceoverId',
                        rules: {
                          required: 'Please select voiceover for your video',
                        },
                      }}
                    />
                  </SectionBorder>
                ),
              },
              {
                key: 'background-music',
                label: (
                  <SectionTitle
                    icon={<CustomerServiceOutlined style={iconStyle} />}
                    text="Background Music"
                  />
                ),
                extra: sectionText && (
                  <Text type="secondary">{musicSectionText}</Text>
                ),
                children: (
                  <SectionBorder>
                    <FormMusic
                      gridColumns={isMobile ? 1 : 2}
                      form={form}
                      musics={musicStore.items}
                      controllerProps={{
                        name: 'backgroundMusicId',
                        rules: { required: false },
                      }}
                    />
                  </SectionBorder>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BaseVideoForm;
