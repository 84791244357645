import React, { FunctionComponent, useRef } from 'react';
import {
  Select,
  Divider,
  Button,
  Flex,
  Empty,
  Typography,
  Avatar,
  Image,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useStore } from '../../helpers/use-store';
import { getEnv } from '../../helpers/mobx-easy-wrapper';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { observer } from 'mobx-react-lite';
import SocialIcon from '../../components/social-provider-icon/social-provider-icon';
import {
  SocialAccountsModal,
  SocialAccountsModalRef,
} from './social-accounts-modal';

export interface SocialAccountSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const SocialAccountSelect: FunctionComponent<SocialAccountSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const {
    envConfig: { youtube_client_id },
  } = getEnv();

  const {
    dataStore: { socialAccountStore },
  } = useStore();
  const modalRef = useRef<SocialAccountsModalRef>(null);

  const handleAddAccount = () => {
    modalRef.current?.open();
  };

  const handleAccountAdded = () => {
    socialAccountStore.fetchAll();
    modalRef.current?.close();
  };

  const socialAccounts = socialAccountStore.items;

  return (
    <>
      <Select
        disabled={disabled}
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        notFoundContent={<Empty description={'No Social Accounts'} />}
        placeholder="Select social account"
        optionLabelProp="label"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />

            <Button
              type="primary"
              icon={<PlusOutlined />}
              block
              onClick={handleAddAccount}
            >
              {'Connect Social Account'}
            </Button>
          </>
        )}
      >
        {socialAccounts.map((account) => {
          const title = (
            <Flex gap={20} align={'center'}>
              <SocialIcon provider={account.provider} />
              <Flex align={'center'} gap={5}>
                <Avatar
                  size={'small'}
                  src={
                    <Image
                      crossOrigin={'anonymous'}
                      referrerPolicy={'no-referrer'}
                      src={account.avatarUrl}
                      preview={false}
                    />
                  }
                />
                <span>{account.title}</span>
              </Flex>
            </Flex>
          );

          return (
            <Select.Option
              disabled={account.tokenInvalid}
              key={account._id}
              value={account._id}
              label={title}
            >
              <Flex style={{ flexDirection: 'column' }}>
                <span>{title}</span>
                {account.tokenInvalid ? (
                  <Typography.Text
                    type={'secondary'}
                    style={{ fontSize: '12px' }}
                  >
                    Need to re-connect account
                  </Typography.Text>
                ) : null}
              </Flex>
            </Select.Option>
          );
        })}
      </Select>

      <GoogleOAuthProvider clientId={youtube_client_id}>
        <SocialAccountsModal
          ref={modalRef}
          onAccountAdded={handleAccountAdded}
        />
      </GoogleOAuthProvider>
    </>
  );
};

export default observer(SocialAccountSelect);
