import { BaseEntityService, BaseResponse } from './base-entity-service';
import { PaginationRequest, PaginationResult } from '@monorepo/types';

export class CrudService<Type = any> extends BaseEntityService {
  async create<T = Type>(payload: Record<string, unknown>): Promise<T> {
    const response = await this.httpService.post<
      Record<string, unknown>,
      BaseResponse<Record<string, unknown>>
    >(`${this.path}`, payload);

    return response.data as T;
  }

  async getAll<T = Type>(queryString = ''): Promise<T[]> {
    let url = `${this.path}`;

    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await this.httpService.get<BaseResponse<T[]>>(url);

    return response.data as T[];
  }

  async paginate<T = Type>(
    paginateRequest: PaginationRequest
  ): Promise<PaginationResult<T>> {
    const url = `${this.path}/paginate`;

    const response = await this.httpService.get<BaseResponse<PaginationResult>>(
      url,
      {
        params: paginateRequest,
      }
    );

    return response.data as PaginationResult<T>;
  }

  async get<T = Type>(id: string) {
    const response = await this.httpService.get<BaseResponse<T>>(
      `${this.path}/${id}`
    );

    return response.data;
  }

  async update<T = Type>(id: string, data: Record<string, unknown>) {
    let url = `${this.path}`;

    if (id) {
      url += `/${id}`;
    }
    const response = await this.httpService.put<
      Record<string, unknown>,
      BaseResponse<Record<string, unknown>>
    >(url, data);

    return response.data as T;
  }

  async delete(id: string) {
    const response = await this.httpService.delete<
      BaseResponse<Record<string, unknown>>
    >(`${this.path}/${id}`);

    return response.data;
  }
}
