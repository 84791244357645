import UserStore from './user/user-store';
import SeriesStore from './series/series-store';
import VoiceoverStore from './voiceover/voiceover-store';
import SocialAccountStore from './social-account/social-account-store';
import VideoStore from './video/video-store';
import AssetStore from './asset/asset-store';
import MusicStore from './music/music-store';
import PlanStore from './plan/plan-store';

class DataStore {
  userStore: UserStore;
  seriesStore: SeriesStore;
  voiceoverStore: VoiceoverStore;
  socialAccountStore: SocialAccountStore;
  videoStore: VideoStore;
  assetStore: AssetStore;
  musicStore: MusicStore;
  planStore: PlanStore;

  constructor() {
    this.userStore = new UserStore();
    this.seriesStore = new SeriesStore();
    this.voiceoverStore = new VoiceoverStore();
    this.socialAccountStore = new SocialAccountStore();
    this.videoStore = new VideoStore();
    this.assetStore = new AssetStore();
    this.musicStore = new MusicStore();
    this.planStore = new PlanStore();
  }
}

export default DataStore;
