import { HttpService } from './http-service';

export interface BaseResponse<T> {
  data: T;
}

interface BaseHttpServiceParams {
  prefix?: string;
  route: string;
  httpService: HttpService;
}

export class BaseEntityService {
  protected httpService: HttpService;
  protected readonly route: string;
  private readonly prefix: string;

  constructor({ route, httpService, prefix }: BaseHttpServiceParams) {
    this.route = route;
    this.prefix = prefix || '';
    this.httpService = httpService;
  }

  protected get path() {
    return `${this.prefix}/${this.route}`;
  }
}
