import { Typography } from 'antd';
import { MusicList } from '../music-list/music-list';
import { Music } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const FormMusic: FunctionComponent<{
  form: UseFormReturn<any>;
  musics: Music[];
  gridColumns: number;
  controllerProps: any;
  text?: string;
}> = ({ form, musics, gridColumns, controllerProps, text }) => {
  return (
    <div>
      {text && <Typography.Text>{text}</Typography.Text>}
      <MusicList
        gridColumns={gridColumns}
        musics={musics}
        shouldDisplayFirst={false}
        musicId={form.watch(controllerProps.name)}
        onMusicSelect={(musicId) => {
          form.setValue(controllerProps.name, musicId);
        }}
      />
    </div>
  );
};
