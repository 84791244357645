import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row, Typography } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  AiAssetStyles,
  AssetOrigin,
  DEFAULT_TIMEZONE,
  Series,
  SeriesSubject,
  SocialAccount,
  SocialProvider,
  Timezone,
  TimezoneLabels,
} from '@monorepo/types';
import './series-form.scss';
import BaseVideoForm, {
  BaseVideoFormType,
  SectionBorder,
  SectionTitle,
} from '../base-video-form/base-video-form';
import {
  FieldNumberOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  SendOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { useStore } from '../../helpers/use-store';
import {
  FormSelect,
  FormSlider,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { FormSocialSelect } from './form-social-select';
import { replace, startCase } from 'lodash';

const { Text } = Typography;

const iconStyle = {
  fontSize: '20px',
  color: '#1890ff',
};
const sections = ['duration', 'social', 'videosPerDay'];

function getExtraContentSection(form: UseFormReturn<Series>) {
  return (
    <FormSelect
      style={{ marginBottom: '15px' }}
      form={form}
      defaultValue={SeriesSubject.RandomAIStory}
      placeholder={'Select a subject'}
      controllerProps={{
        name: 'subjectType',
        rules: { required: 'Please select a series subject' },
      }}
      options={Object.values(SeriesSubject).map((subject) => {
        return {
          label: startCase(replace(subject, '-', ' ')),
          value: subject,
        };
      })}
    />
  );
}

const getSectionsText = (
  form: UseFormReturn<Series>,
  socialAccount?: SocialAccount
) => {
  const socialSectionText =
    form.getValues('socialAccountId') && socialAccount
      ? startCase(socialAccount?.provider)
      : '';
  const durationSectionText = form.getValues('duration')
    ? `${form.getValues('duration')} seconds`
    : '';
  const videosPerDaySectionText = form.getValues('videosPerDay')
    ? `${form.getValues('videosPerDay')}  Videos Per day`
    : '';
  return { socialSectionText, durationSectionText, videosPerDaySectionText };
};

interface Props {
  collapsed?: boolean;
  sectionsText?: boolean;
  loadingButtonText?: ReactNode;
  series?: Series;
  onSubmit: (date: Partial<Series>) => Promise<void>;
}

const SeriesForm: FunctionComponent<Props> = ({
  collapsed,
  series,
  onSubmit,
  sectionsText,
  loadingButtonText,
}) => {
  const {
    dataStore: { voiceoverStore, socialAccountStore },
  } = useStore();

  const [expandedSection, setExpandedSections] = useState<string[]>(
    collapsed ? [] : sections
  );
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);

  const form = useForm<Series>({
    mode: 'all',
    defaultValues: {
      subjectType: SeriesSubject.RandomAIStory,
      assetOrigin: AssetOrigin.AIImages,
      assetStyle: AiAssetStyles.REALISTIC_4K,
      timezone: DEFAULT_TIMEZONE,
      language: 'en',
      duration: 60,
      voiceoverId:
        voiceoverStore.items.find((voiceover) => voiceover.name === 'alloy')
          ?._id || '',
      ...series,
    },
  });

  const _onSubmit = async (data: Partial<Series>) => {
    try {
      updateLoadingState(LoadingState.Loading);

      await onSubmit(data);
    } catch (e) {
      console.error(`failed series submit`, e);
      throw e;
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  // useEffect(() => {
  //   if (collapsed) {
  //     setExpandedSections([]);
  //   } else {
  //     setExpandedSections(sections);
  //   }
  // }, [collapsed]);

  const selectedVideosLength = form.watch('duration');
  const seriesSubject = form.watch('subjectType');
  const socialAccount = socialAccountStore.get(
    form.getValues('socialAccountId')
  );
  const { socialSectionText, durationSectionText, videosPerDaySectionText } =
    getSectionsText(form, socialAccount);

  let frequencyAllowed = [1, 2, 3];

  // TODO: allow increase when fucking google increase youtube quota
  if (socialAccount?.provider === SocialProvider.YouTube) {
    frequencyAllowed = [1];
    form.setValue('videosPerDay', 1);
  }

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Form
      className={'series-form'}
      layout="vertical"
      onFinish={form.handleSubmit(_onSubmit)}
    >
      <Row justify={'center'}>
        <Col span={24}>
          <Card>
            <BaseVideoForm
              sectionText={sectionsText}
              extraContentSection={getExtraContentSection(form)}
              form={form as unknown as UseFormReturn<BaseVideoFormType>}
              hideScript={
                !series?._id && seriesSubject !== SeriesSubject.Custom
              }
              collapsed={collapsed}
            />

            <Collapse
              className="custom-collapse"
              activeKey={expandedSection}
              ghost
              onChange={(keys) => setExpandedSections(keys)}
              items={[
                {
                  key: 'duration',
                  label: (
                    <SectionTitle
                      icon={<FieldTimeOutlined style={iconStyle} />}
                      text="Video Length"
                    />
                  ),
                  extra: sectionsText && (
                    <Text type="secondary">{durationSectionText}</Text>
                  ),
                  children: (
                    <FormSlider
                      label={`Video length: ${selectedVideosLength} seconds`}
                      form={form}
                      props={{
                        step: 5,
                        min: 30,
                      }}
                      controllerProps={{
                        name: 'duration',
                        rules: {
                          min: {
                            value: 30,
                            message: 'Minimum Video length is 30 seconds',
                          },
                          max: {
                            value: 90,
                            message: 'Maximum Video length is 90 seconds',
                          },
                        },
                      }}
                    />
                  ),
                },
                {
                  key: 'social',
                  label: (
                    <SectionTitle
                      icon={<SendOutlined style={iconStyle} />}
                      text="Social Media Account"
                    />
                  ),
                  extra: sectionsText && (
                    <Text type="secondary">{socialSectionText}</Text>
                  ),
                  children: (
                    <FormSocialSelect
                      form={form}
                      controllerProps={{
                        name: 'socialAccountId',
                        rules: { required: 'Please select a social account' },
                      }}
                      onChange={(socialAccountId) => {
                        form.setValue('socialAccountId', socialAccountId);
                        form.trigger('socialAccountId');
                      }}
                    />
                  ),
                },
                {
                  key: 'videosPerDay',
                  label: (
                    <SectionTitle
                      icon={<FieldNumberOutlined style={iconStyle} />}
                      text="Posting Frequency"
                    />
                  ),
                  extra: sectionsText && (
                    <Text type="secondary">{videosPerDaySectionText}</Text>
                  ),
                  children: (
                    <FormSelect
                      form={form}
                      placeholder={'select publication frequency'}
                      controllerProps={{
                        name: 'videosPerDay',
                        rules: {
                          required: 'Please select amount of videos per day',
                        },
                      }}
                      options={frequencyAllowed.map((videosPerDay) => ({
                        value: videosPerDay,
                        label: `${videosPerDay} ${
                          videosPerDay > 1 ? 'Videos' : 'Video'
                        } per day`,
                      }))}
                    />
                  ),
                },
                {
                  key: 'timezone',
                  label: (
                    <SectionTitle
                      icon={<GlobalOutlined style={iconStyle} />}
                      text="Time Zone"
                    />
                  ),
                  extra: sectionsText && (
                    <Text type="secondary">
                      {TimezoneLabels[form.getValues('timezone')]}
                    </Text>
                  ),
                  children: (
                    <FormSelect
                      form={form}
                      placeholder={'Select Timezone'}
                      controllerProps={{
                        name: 'timezone',
                      }}
                      options={Object.entries(Timezone).map(([key, value]) => ({
                        value: value,
                        label: TimezoneLabels[value],
                      }))}
                    />
                  ),
                },
              ]}
            />
          </Card>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Form.Item>
            <div className={'create-button'}>
              <Button
                loading={isLoading}
                disabled={!form.formState.isValid}
                icon={
                  <ThunderboltOutlined
                    style={{ fontSize: '18px', marginTop: '2px' }}
                  />
                }
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                {series ? 'Update Series' : 'Create Series'}
              </Button>
              {isLoading && loadingButtonText ? loadingButtonText : null}
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SeriesForm;
