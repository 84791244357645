import { useForm, UseFormReturn } from 'react-hook-form';
import { AiAssetStyles, AssetOrigin, Video } from '@monorepo/types';
import { Button, Card, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { ThunderboltOutlined } from '@ant-design/icons';

import './video-form.scss';
import { useNavigate } from 'react-router-dom';
import BaseVideoForm, {
  BaseVideoFormType,
} from '../base-video-form/base-video-form';
import { useNotification } from '../../hooks/use-notification';

const VideoForm = () => {
  const {
    dataStore: { voiceoverStore, musicStore, videoStore },
  } = useStore();
  const { updateLoadingState, loadingState } = useLoading();
  const notificationApi = useNotification();
  const navigate = useNavigate();

  const form = useForm<Video>({
    mode: 'all',
    defaultValues: {
      title: Date.now().toString(),
      script: '',
      language: 'en',
      voiceoverId:
        voiceoverStore.items.find((voiceover) => voiceover.name === 'alloy')
          ?._id || '',
      backgroundMusicId: '',
      assetOrigin: AssetOrigin.AIImages,
      assetStyle: AiAssetStyles.REALISTIC_4K,
    },
  });

  const onSubmit = async (data) => {
    updateLoadingState(LoadingState.Loading);

    try {
      console.log('Form submitted:', data);
      const videoId = (await videoStore.create(data)) as unknown as number;

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Video created successfully.',
      });

      navigate(`/videos/${videoId}`);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Not enough credits.',
      });

      console.error(`failed creating video`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <div className="video-form">
      <Card>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <BaseVideoForm
            form={form as unknown as UseFormReturn<BaseVideoFormType>}
          />
          <div className={'create-button'}>
            <Button
              disabled={!form.formState.isValid}
              loading={isLoading}
              icon={
                <ThunderboltOutlined
                  style={{ fontSize: '18px', marginTop: '2px' }}
                />
              }
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              Create Video
            </Button>
            {isLoading ? (
              <Typography.Text
                style={{ display: 'flex', justifyContent: 'center' }}
                type={'secondary'}
              >
                Creating your video... This may take a few minutes ⚡️
              </Typography.Text>
            ) : null}
          </div>
        </form>
      </Card>
    </div>
  );
};

export default VideoForm;
