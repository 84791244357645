import { useForm } from 'react-hook-form';
import { Button, Form, Space } from 'antd';
import { useStore } from '../../../helpers/use-store';
import {
  FormPassword,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { useNotification } from '../../../hooks/use-notification';
import { formPasswordValidation } from '@monorepo/types';

export const UpdatePassword = () => {
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { userStore },
  } = useStore();
  const notificationApi = useNotification();

  const form = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      updateLoadingState(LoadingState.Loading);

      await userStore.updatePassword(data.newPassword);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Password updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed updating password',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const newPassword = form.watch('newPassword');
  const confirmPassword = form.watch('confirmPassword');

  const isPasswordsAreSame = newPassword === confirmPassword;

  return (
    <Form layout="vertical" onFinish={form.handleSubmit(onSubmit)}>
      <Space size={'large'} direction="vertical" style={{ maxWidth: '450px' }}>
        <FormPassword
          text="New Password"
          form={form}
          controllerProps={{
            name: 'newPassword',
            rules: formPasswordValidation(),
          }}
        />

        <FormPassword
          text="Confirm Password"
          form={form}
          controllerProps={{
            name: 'confirmPassword',
            rules: formPasswordValidation(),
          }}
        />

        <Form.Item>
          <Button
            disabled={!form.formState.isDirty || !isPasswordsAreSame}
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
          >
            Update Password
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default UpdatePassword;
