import { IdIdentifier, Timestamps, Timezone, UserIdentifier } from '../common';
import { SubtitlesConfig } from './subtitles';
import { AiAssetStyles, AssetOrigin } from '../asset';
import {
  SocialAccountMetadata,
  SocialProvider,
} from '../social-account/social-account';

export type Video = IdIdentifier &
  Timestamps &
  UserIdentifier &
  VideoSettings & {
    seriesId?: string;
    title: string;
    script?: string;
    thumbnail?: string;
    description?: string;
    status: VideoStatus;
    progress: VideoProgress[];
    videoUrl?: string;
    subtitlesConfig: SubtitlesConfig;
    isRendering: boolean;
    renderProgress: number;
    renderDate?: Date;
    renderStartDate?: Date;
    renderRequested?: boolean;
    renderCounter: number;
    socialPublishInfo?: SocialPublishInfo;
    generatedAssets?: boolean;
    episodeNumber?: number;
    duration: number;
  };

// TEST DEPLOY

export enum VideoProgress {
  Scenes = 'scenes',
  Voiceover = 'voiceover',
  Assets = 'assets',
}

export enum VideoStatus {
  Initial = 'initial',
  Ready = 'ready',
  Published = 'published',
}

export interface VideoSettings {
  voiceoverSpeed: number;
  voiceoverId: string;
  language: string; // language ISO Code
  backgroundMusicId?: string;
  assetOrigin: AssetOrigin;
  assetStyle: VideoAssetStyle;
}

export const supportedAssetsOrigins = [
  AssetOrigin.StockImages,
  AssetOrigin.AIImages,
  AssetOrigin.StockVideos,
];

export type VideoAssetStyle = AiAssetStyles;

export interface SocialPublishInfo {
  socialAccountId: string;
  socialPlatform: SocialProvider;
  publishAt?: Date;
  automaticPublishAt?: Date;
  externalVideoId?: string;
  needsReschedule?: boolean;
  timezone?: Timezone;
  metadata?: SocialAccountMetadata;
}
