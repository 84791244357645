import { AssetType } from '@monorepo/types';
import { FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';

interface Props {
  assetType: AssetType;
}

const AssetTypeIcon: FunctionComponent<Props> = ({ assetType }) => {
  return assetType === AssetType.Image ? (
    <FileImageOutlined />
  ) : (
    <VideoCameraOutlined />
  );
};

export default AssetTypeIcon;
