import { Environment } from '../../helpers/create-store';
import { getEnv } from '../../helpers/mobx-easy-wrapper';

class UiStore {
  initialUrl?: string;

  constructor() {
    const initialUrl = localStorage.getItem('initialUrl');

    this.initialUrl = initialUrl?.replace('#', '');
  }

  get env(): Environment {
    const { envConfig } = getEnv();

    return envConfig;
  }
}

export default UiStore;
