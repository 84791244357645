import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Modal, Row } from 'antd';
import VideoPreview, { VideoPreviewRef } from './video-preview/video-preview';

import './export-modal.scss';
import SocialPublish from './social-publish/social-publish';
import { useVideoContext } from '../../../hooks/use-video';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';

export interface PublishVideoModalRef {
  open: () => void;
  close: () => void;
}

const PublishVideoModal = forwardRef<PublishVideoModalRef>((_, ref) => {
  const { fetchVideoOnly } = useVideoContext();
  const videoPreviewRef = useRef<VideoPreviewRef>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    if (isModalOpen) {
      fetchVideoOnly();
    }
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    videoPreviewRef.current?.endPolling();
  };

  // Expose open/close methods via ref
  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <Modal
      // title="Publish Video"
      open={isModalOpen}
      destroyOnClose={true}
      className={'export-modal'}
      onCancel={closeModal}
      width={1240}
      footer={null}
    >
      <Row gutter={16}>
        {/* Video Preview Column */}
        <Col span={isMobile ? 24 : 10}>
          <VideoPreview isOpen={isModalOpen} ref={videoPreviewRef} />
        </Col>

        {/* Social Media Column */}
        <Col span={isMobile ? 24 : 14}>
          <SocialPublish />
        </Col>
      </Row>
    </Modal>
  );
});

export default PublishVideoModal;
