import { Series } from '@monorepo/types';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../helpers/use-store';
import { useNotification } from '../../../hooks/use-notification';
import SeriesForm from '../../series-form/series-form';
import { Typography } from 'antd';

export const CreateSeriesPage = () => {
  const navigate = useNavigate();
  const notificationApi = useNotification();
  const {
    dataStore: { seriesStore },
  } = useStore();

  const onSubmitHandler = async (data: Partial<Series>) => {
    try {
      const seriesId = (await seriesStore.create(data)) as unknown as number;

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Series created successfully.',
      });

      navigate(`/series/${seriesId}`);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Not enough Series allowed in current plan.',
      });
      console.error(`failed handling series`, e);
    }
  };

  return (
    <SeriesForm
      loadingButtonText={
        <Typography.Text
          style={{ display: 'flex', justifyContent: 'center' }}
          type={'secondary'}
        >
          Creating your series... This may take a few minutes ⚡️
        </Typography.Text>
      }
      onSubmit={onSubmitHandler}
    />
  );
};
