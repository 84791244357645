import {
  interpolate,
  interpolateColors,
  Series,
  spring,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { CaptionSettings, Subtitle, SubtitleWord } from '../remotion-types';
import { makeTransform, scale, translateY } from '@remotion/animation-utils';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { loadGoogleFont } from '../remotion-helpers/google-fonts';
import { first } from 'lodash';

const SubtitleDisplay: React.FC<{
  subtitle: Subtitle;
  settings: CaptionSettings;
  absoluteFrame: number;
  duration: number;
  isFirst?: boolean;
  isRtl?: boolean;
}> = ({
  subtitle,
  settings,
  absoluteFrame,
  duration,
  isFirst = false,
  isRtl = false,
}) => {
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const [fontFamily, setFontFamily] = useState('Arial');
  const requestedFont = settings.fontFamily;

  const loadFont = async () => {
    if (requestedFont) {
      // Basic usage
      const loadedFont = await loadGoogleFont(requestedFont);
      setFontFamily(loadedFont);

      // // Or with options
      // const loadedFontWithOptions = await loadGoogleFont(requestedFont);
      // setFontFamily(loadedFontWithOptions);
    }
  };

  useEffect(() => {
    loadFont();
  }, [requestedFont]);

  // Add entrance animation
  const enter = spring({
    frame: currentFrame,
    fps,
    config: {
      damping: 200,
    },
    durationInFrames: 5,
  });

  const isWordActive = (word: SubtitleWord) => {
    // console.log(
    //   `word: ${word.text}, start: ${word.startTime * fps}/${absoluteFrame}/${
    //     word.endTime * fps
    //   }`
    // );
    return (
      absoluteFrame >= Math.floor(word.startTime * fps) &&
      absoluteFrame <= Math.ceil(word.endTime * fps)
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        direction: isRtl ? 'rtl' : 'ltr',
        justifyContent: 'center',
        alignItems: 'center',
        width: `${settings.textBoxWidthInPercent}%`,
        margin: '0 auto',
        transform: isFirst
          ? 'initial'
          : makeTransform([
              scale(interpolate(enter, [0, 1], [0.8, 1])),
              translateY(interpolate(enter, [0, 1], [30, 0])),
            ]),
      }}
    >
      {subtitle.words.map((word, index) => {
        const isActiveByTime = isWordActive(word);

        const isLast = index === subtitle.words.length - 1;

        const isAllPreviousDisabled = subtitle.words
          .slice(0, -1)
          .every((word) => {
            return !isWordActive(word);
          });

        const prevSub = subtitle.words[index - 1];
        const isPreviousSubPassed = prevSub
          ? prevSub.endTime * fps <= absoluteFrame
          : true;

        const isActive =
          isActiveByTime ||
          (isLast && isAllPreviousDisabled && isPreviousSubPassed); //  && ((currentFrame > (duration / subtitle.words.length) * index  || isAllPreviousDisabled)));

        if (isLast) {
          // console.log(word, prevSub)
          // console.log(absoluteFrame/30, subtitle.words.slice(0, -1))
          // console.log(`${word.text}: isACtiveByTime: ${isActiveByTime}`)
          // console.log(`${word.text}: isAllPreviousDisabled: ${isAllPreviousDisabled}`)
          // console.log(`${word.text}: isPreviousSubPassed: ${isPreviousSubPassed}`)
          // console.log(`${word.text}: currentFrame: ${currentFrame} - ${(duration / subtitle.words.length) * index }`)
        }

        const outlineColor = settings.shadowColor;
        const outlineWidth = settings.shadowBlur || 3;

        // Add word animation
        const wordSpring = spring({
          frame: absoluteFrame,
          fps,
          config: {
            damping: 200,
          },
          durationInFrames: 5,
        });

        const color = interpolateColors(
          wordSpring,
          [0, 1],
          isActive
            ? [
                settings.textColor,
                settings.currentWordColor || settings.textColor,
              ]
            : [settings.textColor, settings.textColor]
        );

        const backgroundColor = interpolateColors(
          wordSpring,
          [0, 1],
          isActive
            ? [
                'transparent',
                settings.currentWordBackgroundColor || 'transparent',
              ]
            : ['transparent', 'transparent']
        );

        return (
          <div
            key={index}
            style={{
              display: 'inline-block',
              fontSize: settings.fontSize,
              fontFamily,
              // fontWeight: settings.fontWeight,
              textShadow: outlineColor
                ? `${outlineWidth}px ${outlineWidth}px 0 ${outlineColor}, -${outlineWidth}px ${outlineWidth}px 0 ${outlineColor}, -${outlineWidth}px -${outlineWidth}px 0 ${outlineColor}, ${outlineWidth}px -${outlineWidth}px 0 ${outlineColor}`
                : 'none',
              padding: '10px 10px 10px 15px',
              color,
              backgroundColor,
              lineHeight: `${settings.fontSize + 40}px`,
              borderRadius: isActive ? '10px' : undefined,
              transform: isActive
                ? makeTransform([
                    scale(interpolate(wordSpring, [0, 1], [0.95, 1.1])),
                    translateY(interpolate(wordSpring, [0, 1], [1, -1])),
                  ])
                : undefined,
            }}
          >
            {settings.uppercase ? word.text.toUpperCase() : word.text}
          </div>
        );
      })}
    </div>
  );
};

export const SubtitleSeries: React.FC<{
  subtitles: Subtitle[];
  settings: CaptionSettings;
  absoluteFrame: number;
  isRtl: boolean;
}> = ({ subtitles, settings, absoluteFrame, isRtl }) => {
  const { fps } = useVideoConfig();

  return (
    <div
      style={{
        position: 'absolute',
        bottom: `${settings.positionY}%`,
        left: 0,
        right: 0,
      }}
    >
      <Series>
        {subtitles.map((subtitle, index) => {
          let durationInFrames = (subtitle.endTime - subtitle.startTime) * fps;

          const nextSubs = subtitles[index + 1];

          if (nextSubs) {
            const isNextStartSame = nextSubs.startTime === subtitle.endTime;

            if (!isNextStartSame) {
              const diff = nextSubs.startTime - subtitle.endTime;

              durationInFrames += diff * fps;
            }
          }

          if (durationInFrames <= 0) {
            return null;
          }

          return (
            <Fragment key={index}>
              <Series.Sequence key={index} durationInFrames={durationInFrames}>
                <SubtitleDisplay
                  isRtl={isRtl}
                  isFirst={index === 0}
                  subtitle={subtitle}
                  duration={durationInFrames}
                  settings={settings}
                  absoluteFrame={absoluteFrame}
                />
              </Series.Sequence>
            </Fragment>
          );
        })}
      </Series>
    </div>
  );
};
