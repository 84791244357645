import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import SocialAccountService from '../../../services/entities/social-account-service';
import { SocialAccount } from '@monorepo/types';

export default class SocialAccountStore extends EntityStore<
  SocialAccountService,
  SocialAccount
> {
  constructor() {
    const {
      apiFactory: { socialAccountService },
    } = getEnv();

    super(socialAccountService);
  }

  async youtubeAuth(code: string): Promise<boolean> {
    return this.service.youtubeAuth(code);
  }

  async tiktokAuth(data: { code: string; state: string }): Promise<boolean> {
    return this.service.tiktokAuth(data);
  }

  async getTiktokUrl(): Promise<string> {
    return this.service.getTiktokAuthUrl();
  }
}
