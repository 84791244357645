import React from 'react';
import { Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Title } = Typography;

const faqItems = [
  {
    key: '1',
    question: 'What is a credit and how does it work?',
    answer:
      'Approximately 10 credits are needed to generate one 1-minute video. You can check the number of credits included in each plan to estimate how many videos you can create. Credits refresh monthly with your subscription.',
  },
  {
    key: '2',
    question: 'Can I upgrade or downgrade my plan at any time?',
    answer: `Yes, you can change your plan at any time. When upgrading, you'll get immediate access to the new features. When downgrading, the changes will take effect in your next billing cycle.`,
  },
  {
    key: '3',
    question: 'What happens if I run out of credits?',
    answer:
      'If you run out of credits, consider upgrading your plan for higher credit limits. Need a custom solution? Our support team is here to help - just reach out through the chat at the bottom of the screen.',
  },
  {
    key: '4',
    question: 'What payment methods do you accept?',
    answer:
      'We process payments through PayPro Global, supporting all payment methods available through their platform.',
  },
  {
    key: '5',
    question: 'Is there a free trial available?',
    answer:
      'We do not offer a free trial as AI technology involves significant computational costs. However, our plans are designed to provide excellent value for the features and capabilities provided.',
  },
  {
    key: '6',
    question: 'What does auto-publish to TikTok/YouTube mean?',
    answer:
      'This upcoming feature will allow you to automatically publish your generated videos directly to your social media accounts. Stay tuned for the official release.',
  },
  {
    key: '7',
    question: 'Credits Usage',
    answer:
      'Your credits reset every month according to your billing cycle. Unused credits do not roll over to the next month.',
  },
  {
    key: '8',
    question: 'How long does payment processing take?',
    answer:
      "Payment processing may take a few minutes to complete. If you don't see your payment reflected immediately, please wait a moment and refresh the page to check your updated status. In any case, you can always contact our support team through the chat at the bottom of the screen for assistance.",
  },
];

export const FAQSection = () => {
  const [expandedKey, setExpandedKey] = React.useState<string | null>(null);

  return (
    <div style={{ padding: '40px 24px' }}>
      <Title level={2} style={{ marginBottom: 32 }}>
        Frequently Asked Questions
      </Title>
      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        {faqItems.map((item) => (
          <div
            key={item.key}
            style={{
              borderBottom:
                item.key !== faqItems[faqItems.length - 1].key
                  ? '1px solid #f0f0f0'
                  : 'none',
            }}
          >
            <div
              onClick={() =>
                setExpandedKey(expandedKey === item.key ? null : item.key)
              }
              style={{
                padding: '16px 24px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#fff',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#fafafa';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#fff';
              }}
            >
              <span style={{ fontSize: 16 }}>{item.question}</span>
              <RightOutlined
                style={{
                  transform:
                    expandedKey === item.key ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </div>
            <div
              style={{
                padding: expandedKey === item.key ? '16px 24px' : '0 24px',
                maxHeight: expandedKey === item.key ? '500px' : '0',
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                background: '#fafafa',
              }}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
