import { Card, Col, Descriptions, Flex, Row, Tag } from 'antd';
import { Space } from '@monorepo/react-components';
import React, { FunctionComponent, ReactNode } from 'react';
import {
  AssetOrigin,
  Languages,
  Series as SeriesType,
  SeriesSubject,
  SocialAccount,
  TimezoneLabels,
  Voiceover,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import SocialIcon from '../../components/social-provider-icon/social-provider-icon';
import { startCase } from 'lodash';

import './series-information.scss';

const getSeriesTypeColor = (type: AssetOrigin) => {
  switch (type) {
    case AssetOrigin.AIImages:
      return 'green';
    case AssetOrigin.StockImages:
      return 'orange';
    default:
      return 'default';
  }
};

function createItemsForSeries(
  item: SeriesType,
  voiceOver: Voiceover | undefined,
  socialAccount: SocialAccount | undefined
) {
  return [
    {
      label: 'Category',
      children: startCase(
        Object.keys(SeriesSubject).find(
          (key) => SeriesSubject[key] === item.subjectType
        )
      ),
    },
    {
      label: 'Language',
      children: Object.keys(Languages).find(
        (key) => Languages[key] === item.language
      ),
    },
    {
      label: 'Voice',
      children: startCase(voiceOver?.name),
    },
    {
      label: 'Videos Length',
      children: `${item.duration} seconds`,
    },
    {
      label: 'Visual Style',
      children: (
        <Tag color={getSeriesTypeColor(item.assetOrigin)}>
          <div>
            {startCase(
              Object.keys(AssetOrigin).find(
                (key) => AssetOrigin[key] === item.assetOrigin
              )
            )}{' '}
          </div>
          <div>{item.assetStyle}</div>
        </Tag>
      ),
    },
    {
      label: 'Social account',
      children: socialAccount ? (
        <Flex align={'center'} gap={8} justify={'center'}>
          <SocialIcon provider={socialAccount?.provider} />
          <span>{socialAccount.title}</span>
        </Flex>
      ) : (
        'No Social Account'
      ),
    },
    {
      label: 'Status',
      children: (
        <Tag color={item.isActive ? 'green' : 'red'}>
          {item.isActive ? 'Active' : 'Disabled'}
        </Tag>
      ),
      span: 1,
    },
    {
      label: 'Posting Frequency',
      children: `${item.videosPerDay || 1}`,
      span: 1,
    },
    {
      label: 'Time Zone',
      children: `${TimezoneLabels[item.timezone]}`,
      span: 1,
    },
  ];
}

interface Props {
  columns?: number;
  series: SeriesType;
  actions?: ReactNode;
}

export const SeriesInformation: FunctionComponent<Props> = ({
  series,
  actions,
  columns = 2,
}) => {
  const {
    dataStore: { voiceoverStore, socialAccountStore },
  } = useStore();

  return (
    <Card
      className={'series-information'}
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Space>
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {series.name}
              </span>
            </Space>
          </Col>
          <Col>
            <Space>{actions ? actions : null}</Space>
          </Col>
        </Row>
      }
    >
      <Descriptions
        column={columns}
        items={createItemsForSeries(
          series,
          voiceoverStore.get(series.voiceoverId),
          socialAccountStore.get(series.socialAccountId)
        )}
      />
    </Card>
  );
};
