import { Avatar, Dropdown, Layout, Image, Button } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';
import { useNavigate } from 'react-router-dom';

const { Header: AntHeader } = Layout;

export interface Props {
  collapsed: boolean;
  setCollapsed: (flag: boolean) => void;
}

const Header: FunctionComponent<Props> = ({ collapsed, setCollapsed }) => {
  const { isMobile } = useResponsiveReady();
  const {
    dataStore: { userStore },
  } = useStore();
  const navigate = useNavigate();

  const avatarDropdownItems = [
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      onClick: () => navigate('/settings/profile'),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: () => userStore.logout(),
    },
  ];

  return (
    <AntHeader>
      {isMobile ? (
        <Button
          type="text"
          style={{ marginLeft: !collapsed ? 200 : 0 }}
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          className="menu-trigger"
        />
      ) : (
        <span></span>
      )}
      <Dropdown
        menu={{ items: avatarDropdownItems }}
        placement="bottomRight"
        arrow
      >
        <Avatar
          size={'large'}
          style={{ border: '1px solid #e3e3e3' }}
          src={
            <Image
              referrerPolicy={'no-referrer'}
              src={`${userStore.currentUser.profileImage}?date=${Date.now()}`}
              preview={false}
            />
          }
        />
      </Dropdown>
    </AntHeader>
  );
};

export default observer(Header);
