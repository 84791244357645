import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Segmented, Typography } from 'antd';
import { Asset, AssetOrigin } from '@monorepo/types';

import './assets-modal.scss';
import CurrentAsset from './tabs/current-asset';
import ManualUpload from './tabs/manual-upload';
import MyAssets from './tabs/my-assets/my-assets';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import StockFootage from './tabs/stock-footage/stock-footage';
import { PartialAsset } from '../../../components/selectable-asset/selectable-asset';
import { useStore } from '../../../helpers/use-store';
import AIImageGenerator from './tabs/ai-image/ai-image';

const { Title, Text } = Typography;

export enum AssetTab {
  Current = 'Current',
  MyAssets = 'My Assets',
  StockMedia = 'Stock Media',
  AiImages = 'AI Images',
  Manual = 'Upload Manually',
}

const tabs = [
  AssetTab.Current,
  AssetTab.MyAssets,
  AssetTab.StockMedia,
  AssetTab.AiImages,
  AssetTab.Manual,
];

interface Props {
  sceneId?: string;
  videoId?: string;
  asset?: Asset;
}

export interface AssetSelectionModalActions {
  open: () => void;
}

const AssetSelectionModal = forwardRef<AssetSelectionModalActions, Props>(
  ({ sceneId, videoId, asset: currentAsset }, ref) => {
    const { loadingState, updateLoadingState } = useLoading();
    const [isOpen, setIsOpen] = useState(false);
    const {
      dataStore: { assetStore },
    } = useStore();
    const [selectedTab, setSelectedTab] = useState(AssetTab.Current);
    const [selectedAsset, setSelectedAsset] = useState<
      Asset | PartialAsset | null
    >(null);
    const { updateScene, getSceneStartFrame } = useVideoContext();

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
    }));

    const handleOk = async () => {
      if (!sceneId || !videoId || !selectedAsset) {
        return;
      }

      updateLoadingState(LoadingState.Loading);

      try {
        let assetId = (selectedAsset as Asset)._id;

        if (!assetId) {
          const uploadedAsset = await assetStore.uploadByUrl({
            url: selectedAsset.url,
            type: selectedAsset.type,
            origin: AssetOrigin.StockImages,
            prompt: selectedAsset.prompt || '',
          });

          assetId = uploadedAsset._id;
        }

        const frame = getSceneStartFrame(sceneId);

        await updateScene({
          sceneId,
          videoId,
          updateDto: {
            assetId,
          },
          frameToSeek: frame,
          shouldUpdateState: true,
        });

        handleCancel();
      } catch (e) {
        console.error(`failed saving`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const handleCancel = () => {
      setIsOpen(false);
      setSelectedTab(AssetTab.Current);
      setSelectedAsset(null);
    };

    const selectExistingAsset = (asset: Asset) => {
      if (currentAsset?._id === asset._id) {
        setSelectedAsset(null);
        return;
      }

      setSelectedAsset(asset);
    };

    const selectNonExistingAssetByUrl = (partialAsset: PartialAsset) => {
      if (currentAsset?.url === partialAsset.url) {
        setSelectedAsset(null);
        return;
      }

      setSelectedAsset(partialAsset as Asset);
    };

    const onAssetUpload = (asset: Asset) => {
      setSelectedAsset(asset);
      setSelectedTab(AssetTab.Current);
    };

    const nowAsset = selectedAsset ? selectedAsset : (currentAsset as Asset);

    return (
      <Modal
        className="assets-modal"
        title={
          <div>
            <Title style={{ marginTop: 0, marginBottom: 0 }} level={4}>
              Assets
            </Title>
            <Text type="secondary" style={{ marginTop: 0 }}>
              Select existing image or generate new one
            </Text>
          </div>
        }
        open={isOpen}
        okText={'Save'}
        onOk={handleOk}
        okButtonProps={{
          disabled: !selectedAsset,
          loading: loadingState === LoadingState.Loading,
        }}
        onCancel={handleCancel}
        width={800}
        style={{ maxHeight: '90vh', overflow: 'auto' }}
      >
        <Segmented
          options={tabs}
          value={selectedTab}
          onChange={(value) => setSelectedTab(value)}
          style={{ marginBottom: 16 }}
        />
        {selectedTab === AssetTab.Current ? (
          <CurrentAsset asset={nowAsset} />
        ) : null}
        {selectedTab === AssetTab.Manual ? (
          <ManualUpload onUpload={onAssetUpload} />
        ) : null}
        {selectedTab === AssetTab.MyAssets ? (
          <MyAssets selectedAsset={nowAsset} onSelect={selectExistingAsset} />
        ) : null}
        {selectedTab === AssetTab.StockMedia ? (
          <StockFootage
            onSelect={selectNonExistingAssetByUrl}
            asset={nowAsset}
          />
        ) : null}
        {selectedTab === AssetTab.AiImages ? (
          <AIImageGenerator
            onSelect={selectNonExistingAssetByUrl}
            asset={nowAsset}
          />
        ) : null}
      </Modal>
    );
  }
);

export default AssetSelectionModal;
