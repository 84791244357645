import React, { FunctionComponent } from 'react';

import './music.scss';
import { useStore } from '../../../helpers/use-store';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { MusicList } from '../../../components/music-list/music-list';

interface Props {
  musicId?: string;
}

const VoiceoverList: FunctionComponent<Props> = ({ musicId }) => {
  const {
    dataStore: { musicStore },
  } = useStore();
  const { updateBackgroundMusic } = useVideoContext();
  const { updateLoadingState, loadingState } = useLoading();

  const handleMusicUpdate = async (selectedMusicId: string) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await updateBackgroundMusic(selectedMusicId);
    } catch (e) {
      console.error('failed updating background music', e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <MusicList
      musicId={musicId}
      musics={musicStore.items}
      onButtonClick={handleMusicUpdate}
      showUpdateButton
      updateButtonText="Update"
      loading={loadingState === LoadingState.Loading}
    />
  );
};

export default VoiceoverList;
