import { useEffect } from 'react';
import axios from 'axios';
import { Flex, Skeleton } from 'antd';

declare global {
  interface Window {
    onTikTokAuthSuccess?: (data: { code: string; state: string }) => void;
    onTikTokAuthError?: (error: string) => void;
  }
}

export default function TikTokCallback() {
  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const state = params.get('state');
      const error = params.get('error');
      const errorDescription = params.get('error_description');

      window.opener.console.log(params);

      try {
        if (error || !code) {
          window.opener.onTikTokAuthError?.(
            errorDescription || 'Authentication failed'
          );
        } else {
          // Send code to your backend
          window.opener.onTikTokAuthSuccess?.({ code, state });
        }
      } catch (error) {
        window.opener.onTikTokAuthError?.('Failed to connect TikTok account');
      } finally {
        // Close the popup
        window.close();
      }
    };

    handleAuth();
  }, []);

  return (
    <Flex
      style={{ width: '100%', height: '100%', justifyContent: 'center' }}
      align={'center'}
    >
      <Skeleton
        style={{ height: '100%' }}
        paragraph={{ rows: 10 }}
        active={true}
      />
    </Flex>
  );
}
