import { Layout, Menu } from 'antd';
import Logo from '../../../../components/logo/logo';
import {
  AudioOutlined,
  CustomerServiceOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  OrderedListOutlined,
  StarOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { VideoPart } from '../../video-editor-types';
import { useVideoContext } from '../../../../hooks/use-video';

const VideoSidebar: FunctionComponent = () => {
  const { currentVideoPart, setCurrentVideoPart } = useVideoContext();

  let actualSelected: VideoPart = currentVideoPart;

  if (currentVideoPart === VideoPart.EditScene) {
    actualSelected = VideoPart.Scenes;
  }

  return (
    <Layout.Sider width={110}>
      <div className="logo">
        <Logo />
      </div>
      <Menu
        selectedKeys={[actualSelected]}
        mode="inline"
        defaultSelectedKeys={[VideoPart.Scenes]}
        onClick={(item) => setCurrentVideoPart(item.key as VideoPart)}
        items={[
          {
            key: VideoPart.Scenes,
            label: 'Scenes',
            icon: <OrderedListOutlined />,
          },
          {
            key: VideoPart.Subtitles,
            label: 'Subtitles',
            icon: <FontSizeOutlined />,
          },
          {
            key: VideoPart.Voiceover,
            label: 'Voiceover',
            icon: <AudioOutlined />,
          },
          // {
          //   key: VideoPart.Effects,
          //   label: 'Effects',
          //   icon: <StarOutlined />,
          // },
          {
            key: VideoPart.Music,
            label: 'Music',
            icon: <CustomerServiceOutlined />,
          },
          {
            key: VideoPart.Metadata,
            label: 'Metadata',
            icon: <InfoCircleOutlined />,
          },
        ]}
      />
    </Layout.Sider>
  );
};

export default VideoSidebar;
