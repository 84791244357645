import { AssetOrigin, VideoAssetStyle } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { AiImageStylesComponent } from '../../features/ai-image-styles/ai-image-styles';

interface Props {
  assetOrigin: AssetOrigin;
  value: string;
  onChange: (value: VideoAssetStyle) => void;
}

const AssetStyles: FunctionComponent<Props> = ({
  assetOrigin,
  value,
  onChange,
}) => {
  const getAssetStyle = () => {
    if (assetOrigin === AssetOrigin.AIImages) {
      return <AiImageStylesComponent value={value} onChange={onChange} />;
    }

    return <div>Not Implemented</div>;
  };
  return <div className="asset-styles">{getAssetStyle()}</div>;
};

export default AssetStyles;
