import React from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import createStore, { Environment } from './helpers/create-store';
import { ErrorBoundary } from './features/error-page';
import { StoreProvider } from './helpers/store-provider';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';

const initApp = async () => {
  let envConfig: Environment;

  try {
    envConfig = await fetch(`/env/${reactEnv}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json());
  } catch {
    try {
      envConfig = await fetch(`/env/development.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res) => res.json());
    } catch (e) {
      console.error('Error fetching configuration:', e);
      return;
    }
  }

  const { rootStore } = createStore({ envConfig });
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    console.warn(`Root element not found`);
    return;
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <ErrorBoundary>
      <StoreProvider value={rootStore}>
        <App />
      </StoreProvider>
    </ErrorBoundary>
  );
};

initApp();
