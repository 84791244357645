import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { Voiceover } from '@monorepo/types';
import VoiceoverService from '../../../services/entities/voiceover-service';

export default class VoiceoverStore extends EntityStore<
  VoiceoverService,
  Voiceover
> {
  constructor() {
    const {
      apiFactory: { voiceoverService },
    } = getEnv();

    super(voiceoverService);
  }
}
