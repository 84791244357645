export enum PrivacyLevel {
  PUBLIC_TO_EVERYONE = 'PUBLIC_TO_EVERYONE',
  MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
  FOLLOWER_OF_CREATOR = 'FOLLOWER_OF_CREATOR',
  SELF_ONLY = 'SELF_ONLY',
}

export const privacyLabels: Record<PrivacyLevel, string> = {
  [PrivacyLevel.PUBLIC_TO_EVERYONE]: 'Everyone can view this video',
  [PrivacyLevel.MUTUAL_FOLLOW_FRIENDS]: 'Mutual followers',
  [PrivacyLevel.FOLLOWER_OF_CREATOR]: 'Followers only',
  [PrivacyLevel.SELF_ONLY]: 'Only me',
};

export interface TiktokMetadata {
  privacyLevels: PrivacyLevel[];
  privacy: PrivacyLevel | null;
  interactions: {
    commentDisabled: boolean;
    duetDisabled: boolean;
    stitchDisabled: boolean;
    comment: boolean;
    duet: boolean;
    stitch: boolean;
  };
  isDisclosureEnabled: boolean;
  brandOptions: {
    yourBrand: boolean;
    brandedContent: boolean;
  };
  maxVideoSecondsAmount: number;
}

export const tiktokDefaultMetadata: TiktokMetadata = {
  privacyLevels: [],
  privacy: null,
  interactions: {
    comment: false,
    duet: false,
    stitch: false,
    duetDisabled: false,
    commentDisabled: false,
    stitchDisabled: false,
  },
  isDisclosureEnabled: false,
  brandOptions: {
    yourBrand: false,
    brandedContent: false,
  },
  maxVideoSecondsAmount: 9999,
};
