import type { MenuProps } from 'antd';
import { Dropdown, Modal } from 'antd';
import {
  CreditCardOutlined,
  DeleteOutlined,
  MoreOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { useStore } from '../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useNotification } from '../../../hooks/use-notification';
import { SubscriptionStatusName } from '@monorepo/types';

type ActionType = 'cancel' | 'renew';

const SubscriptionActions = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState<ActionType>('cancel');
  const { loadingState, updateLoadingState } = useLoading();
  const notificationApi = useNotification();

  const handleAction = async () => {
    try {
      updateLoadingState(LoadingState.Loading);

      if (actionType === 'cancel') {
        await userStore.cancelSubscription();
      } else {
        await userStore.renewSubscription();
      }

      // For Webhook
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await userStore.getLatestUser();

      notificationApi.success({
        placement: 'top',
        message:
          actionType === 'cancel'
            ? 'Subscription cancelled'
            : 'Subscription renewed',
        description:
          actionType === 'cancel'
            ? 'Your subscription has been cancelled. You can continue using your remaining credits until the end of the billing cycle.'
            : 'Your subscription has been renewed successfully. Your account is now active.',
      });
    } catch (e) {
      console.error(`failed ${actionType}ing subscription`, e);
      notificationApi.error({
        placement: 'top',
        message: `${
          actionType === 'cancel' ? 'Cancellation' : 'Renewal'
        } failed`,
        description: `We encountered an error while ${actionType}ing your subscription. Please try again or contact support.`,
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
      setIsModalOpen(false);
    }
  };

  const openModal = (type: ActionType) => {
    setActionType(type);
    setIsModalOpen(true);
  };

  const updatePaymentDetails = async () => {
    try {
      await userStore.updatePaymentDetails();

      notificationApi.success({
        placement: 'top',
        message: 'Link sent',
        description:
          'We have sent you an email with instructions to update your payment details.',
      });
    } catch (e) {
      console.error(`failed sending link to update payment details`, e);
      notificationApi.error({
        placement: 'top',
        message: 'Failed to send link',
        description:
          'We were unable to send the payment update link. Please try again or contact support.',
      });
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'cancel',
      disabled:
        userStore.userSubscription.status !== SubscriptionStatusName.Active,
      label: (
        <span onClick={() => openModal('cancel')}>
          <DeleteOutlined style={{ marginRight: 8 }} />
          Cancel Subscription
        </span>
      ),
      danger: true,
    },
    {
      key: 'renew',
      disabled:
        userStore.userSubscription.status !== SubscriptionStatusName.Suspended,
      label: (
        <span onClick={() => openModal('renew')}>
          <RedoOutlined style={{ marginRight: 8 }} />
          Renew Subscription
        </span>
      ),
    },
    {
      key: 'Update Payment Details',
      disabled: !userStore.userSubscription.subscriptionId,
      label: (
        <span onClick={updatePaymentDetails}>
          <CreditCardOutlined style={{ marginRight: 8 }} />
          Update Payment Details
        </span>
      ),
    },
  ];

  const modalContent = {
    cancel: {
      title: 'Cancel Subscription',
      okText: 'Yes, Cancel',
      cancelText: 'No, Keep',
      content: (
        <>
          <p>Are you sure you want to cancel your subscription?</p>
          <ul>
            <li>
              Your credits and series will be active until the end of the
              billing cycle.
            </li>
          </ul>
          <p>
            If you are missing anything or having issues, please contact us and
            we will try our best to help.
          </p>
        </>
      ),
    },
    renew: {
      title: 'Renew Subscription',
      okText: 'Yes, Renew',
      cancelText: 'No, Thanks',
      content: (
        <>
          <p>Would you like to renew your subscription?</p>
          <ul>
            <li>Your subscription will be reactivated immediately</li>
            <li>
              If your previous billing cycle has ended, you'll be charged
              immediately. Otherwise, your subscription will continue from where
              it left off
            </li>
          </ul>
        </>
      ),
    },
  };

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
        <MoreOutlined
          style={{
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
      </Dropdown>

      <Modal
        title={modalContent[actionType].title}
        open={isModalOpen}
        onOk={handleAction}
        onCancel={() => setIsModalOpen(false)}
        okText={modalContent[actionType].okText}
        cancelText={modalContent[actionType].cancelText}
        okButtonProps={{
          danger: actionType === 'cancel',
          loading: loadingState === LoadingState.Loading,
        }}
      >
        {modalContent[actionType].content}
      </Modal>
    </>
  );
};

export default SubscriptionActions;
