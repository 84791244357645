import { Button, notification, Result, Skeleton } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';

const ConfirmPage = () => {
  const navigate = useNavigate();
  const { confirmCode } = useParams<{ confirmCode: string }>();
  const {
    dataStore: { userStore },
  } = useStore();
  const notificationApi = useNotification();

  useEffect(() => {
    const init = async () => {
      if (confirmCode) {
        try {
          await userStore.confirm(confirmCode);
        } catch (e) {
          notificationApi.error({
            placement: 'top',
            icon: '',
            message: 'Error',
            description: 'Failed confirmation, please contact support.',
          });

          navigate('/login');
        }
      }
    };

    init();
  }, [confirmCode]);

  if (!confirmCode) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back
          </Button>
        }
      />
    );
  }

  return null;
};

export default ConfirmPage;
