import { CrudService, HttpService } from '@monorepo/client-common';
import { Plan } from '@monorepo/types';

const routeName = 'plan';

class PlanService extends CrudService<Plan> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }
}

export default PlanService;
