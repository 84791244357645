import React from 'react';
import { Button, Typography, Tag } from 'antd';
import {
  CheckOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { upperFirst } from 'lodash';
import { useResponsiveReady } from '../../hooks/use-responive-ready';

const { Text } = Typography;

interface VoiceoverListItemProps {
  id: string;
  name: string;
  description?: string;
  audioUrl: string;
  tags?: string[];
  isSelected: boolean;
  isPlaying: boolean;
  onPlay: (id: string, url: string, event: React.MouseEvent) => void;
  onSelect: (id: string) => void;
}

const CardWithSound: React.FC<VoiceoverListItemProps> = ({
  id,
  name,
  description,
  audioUrl,
  tags = [],
  isSelected,
  isPlaying,
  onPlay,
  onSelect,
}) => {
  const { isMobile } = useResponsiveReady();
  const defaultDescription =
    'A calm, confident and enthusiastic middle-aged male voice. Warm bass tones and crisp high tones. Delivery is articulate intelligent and vibrant. The voice is used for voiceovers and educational narrations.';

  return (
    <div
      className={`list-item ${isSelected ? 'selected' : ''}`}
      style={{
        padding: isMobile ? 0 : '15px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flex: 1,
            marginRight: '16px',
          }}
        >
          <Button
            type="text"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '8px',
              flexShrink: 0,
            }}
            icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            onClick={(e) => onPlay(id, audioUrl, e)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              minWidth: 0,
            }}
          >
            <Text strong>{upperFirst(name)}</Text>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                width: '100%',
              }}
            >
              {description || defaultDescription}
            </Text>
          </div>
        </div>
        <Button
          size="small"
          style={{
            width: '100px',
            flexShrink: 0,
          }}
          type={isSelected ? 'primary' : 'default'}
          onClick={() => onSelect(id)}
          icon={isSelected ? <CheckOutlined /> : null}
        >
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </div>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', gap: 5, minHeight: '32px' }}
      >
        {tags.map((tag, index) => (
          <Tag key={index} color="blue">
            {upperFirst(tag)}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default CardWithSound;
