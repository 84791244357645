import { ZoomType } from '@monorepo/types';
import { interpolate } from 'remotion';

export const calculateZoom = (
  zoomType: ZoomType,
  progress: number
): { scale: number } => {
  switch (zoomType) {
    case ZoomType.ZoomIn:
      return { scale: interpolate(progress, [0, 1], [1, 1.2]) };
    case ZoomType.ZoomOut:
      return { scale: interpolate(progress, [0, 1], [1.2, 1]) };
    case ZoomType.ZoomInOut:
      return {
        scale: interpolate(progress, [0, 0.5, 1], [1, 1.2, 1]),
      };
    case ZoomType.ZoomOutIn:
      return {
        scale: interpolate(progress, [0, 0.5, 1], [1.2, 1, 1.2]),
      };
    default:
      return { scale: 1 };
  }
};
