import { importFont } from './google-font-importer';

export const systemFonts = [
  'Arial',
  'Helvetica',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
  'Georgia',
  'Garamond',
  'Courier New',
  'Brush Script MT',
  'Impact',
  'Comic Sans MS',
] as const;

export const googleFonts = [
  'TitanOne',
  'Ranchers',
  'RampartOne',
  'PermanentMarker',
  'LuckiestGuy',
  'Knewave',
  'Jua',
  'Creepster',
  'Caveat',
  'Bungee',
  'BebasNeue',
  'Bangers',
  'BakbakOne',
  'Roboto',
  'OpenSans',
  'Lato',
  'Montserrat',
  'Oswald',
  'Raleway',
  'Merriweather',
  'PTSans',
  'Ubuntu',
  'PlayfairDisplay',
  'FiraSans',
  'NotoSans',
  'SourceSansPro',
  'Nunito',
  'TitilliumWeb',
  'Poppins',
  'Rubik',
  'WorkSans',
  'Quicksand',
  'Karla',
] as const;

// Display name to package name mapping
const displayToPackageName: Record<string, string> = {
  TitanOne: 'TitanOne',
  RampartOne: 'RampartOne',
  PermanentMarker: 'PermanentMarker',
  LuckiestGuy: 'LuckiestGuy',
  'Bebas Neue': 'BebasNeue',
  'Bakbak One': 'BakbakOne',
  'Open Sans': 'OpenSans',
  'PT Sans': 'PTSans',
  'Playfair Display': 'PlayfairDisplay',
  'Fira Sans': 'FiraSans',
  'Noto Sans': 'NotoSans',
  'Source Sans Pro': 'SourceSansPro',
  'Titillium Web': 'TitilliumWeb',
  'Work Sans': 'WorkSans',
};

interface FontLoadOptions {
  style?: 'normal' | 'italic';
  weights?: string[];
  subsets?: string[];
}

// Cache for loaded fonts
const loadedFonts: Record<string, string> = {};

const getPackageName = (fontName: string): string => {
  // If it's in the mapping, use that
  if (displayToPackageName[fontName]) {
    return displayToPackageName[fontName];
  }
  // Otherwise, remove spaces and return the name
  return fontName.replace(/\s+/g, '');
};

export const loadGoogleFont = async (
  fontName: string,
  options?: FontLoadOptions
): Promise<string> => {
  // Return system fonts immediately
  if (systemFonts.includes(fontName as any)) {
    return fontName;
  }

  // Return cached font if available
  const cacheKey = `${fontName}:${JSON.stringify(options || {})}`;
  if (loadedFonts[cacheKey]) {
    return loadedFonts[cacheKey];
  }

  try {
    // Dynamically import the font module
    /* @vite-ignore */
    const fontModule = await importFont(fontName);

    // Load the font with specified options
    const { fontFamily } = fontModule.loadFont();

    // Cache the loaded font
    loadedFonts[cacheKey] = fontFamily;

    return fontFamily;
  } catch (error) {
    console.error(`Failed to load font ${fontName}:`, error);
    return 'Arial'; // Fallback to system font
  }
};

export const loadGoogleFonts = async (
  fonts: Array<{ name: string; options?: FontLoadOptions }>
): Promise<Record<string, string>> => {
  const results: Record<string, string> = {};

  await Promise.all(
    fonts.map(async ({ name, options }) => {
      results[name] = await loadGoogleFont(name, options);
    })
  );

  return results;
};

// Type for all available fonts
export const availableFonts = [
  ...googleFonts.map(
    (font) =>
      displayToPackageName[font] || font.replace(/([A-Z])/g, ' $1').trim()
  ),
  ...systemFonts,
] as const;

export type AvailableFont = (typeof availableFonts)[number];
