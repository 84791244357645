export const checkoutStyles = `
  .ppg-checkout-modal {
    z-index: 99999;
    display: none;
    background-color: rgba(0, 0, 0, 0.45);
    border: 0px none transparent;
    visibility: visible;
    margin: 0px;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .ppg-checkout-modal.ppg-show {
    display: block;
  }
  .ppg-btn-close {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 35px;
    background: rgb(0 0 0 / 35%);
    height: 50px;
    width: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
  }
  .ppg-btn-close.ppg-show {
    display: flex;
  }
  .ppg-btn-close img {
    width: 24px;
  }
  .ppg-iframe {
    width: 100%;
    height: 100%;
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ppg-loader {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ppg-rotation 1s linear infinite;
  }
  @keyframes ppg-rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
