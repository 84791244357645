export type PayProWebhookBody = {
  IPN_TYPE_ID: IPNType; // Refer to the list of IPN types for values
  IPN_TYPE_NAME: string; // Text representation of IPN_TYPE_ID
  ORDER_ID: string;
  ORDER_STATUS_ID:
    | 'Waiting'
    | 'Canceled'
    | 'Refunded'
    | 'Chargeback'
    | 'Processed';
  ORDER_STATUS: string; // Text representation of ORDER_STATUS_ID
  PRODUCT_ID: number;
  PRODUCT_QUANTITY: number;
  ORDER_ITEM_ID: number;
  ORDER_ITEM_NAME: string;
  ORDER_ITEM_TYPE_ID:
    | 1 // Product
    | 2 // BackupMedia
    | 3 // DownloadWarranty
    | 5 // BundledProduct
    | 7 // ProductSelection
    | 8; // ManualProcessingFee
  ORDER_ITEM_TYPE_NAME: string; // Text representation of ORDER_ITEM_TYPE_ID
  VENDOR_BALANCE_CURRENCY_CODE: string;
  ORDER_ITEM_BALANCE_CURRENCY_TOTAL_AMOUNT: number;
  ORDER_ITEM_BALANCE_CURRENCY_VENDOR_AMOUNT: number;
  ORDER_ITEM_BALANCE_CURRENCY_AFFILIATE_AMOUNT: number;
  ORDER_ITEM_BALANCE_CURRENCY_PARTNERS_AMOUNT: number;
  ORDER_ITEM_BALANCE_CURRENCY_PAYPRO_EXPENSES_AMOUNT: number;
  ORDER_ITEM_BALANCE_CURRENCY_REFUNDED: number;
  ORDER_ITEM_BALANCE_CURRENCY_VENDOR_REFUNDED: number;
  ORDER_ITEM_BALANCE_CURRENCY_AFFILIATE_REFUNDED: number;
  ORDER_ITEM_BALANCE_CURRENCY_PARTNERS_REFUNDED: number;
  ORDER_TOTAL_BALANCE_CURRENCY_AMOUNT: number;
  ORDER_BALANCE_CURRENCY_VENDOR_AMOUNT: number;
  ORDER_TAXES_BALANCE_CURRENCY_AMOUNT: number;
  ORDER_BALANCE_CURRENCY_REFUNDED: number;
  ORDER_BALANCE_CURRENCY_VENDOR_REFUNDED: number;
  ORDER_BALANCE_CURRENCY_AFFILIATE_REFUNDED: number;
  ORDER_BALANCE_CURRENCY_PARTNERS_REFUNDED: number;
  ORDER_ITEM_LICENSES?: string; // Product license keys separated by ',\t'
  PAYMENT_METHOD_ID: number; // Refer to the list of payment methods for values
  PAYMENT_METHOD_NAME: string; // Text representation of PAYMENT_METHOD_ID
  ORDER_CURRENCY_CODE: string;
  ORDER_PLACED_TIME_CUSTOMER_TIMEZONE: string;
  CUSTOMER_TIMEZONE: string;
  ORDER_PLACED_TIME_UTC: string;
  ORDER_REFERRER_URL: string;
  ORDER_ITEM_SKU?: string;
  ORDER_ITEMS_COUNT: number;
  ORDER_ITEM_UNIT_PRICE: number;
  ORDER_ITEM_TOTAL_AMOUNT: number;
  ORDER_ITEM_VENDOR_AMOUNT: number;
  ORDER_ITEM_AFFILIATE_AMOUNT: number;
  ORDER_ITEM_PARTNERS_AMOUNT: number;
  ORDER_ITEM_REFUNDED: number;
  ORDER_ITEM_VENDOR_REFUNDED: number;
  ORDER_ITEM_AFFILIATE_REFUNDED: number;
  ORDER_ITEM_PARTNERS_REFUNDED: number;
  ORDER_ITEM_COUPON_DISCOUNT?: number;
  ORDER_ITEM_DYNAMIC_DISCOUNT?: number;
  ORDER_ITEM_LEAD_DISCOUNT?: number;
  COUPON_IS_PERCENTAGE?: string;
  COUPON_DISCOUNT?: string;
  COUPON_NAME?: string;
  ORDER_ITEM_PROMO_DISCOUNT?: number;
  ORDER_ITEM_VOLUME_DISCOUNT?: number;
  ORDER_ITEM_API_DISCOUNT?: number;
  ORDER_ITEM_TOTAL_DISCOUNT?: number;
  ORDER_TOTAL_AMOUNT?: number;
  ORDER_TOTAL_AMOUNT_SHOWN?: number;
  ORDER_TOTAL_AMOUNT_WITH_TAXES_SHOWN?: number;
  ORDER_TAXES_AMOUNT?: number;
  ORDER_ITEM_TAX_NAME?: string; // If taxes are applied
  ORDER_ITEM_TAX_RATE?: number; // If taxes are applied
  ORDER_REFUNDED?: number;
  ORDER_VENDOR_REFUNDED?: number;
  ORDER_AFFILIATE_REFUNDED?: number;
  ORDER_PARTNERS_REFUNDED?: number;
  ORDER_CUSTOM_FIELDS?: string; // Custom fields used in the order
  CUSTOMER_ID: number;
  CUSTOMER_FIRST_NAME: string;
  CUSTOMER_FIRST_NAME_ASCII?: string;
  CUSTOMER_LAST_NAME: string;
  CUSTOMER_LAST_NAME_ASCII?: string;
  CUSTOMER_NAME: string;
  CUSTOMER_NAME_ASCII?: string;
  CUSTOMER_EMAIL: string;
  CUSTOMER_IP: string;
  CUSTOMER_COUNTRY_CODE: string;
  CUSTOMER_COUNTRY_NAME: string;
  CUSTOMER_COUNTRY_CODE_BY_IP?: string;
  CUSTOMER_COUNTRY_NAME_BY_IP?: string;
  CUSTOMER_STATE_CODE?: string;
  CUSTOMER_STATE_NAME?: string;
  CUSTOMER_CITY?: string;
  CUSTOMER_STREET_ADDRESS?: string;
  CUSTOMER_STREET_NUMBER?: string;
  CUSTOMER_ZIPCODE?: string;
  CUSTOMER_PHONE?: string;
  CUSTOMER_FISCAL_NUMBER_PERSONAL?: string;
  CUSTOMER_FISCAL_NUMBER_CORPORATE?: string;
  CORPORATE_PURCHASE?: boolean;
  COMPANY_NAME?: string;
  LICENSED_TO_NAME?: string;
  LICENSED_TO_NAME_ASCII?: string;
  LICENSED_TO_EMAIL?: string;
  ACTION_REASON?: string; // Decline, refund, chargeback, or subscription cancellation reason
  AFFILIATE_AGREEMENT_ID?: number;
  AFFILIATE_NETWORK_ID?: number;
  AFFILIATE_VENDOR_ACCOUNT_ID?: number;
  COUPON_CODE?: string;
  COUPON_ID?: number;
  CREDIT_CARD_BIN?: string;
  CREDIT_CARD_BIN_RESULT?: string;
  CREDIT_CARD_LAST4?: string;
  CREDIT_CARD_EXPIRATION_DATE?: string;
  MAXMIND_RESULT?: string;
  CUSTOM_LICENSE_INFO?: string;
  HASH: string; // IPN verification hash
  SIGNATURE: string; // IPN verification signature
  PAYPAL_ACCOUNT?: string;
  SHIPPING_COUNTRY_CODE?: string;
  SHIPPING_COUNTRY_NAME?: string;
  SHIPPING_STATE_CODE?: string;
  SHIPPING_STATE_NAME?: string;
  SHIPPING_CITY?: string;
  SHIPPING_FIRST_NAME?: string;
  SHIPPING_FIRST_NAME_ASCII?: string;
  SHIPPING_LAST_NAME?: string;
  SHIPPING_LAST_NAME_ASCII?: string;
  SHIPPING_STREET_ADDRESS?: string;
  SHIPPING_ZIPCODE?: string;
  SUBSCRIPTION_ID?: string;
  SUBSCRIPTION_NEXT_CHARGE_DATE?: string;
  SUBSCRIPTION_NEXT_CHARGE_AMOUNT?: number;
  SUBSCRIPTION_NEXT_CHARGE_CURRENCY_CODE?: string;
  SUBSCRIPTION_NEXT_CHARGE_CURRENCY?: 0 | 1;
  SUBSCRIPTION_STATUS_NAME?: SubscriptionStatusName;
  SUBSCRIPTION_STATUS_ID?: string;
  SUBSCRIPTION_INITIAL_ORDER_ID?: number;
  SUBSCRIPTION_RENEWAL_TYPE?: 'Manual' | 'Auto';
  SUBSCRIPTION_NUMBER_OF_BILLING_CYCLES?: number;
  SUBSCRIPTION_NUMBER_OF_FAILED_ATTEMPTS?: number;
  SUBSCRIPTION_CANCELLATION_REASON_ID?:
    | 1 // The price is too high
    | 2 // No longer needed
    | 3 // Not satisfied
    | 4 // Switched to another product
    | 5 // Not a priority
    | 6 // Subscribed by mistake
    | 7 // Other
    | 8; // Switching plans
  SUBSCRIPTION_FINISH_DATE?: string;
  IS_ON_TRIAL_PERIOD?: boolean;
  TRIAL_PERIOD_TILL?: string;
  OPTION_GROUP_IDS?: string[];
  OPTION_GROUP_ITEM_IDS?: string[];
  PRODUCT_COMBINATION_ID?: string;
  PRODUCT_COMBINATION_NAME?: string;
  SELECTION_GROUP_ITEM_IDS?: string[];
  SELECTION_GROUP_ITEM_NAMES?: string[];
  CUSTOMER_LANGUAGE_CODE?: string;
  REGIONAL_PRICE?: boolean;
  INVOICE_LINK?: string;
  BUNDLED_ITEMS_COUNT?: number;
  REFUND_ISSUED_BY?: 'PayPro Global' | 'Vendor';
  TEST_MODE?: boolean;
  CROSS_SELL_MAIN_ITEM_ID?: number;
  CROSS_SELL_ITEM_IDS?: number[];
  IS_CROSS_SELL_ITEM?: boolean;
  CHECKOUT_QUERY_STRING?: string;
  IS_RESENT?: boolean;
  LEAD_TYPE_ID?: 1 | 2;
  LEAD_TYPE_NAME?: string;
  LEAD_CAMPAIGN_ID?: string;
  LEAD_FOLLOW_UP_ID?: string;
};

export enum IPNType {
  OrderCharged = 1, // Triggered when an order has been successfully processed for the initial order of the subscription.
  OrderRefunded = 2, // Triggered when an order has been refunded.
  OrderChargedBack = 3, // Triggered when a chargeback has been initiated for an order.
  OrderDeclined = 4, // Triggered when an order has been declined.
  OrderPartiallyRefunded = 5, // Triggered when an order has been partially refunded.
  SubscriptionChargeSucceed = 6, // Triggered when a recurring payment (order) has been successfully processed.
  SubscriptionChargeFailed = 7, // Triggered when a recurring payment (order) has failed.
  SubscriptionSuspended = 8, // Triggered when a subscription has been suspended by the customer, vendor, or PayPro Global Support Team.
  SubscriptionRenewed = 9, // Triggered when a subscription has been successfully renewed.
  SubscriptionTerminated = 10, // Triggered when a subscription has been terminated.
  SubscriptionFinished = 11, // Triggered when a subscription has ended.
  LicenseRequested = 12, // Triggered when a license key has been requested.
  TrialCharge = 13, // Triggered when a trial charge has been processed.
  OrderChargebackIsWon = 14, // Triggered when a chargeback dispute has been won.
  OrderCustomerInformationChanged = 15, // Triggered when customer information for an order has been changed.
  InstantLeadNotification = 16, // Triggered for instant lead notifications.
}

// Cancellation and termination-related IPN_TYPE_ID values
export const CANCEL_IPN_TYPES: IPNType[] = [
  IPNType.OrderRefunded, // Order refunded
  IPNType.OrderChargedBack, // Chargeback initiated
  IPNType.SubscriptionTerminated, // Subscription terminated
  IPNType.SubscriptionFinished, // Subscription ended
];

export enum SubscriptionStatusName {
  Active = 'Active',
  Suspended = 'Suspended',
  Terminated = 'Terminated',
  Finished = 'Finished',
}
