import { Button, Card, Flex, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import Scenes from '../../scenes/scenes';
import SubtitleStyleEditor from '../../subtitles/subtitles';
import { isEmpty } from 'lodash';
import { baseSubtitles } from '@monorepo/types';
import VideoVoiceover from '../../voiceover/voiceover';
import MusicList from '../../music/music';
import { useVideoContext } from '../../../../hooks/use-video';
import {
  BACK_BY_SCENE,
  TITLE_BY_VIDEO_PAGE,
  VideoPart,
} from '../../video-editor-types';
import EditScene from '../../edit-scene/edit-scene';

import './video-content-container.scss';
import { CaretLeftOutlined } from '@ant-design/icons';
import ReplayScene from '../../scenes/replay-scene/replay-scene';
import VideoMetadata from '../../metadata/video-metadata';

const VideoContentContainer: FunctionComponent = () => {
  const { video, currentVideoPart, setCurrentVideoPart } = useVideoContext();

  if (!video) {
    return null;
  }

  const backPart = BACK_BY_SCENE[currentVideoPart];

  const getComponentByPart = () => {
    if (currentVideoPart === VideoPart.Scenes) {
      return <Scenes />;
    }

    if (currentVideoPart === VideoPart.EditScene) {
      return <EditScene />;
    }

    if (currentVideoPart === VideoPart.Subtitles) {
      return (
        <SubtitleStyleEditor
          subtitlesConfig={
            isEmpty(video.subtitlesConfig)
              ? baseSubtitles
              : video.subtitlesConfig
          }
        />
      );
    }

    if (currentVideoPart === VideoPart.Voiceover) {
      return <VideoVoiceover voiceoverId={video.voiceoverId} />;
    }

    if (currentVideoPart === VideoPart.Music) {
      return <MusicList musicId={video.backgroundMusicId} />;
    }

    if (currentVideoPart === VideoPart.Metadata) {
      return <VideoMetadata />;
    }

    return 'Not Implemented';
  };

  return (
    <Card
      classNames={{
        body: 'scroller',
      }}
      className="video-content-container"
      style={{ minHeight: '100vh', borderRadius: 0 }}
    >
      <Flex justify={'space-between'} align={'center'}>
        <Flex
          justify={'start'}
          align={'start'}
          style={{ margin: '10px 0', flexDirection: 'column' }}
        >
          <Typography.Title
            style={{ marginTop: 0 }}
            underline={true}
            className="content-title"
            level={5}
          >
            {TITLE_BY_VIDEO_PAGE[currentVideoPart]}
          </Typography.Title>
          {backPart ? (
            <Button
              style={{ fontSize: '12px', paddingLeft: 0 }}
              size={'small'}
              onClick={() => setCurrentVideoPart(backPart)}
              type={'text'}
              icon={<CaretLeftOutlined />}
            >
              Back
            </Button>
          ) : null}
          {/*<Typography.Text type='secondary'>Description</Typography.Text>*/}
        </Flex>
        {backPart ? <ReplayScene /> : null}
      </Flex>
      {/*<Divider style={{ margin: '10px 0' }} />*/}
      {getComponentByPart()}
    </Card>
  );
};

export default VideoContentContainer;
