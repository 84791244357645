import { FunctionComponent } from 'react';
import { Space as AntdSpace, SpaceProps } from 'antd';
import classNames from 'classnames';

import './space.scss';

type Props = SpaceProps & {
  fullWidth?: boolean;
};

export const Space: FunctionComponent<Props> = ({
  className,
  fullWidth = true,
  ...antdProps
}) => {
  return (
    <AntdSpace
      {...antdProps}
      className={classNames('shorts-ninja-space', className, {
        'full-width': fullWidth,
      })}
    />
  );
};
