import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import { Asset, SearchResult, Voiceover } from '@monorepo/types';

const routeName = 'asset';

class AssetService extends CrudService<Voiceover> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }

  async upload(file: File) {
    const formData = new FormData();

    formData.append('asset', file);

    const response = await this.httpService.post<FormData, BaseResponse<Asset>>(
      `${this.path}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async uploadByUrl(asset: Pick<Asset, 'url' | 'type' | 'origin' | 'prompt'>) {
    const response = await this.httpService.post<
      Pick<Asset, 'url' | 'type' | 'origin'>,
      BaseResponse<Asset>
    >(`${this.path}/upload-by-url`, asset);

    return response.data;
  }

  async generateAIImage(prompt: string) {
    const response = await this.httpService.post<
      { prompt: string },
      BaseResponse<Asset>
    >(`${this.path}/generate-ai-image`, { prompt });

    return response.data;
  }

  async searchStockFootage(prompt: string) {
    const response = await this.httpService.get<BaseResponse<SearchResult[]>>(
      `${this.path}/search-stock-footage`,
      {
        params: { prompt },
      }
    );

    return response.data;
  }
}

export default AssetService;
