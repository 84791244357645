import { Empty, Flex } from 'antd';
import { Asset } from '@monorepo/types';
import React, { FunctionComponent, useRef, useState } from 'react';
import AssetSelectionModal, {
  AssetSelectionModalActions,
} from '../assets/assets-modal';
import { useVideoContext } from '../../../hooks/use-video';

import './scenes.scss';
import SceneItem from './scene/scene';

const Scenes: FunctionComponent = () => {
  const [selectAsset, setAssets] = useState<Asset>();
  const modalRef = useRef<AssetSelectionModalActions>(null);
  const { scenes, setScene, selectedScene } = useVideoContext();

  if (scenes.length === 0) {
    return <Empty description={'No Scenes yet'} />;
  }

  let currentTime = 0;

  return (
    <div className="scenes">
      <Flex vertical gap="large">
        {scenes.map((scene, index) => {
          const isLast = index === scenes.length - 1;
          const sceneTime = currentTime;
          currentTime += scene.audioDuration;

          return (
            <SceneItem
              key={scene._id}
              sceneId={scene._id}
              sceneNumber={index + 1}
              currentTime={sceneTime}
              displayDivider={!isLast}
              onAssetClick={(scene, asset) => {
                modalRef.current?.open();
                setAssets(asset);
                setScene(scene);
              }}
            />
          );
        })}
        {/*{scenes.map((scene, sceneIndex) => {*/}
        {/*  const firstAsset = scenesAssets.find(*/}
        {/*    (asset) => asset._id === scene.assetId*/}
        {/*  );*/}

        {/*  const sceneStartTime = currentDuration;*/}
        {/*  const sceneEndTime = sceneStartTime + scene.audioDuration;*/}

        {/*  const isLast = sceneIndex === scenes.length - 1;*/}

        {/*  currentDuration += scene.audioDuration;*/}

        {/*  return (*/}
        {/*    <div className="scene" key={scene._id}>*/}
        {/*      <Flex align={'baseline'} justify={'space-between'}>*/}
        {/*        <Flex align={'baseline'}>*/}
        {/*          <Typography.Text*/}
        {/*            style={{ marginRight: '15px' }}*/}
        {/*            type={'secondary'}*/}
        {/*            underline={true}*/}
        {/*          >*/}
        {/*            {sceneIndex + 1}*/}
        {/*          </Typography.Text>*/}
        {/*          <span style={{ flex: 4, maxWidth: '400px' }}>*/}
        {/*            <div style={{ marginBottom: '15px' }}>*/}
        {/*              <Tag style={{ borderRadius: '5px' }}>*/}
        {/*                In {sceneStartTime.toFixed(2)}s*/}
        {/*              </Tag>*/}
        {/*              <Tag style={{ borderRadius: '5px' }}>*/}
        {/*                Out {sceneEndTime.toFixed(2)}s*/}
        {/*              </Tag>*/}
        {/*            </div>*/}
        {/*          </span>*/}
        {/*        </Flex>*/}
        {/*        <Flex align={'baseline'}>*/}
        {/*          <Button*/}
        {/*            type={'text'}*/}
        {/*            onClick={() => {*/}
        {/*              setCurrentVideoPart(VideoPart.EditScene);*/}
        {/*              setScene(scene);*/}
        {/*              setAssets(firstAsset);*/}
        {/*            }}*/}
        {/*            shape={'round'}*/}
        {/*            icon={<EditOutlined />}*/}
        {/*          />*/}
        {/*        </Flex>*/}
        {/*      </Flex>*/}
        {/*      <Flex justify="start" align="start">*/}
        {/*        /!*<Text underline={true}>{sceneIndex + 1}</Text>*!/*/}
        {/*        /!*<Input.TextArea value={scene.text} style={{ flex: 1, marginRight: 16, maxWidth: '300px' }} />*!/*/}

        {/*        <div*/}
        {/*          style={{ marginRight: '25px' }}*/}
        {/*          className="scene-image"*/}
        {/*          onClick={() => {*/}
        {/*            modalRef.current?.open();*/}
        {/*            setAssets(firstAsset);*/}
        {/*            setScene(scene);*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <AssetComponent*/}
        {/*            width={'140px'}*/}
        {/*            height={'250px'}*/}
        {/*            preview={false}*/}
        {/*            asset={firstAsset as Asset}*/}
        {/*          />*/}
        {/*          /!*<ImagePreview*!/*/}
        {/*          /!*  src={imageSrc}*!/*/}
        {/*          /!*  alt="scene"*!/*/}
        {/*          /!*  style={{ width: '140px', height: '250px' }}*!/*/}
        {/*          /!*/
        /*/}
        {/*          <div className="scene-image-overlay">*/}
        {/*            <EditOutlined className="scene-image-edit" />*/}
        {/*            <div style={{ color: '#fff' }}>Edit</div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <Text>{scene.text}</Text>*/}
        {/*      </Flex>*/}

        {/*      {isLast ? null : <Divider style={{ marginBottom: 0 }} />}*/}
        {/*    </div>*/}
        {/*  );*/}
        {/*})}*/}
      </Flex>
      <AssetSelectionModal
        asset={selectAsset}
        ref={modalRef}
        sceneId={selectedScene?._id}
        videoId={selectedScene?.videoId}
      />
    </div>
  );
};

export default Scenes;
