import React, { FunctionComponent } from 'react';
import { message, Skeleton, Upload, UploadFile } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload/interface';
import { useStore } from '../../../../helpers/use-store';
import { Asset } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';

const { Dragger } = Upload;

interface Props {
  onUpload: (asset: Asset) => void;
}

const UploadAndDisplayImage: FunctionComponent<Props> = ({ onUpload }) => {
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore },
  } = useStore();

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp' ||
      file.type === 'video/mp4' ||
      file.type === 'video/quicktime';
    if (!isAllowedType) {
      message.error(
        'You can only upload JPG/PNG/WebP image or MP4/MOV video files!'
      );
    }
    const isLt50M = (file.size || 0) / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('File must be smaller than 50MB!');
    }
    return isAllowedType && isLt50M;
  };

  const handleUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;

    const isValid = beforeUpload(file);

    if (!isValid) {
      return;
    }

    try {
      updateLoadingState(LoadingState.Loading);

      const asset = await assetStore.upload(file as any);

      onUpload(asset);
      message.success(`${file.name} file uploaded successfully`);
    } catch (e) {
      message.error(`${file.name} file upload failed.`);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <div>
      <Dragger
        name="asset"
        multiple={false}
        beforeUpload={() => false}
        onChange={handleUpload}
        style={{
          border: '1px dashed #d9d9d9',
          borderRadius: '2px',
          background: '#fafafa',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton active={true} />
        ) : (
          <>
            <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
            <p style={{ marginTop: '8px', color: '#666' }}>
              Click or drag file to this area to upload
            </p>
          </>
        )}
      </Dragger>
    </div>
  );
};

export default UploadAndDisplayImage;
