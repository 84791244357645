import { useVideoContext } from '../../../hooks/use-video';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Button, Card, Form, Space } from 'antd';
import { FormInput, FormTextArea } from '@monorepo/react-components';
import { Video } from '@monorepo/types';

const VideoMetadata = () => {
  const { video, updateMetadata } = useVideoContext();
  const form = useForm<Pick<Video, 'title' | 'description'>>({
    defaultValues: {
      title: '',
      description: '',
    },
  });
  const {
    formState: { errors },
  } = form;

  useEffect(() => {
    if (video) {
      form.reset({
        title: video.title || '',
        description: video.description || '',
      });
    }
  }, [video?._id]);

  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      if (video) {
        updateMetadata({
          title: value.title || video.title,
          description: value.description || video.description,
        });
      }
    });
    return () => unsubscribe();
  }, [form.watch]);

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <div className="video-metadata">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <span>Title:</span>
          <FormTextArea
            form={form}
            controllerProps={{
              name: 'title',
            }}
            placeholder="Enter title"
            textareaProps={{
              maxLength: 100,
              showCount: true,
              rows: 4,
              status: errors.description ? 'error' : undefined,
            }}
          />

          <span>Description(TikTok Title):</span>
          <FormTextArea
            form={form}
            controllerProps={{
              name: 'description',
            }}
            placeholder="Enter description"
            textareaProps={{
              rows: 25,
              maxLength: 1000,
              showCount: true,
              status: errors.description ? 'error' : undefined,
            }}
          />
        </Space>
      </form>
    </div>
  );
};

export default VideoMetadata;
