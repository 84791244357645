import React, { useCallback, useEffect, useState } from 'react';
import { Slider } from 'antd';
import { interpolate } from 'remotion';
import { useVideoContext } from '../../../../hooks/use-video';

export const SeekBar: React.FC<{
  durationInFrames: number;
  inFrame?: number | null;
  outFrame?: number | null;
}> = ({ durationInFrames, inFrame = 0, outFrame }) => {
  const { playerRef, isPlayerReady } = useVideoContext();
  const [playing, setPlaying] = useState(false);
  const [frame, setFrame] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  // Listen for frame updates from the player
  useEffect(() => {
    const { current } = playerRef;
    if (!current) return;

    const onFrameUpdate = () => {
      setFrame(current.getCurrentFrame());
    };

    current.addEventListener('frameupdate', onFrameUpdate);
    return () => {
      current.removeEventListener('frameupdate', onFrameUpdate);
    };
  }, [isPlayerReady]);

  // Listen for play/pause state
  useEffect(() => {
    const { current } = playerRef;
    if (!current) return;

    const onPlay = () => setPlaying(true);
    const onPause = () => setPlaying(false);

    current.addEventListener('play', onPlay);
    current.addEventListener('pause', onPause);

    return () => {
      current.removeEventListener('play', onPlay);
      current.removeEventListener('pause', onPause);
    };
  }, [isPlayerReady]);

  // Handle slider changes
  const handleSliderChange = useCallback(
    (value: number) => {
      if (!playerRef.current) return;
      setIsDragging(true);
      playerRef.current.pause();

      const frame = Math.round(
        interpolate(value, [0, 100], [0, Math.max(durationInFrames - 1, 0)], {
          extrapolateLeft: 'clamp',
          extrapolateRight: 'clamp',
        })
      );

      playerRef.current.seekTo(frame);
    },
    [durationInFrames]
  );

  // Handle the completion of sliding
  const handleChangeComplete = useCallback(
    (value: number) => {
      if (!playerRef.current) return;
      setIsDragging(false);
      if (playing) {
        playerRef.current.play();
      }
    },
    [playing]
  );

  // Calculate current value as percentage
  const currentValue = Math.round(
    interpolate(frame, [0, Math.max(durationInFrames - 1, 0)], [0, 100], {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    })
  );

  // Calculate marks for in/out points if they exist
  const marks = {
    ...(inFrame
      ? {
          [interpolate(inFrame, [0, durationInFrames - 1], [0, 100])]: 'In',
        }
      : {}),
    ...(outFrame
      ? {
          [interpolate(outFrame, [0, durationInFrames - 1], [0, 100])]: 'Out',
        }
      : {}),
  };

  return (
    <Slider
      value={currentValue}
      onChange={handleSliderChange}
      onChangeComplete={handleChangeComplete}
      marks={marks}
      step={1}
      min={0}
      max={100}
      tooltip={{
        open: false,
        formatter: (value) =>
          Math.round(
            interpolate(value as number, [0, 100], [0, durationInFrames - 1])
          ),
      }}
      style={{ width: '150px' }}
      styles={{
        track: {
          // backgroundColor: '#000',
        },
        rail: {
          // backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
        handle: {
          // borderColor: '#000',
          // opacity: isDragging ? 1 : undefined,
        },
      }}
    />
  );
};
