import { Button, Flex, Layout, Menu, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { layoutItems, useCurrentPage } from '../../../hooks/use-current-page';
import Logo from '../../../components/logo/logo';
import { useStore } from '../../../helpers/use-store';
import { CrownOutlined, DesktopOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';

const { Sider } = Layout;
const { Text } = Typography;

interface Props {
  collapsed: boolean;
  setCollapsed: (flag: boolean) => void;
}

const Navbar: FunctionComponent<Props> = ({ collapsed, setCollapsed }) => {
  const {
    dataStore: { userStore, seriesStore },
  } = useStore();
  const navigate = useNavigate();
  const { firstPage: currentPage } = useCurrentPage();
  const { isMobile } = useResponsiveReady();

  return (
    <Sider collapsedWidth={0} collapsed={isMobile ? collapsed : false}>
      <div className="logo">
        <Logo />
        <Text style={{ marginLeft: '15px' }} strong>
          ShortsNinja
        </Text>
      </div>
      <Menu
        selectedKeys={[currentPage]}
        mode="inline"
        defaultSelectedKeys={['Home']}
        onClick={(item) => {
          navigate(`/${item.key}`);
          setCollapsed(true);
        }}
        items={layoutItems
          .filter((item) => !item.hidden)
          .map((item) => {
            const { createButton, ...cleanItem } = item;

            return cleanItem;
          })}
      />
      <Flex
        justify={'center'}
        align={'center'}
        style={{ flexDirection: 'column' }}
        className="subscription-info"
      >
        <div className="subscription-info-item">
          <CrownOutlined />
          Credits: {userStore.userSubscription.credits}
        </div>
        <div className="subscription-info-item">
          <DesktopOutlined />
          Allowed Series: {seriesStore.items.length}/
          {userStore.userSubscription.allowedSeriesAmount}
        </div>
        <div className={'subscription-info-item'}>
          {userStore.userSubscription.credits < 50 ? (
            <Button
              onClick={() => navigate('/settings/billing')}
              type={'primary'}
              style={{ width: '80%', marginTop: '10px' }}
            >
              Upgrade
            </Button>
          ) : (
            <span style={{ marginTop: '10px' }}></span>
          )}
        </div>
      </Flex>
    </Sider>
  );
};

export default observer(Navbar);
