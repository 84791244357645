import {
  BaseEntityService,
  BaseResponse,
  HttpService,
} from '@monorepo/client-common';
import { ClientUser, LoginRequest, RegisterRequest } from '@monorepo/types';

class ClientAuthService extends BaseEntityService {
  protected override route = 'auth';

  constructor({ httpService }: { httpService: HttpService }) {
    const route = 'auth';

    super({ route: 'auth', httpService });

    this.route = route;
  }

  async register(data: RegisterRequest): Promise<boolean> {
    const url = `${this.path}/register`;

    const result = await this.httpService.post<
      RegisterRequest,
      BaseResponse<boolean>
    >(url, data);

    return result.data;
  }

  async confirm(token: string): Promise<ClientUser> {
    const url = `${this.path}/confirm`;

    const result = await this.httpService.post<
      { token: string },
      BaseResponse<ClientUser>
    >(url, { token });

    return result.data;
  }

  async forgetPassword(email: string): Promise<boolean> {
    const url = `${this.path}/forget-password`;

    const result = await this.httpService.post<
      { email: string },
      BaseResponse<boolean>
    >(url, { email });

    return result.data;
  }

  async resetPassword(token: string, password: string): Promise<boolean> {
    const url = `${this.path}/reset-password`;

    const result = await this.httpService.post<
      { token: string; password: string },
      BaseResponse<boolean>
    >(url, { token, password });

    return result.data;
  }

  async login(data: Partial<LoginRequest>): Promise<ClientUser> {
    const url = `${this.path}/login`;

    const result = await this.httpService.post<
      Partial<LoginRequest>,
      BaseResponse<ClientUser>
    >(url, data);

    return result.data;
  }

  async googleCallback(token: string): Promise<ClientUser> {
    const url = `${this.path}/google/callback`;

    const result = await this.httpService.post<
      { token },
      BaseResponse<ClientUser>
    >(url, { token });

    return result.data;
  }

  // Logout method
  async logout(): Promise<boolean> {
    const url = `${this.path}/logout`;

    const result = await this.httpService.post<null, BaseResponse<boolean>>(
      url
    );

    return result.data;
  }
}

export default ClientAuthService;
