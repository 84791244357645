import { useGoogleLogin } from '@react-oauth/google';
import { useStore } from '../../../helpers/use-store';
import { useNotification } from '../../../hooks/use-notification';

interface UseYouTubeAuthProps {
  onSuccess?: () => void;
}

export const useYouTubeAuth = ({ onSuccess }: UseYouTubeAuthProps = {}) => {
  const {
    dataStore: { socialAccountStore },
  } = useStore();
  const notificationApi = useNotification();

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: [
      'https://www.googleapis.com/auth/youtube.force-ssl',
      'https://www.googleapis.com/auth/youtube.upload',
      'https://www.googleapis.com/auth/youtube.readonly',
    ].join(' '),
    onSuccess: async (codeResponse) => {
      try {
        const response = await socialAccountStore.youtubeAuth(
          codeResponse.code
        );
        if (response) {
          notificationApi.success({
            placement: 'top',
            icon: '',
            message: 'Success',
            description: 'YouTube Account added successfully',
          });

          onSuccess?.();
        }
      } catch (error) {
        console.error('YouTube auth error:', error);
      }
    },
    onError: (errorResponse) => {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed adding YouTube account, please contact support.',
      });
      console.error('Google login error:', errorResponse);
    },
  });

  return { handleAuth: login };
};
