import { createContext, useContext } from 'react';
import type { NotificationInstance } from 'antd/es/notification/interface';

export const NotificationContext = createContext<NotificationInstance>(
  {} as NotificationInstance
);
export const NotificationProvider = NotificationContext.Provider;

export const useNotification = () => {
  return useContext(NotificationContext);
};
