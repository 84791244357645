import { useForm } from 'react-hook-form';
import { Button, Form, Space } from 'antd';
import { useStore } from '../../../helpers/use-store';
import { formEmailValidation, User } from '@monorepo/types';
import {
  FormInput,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { useNotification } from '../../../hooks/use-notification';

export const UserInfo = () => {
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { userStore },
  } = useStore();
  const notificationApi = useNotification();

  const form = useForm<Pick<User, 'firstName' | 'lastName' | 'email'>>({
    defaultValues: {
      firstName: userStore.currentUser.firstName,
      lastName: userStore.currentUser.lastName,
      email: userStore.currentUser.email,
    },
  });

  const onSubmit = async (
    data: Pick<User, 'firstName' | 'lastName' | 'email'>
  ) => {
    try {
      updateLoadingState(LoadingState.Loading);

      await userStore.updateDetails(data);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Profile details updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed uploading profile details',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <Form layout="vertical" onFinish={form.handleSubmit(onSubmit)}>
      <Space size={'large'} direction="vertical" style={{ maxWidth: '450px' }}>
        <FormInput
          text="First Name"
          form={form}
          controllerProps={{
            name: 'firstName',
            rules: { required: 'First name is required' },
          }}
        />

        <FormInput
          text="Last Name"
          form={form}
          controllerProps={{
            name: 'lastName',
            rules: { required: 'Last name is required' },
          }}
        />

        <FormInput
          text="Email"
          form={form}
          controllerProps={{
            name: 'email',
            rules: formEmailValidation(),
          }}
        />

        <Form.Item>
          <Button
            disabled={!form.formState.isDirty}
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
          >
            Update Profile
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
