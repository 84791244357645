import React from 'react';
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from '@react-oauth/google';
import { useStore } from '../../../helpers/use-store';
import { useNotification } from '../../../hooks/use-notification';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';

const GoogleLoginButton: React.FC = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const notificationApi = useNotification();

  const handleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    try {
      if (!credentialResponse.credential) {
        throw new Error(`missing credential`);
      }

      await userStore.googleCallback(credentialResponse.credential);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed google auth, please contact support.',
      });
    }
  };

  const handleLoginError = () => {
    console.error('Login Failed');
  };

  return (
    <GoogleLogin
      width={280}
      onSuccess={handleLoginSuccess}
      onError={handleLoginError}
    />
  );
};

const GoogleLoginComponent: React.FC = () => {
  const {
    envConfig: { google_client_id },
  } = getEnv();

  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <div className="google-container">
        <GoogleLoginButton />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
