export enum Languages {
  Afrikaans = 'af',
  Arabic = 'ar',
  Armenian = 'hy',
  Azerbaijani = 'az',
  Belarusian = 'be',
  Bosnian = 'bs',
  Bulgarian = 'bg',
  Catalan = 'ca',
  Chinese = 'zh',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  Galician = 'gl',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Icelandic = 'is',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Kannada = 'kn',
  Kazakh = 'kk',
  Korean = 'ko',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Macedonian = 'mk',
  Malay = 'ms',
  Marathi = 'mr',
  Maori = 'mi',
  Nepali = 'ne',
  Norwegian = 'no',
  Persian = 'fa',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Slovak = 'sk',
  Slovenian = 'sl',
  Spanish = 'es',
  Swahili = 'sw',
  Swedish = 'sv',
  Tagalog = 'tl',
  Tamil = 'ta',
  Thai = 'th',
  Turkish = 'tr',
  Ukrainian = 'uk',
  Urdu = 'ur',
  Vietnamese = 'vi',
  Welsh = 'cy',
}

interface LanguageMetadata {
  name: string;
  flag: string;
  nativeText: string;
}

// Mapping of language codes to their metadata including flags
export const LANGUAGE_METADATA: Record<Languages, LanguageMetadata> = {
  [Languages.Afrikaans]: {
    name: 'Afrikaans',
    flag: '🇿🇦',
    nativeText: 'Afrikaans',
  },
  [Languages.Arabic]: { name: 'Arabic', flag: '🇸🇦', nativeText: 'العربية' },
  [Languages.Armenian]: { name: 'Armenian', flag: '🇦🇲', nativeText: 'Հայերեն' },
  [Languages.Azerbaijani]: {
    name: 'Azerbaijani',
    flag: '🇦🇿',
    nativeText: 'Azərbaycan',
  },
  [Languages.Belarusian]: {
    name: 'Belarusian',
    flag: '🇧🇾',
    nativeText: 'Беларуская',
  },
  [Languages.Bosnian]: { name: 'Bosnian', flag: '🇧🇦', nativeText: 'Bosanski' },
  [Languages.Bulgarian]: {
    name: 'Bulgarian',
    flag: '🇧🇬',
    nativeText: 'Български',
  },
  [Languages.Catalan]: {
    name: 'Catalan',
    flag: '🏴󠁥󠁳󠁣󠁴󠁿',
    nativeText: 'Català',
  },
  [Languages.Chinese]: { name: 'Chinese', flag: '🇨🇳', nativeText: '中文' },
  [Languages.Croatian]: {
    name: 'Croatian',
    flag: '🇭🇷',
    nativeText: 'Hrvatski',
  },
  [Languages.Czech]: { name: 'Czech', flag: '🇨🇿', nativeText: 'Čeština' },
  [Languages.Danish]: { name: 'Danish', flag: '🇩🇰', nativeText: 'Dansk' },
  [Languages.Dutch]: { name: 'Dutch', flag: '🇳🇱', nativeText: 'Nederlands' },
  [Languages.English]: { name: 'English', flag: '🇬🇧', nativeText: 'English' },
  [Languages.Estonian]: { name: 'Estonian', flag: '🇪🇪', nativeText: 'Eesti' },
  [Languages.Finnish]: { name: 'Finnish', flag: '🇫🇮', nativeText: 'Suomi' },
  [Languages.French]: { name: 'French', flag: '🇫🇷', nativeText: 'Français' },
  [Languages.Galician]: { name: 'Galician', flag: '🇪🇸', nativeText: 'Galego' },
  [Languages.German]: { name: 'German', flag: '🇩🇪', nativeText: 'Deutsch' },
  [Languages.Greek]: { name: 'Greek', flag: '🇬🇷', nativeText: 'Ελληνικά' },
  [Languages.Hebrew]: { name: 'Hebrew', flag: '🇮🇱', nativeText: 'עברית' },
  [Languages.Hindi]: { name: 'Hindi', flag: '🇮🇳', nativeText: 'हिन्दी' },
  [Languages.Hungarian]: {
    name: 'Hungarian',
    flag: '🇭🇺',
    nativeText: 'Magyar',
  },
  [Languages.Icelandic]: {
    name: 'Icelandic',
    flag: '🇮🇸',
    nativeText: 'Íslenska',
  },
  [Languages.Indonesian]: {
    name: 'Indonesian',
    flag: '🇮🇩',
    nativeText: 'Bahasa Indonesia',
  },
  [Languages.Italian]: { name: 'Italian', flag: '🇮🇹', nativeText: 'Italiano' },
  [Languages.Japanese]: { name: 'Japanese', flag: '🇯🇵', nativeText: '日本語' },
  [Languages.Kannada]: { name: 'Kannada', flag: '🇮🇳', nativeText: 'ಕನ್ನಡ' },
  [Languages.Kazakh]: { name: 'Kazakh', flag: '🇰🇿', nativeText: 'Қазақ' },
  [Languages.Korean]: { name: 'Korean', flag: '🇰🇷', nativeText: '한국어' },
  [Languages.Latvian]: { name: 'Latvian', flag: '🇱🇻', nativeText: 'Latviešu' },
  [Languages.Lithuanian]: {
    name: 'Lithuanian',
    flag: '🇱🇹',
    nativeText: 'Lietuvių',
  },
  [Languages.Macedonian]: {
    name: 'Macedonian',
    flag: '🇲🇰',
    nativeText: 'Македонски',
  },
  [Languages.Malay]: { name: 'Malay', flag: '🇲🇾', nativeText: 'Bahasa Melayu' },
  [Languages.Marathi]: { name: 'Marathi', flag: '🇮🇳', nativeText: 'मराठी' },
  [Languages.Maori]: { name: 'Maori', flag: '🇳🇿', nativeText: 'Māori' },
  [Languages.Nepali]: { name: 'Nepali', flag: '🇳🇵', nativeText: 'नेपाली' },
  [Languages.Norwegian]: { name: 'Norwegian', flag: '🇳🇴', nativeText: 'Norsk' },
  [Languages.Persian]: { name: 'Persian', flag: '🇮🇷', nativeText: 'فارسی' },
  [Languages.Polish]: { name: 'Polish', flag: '🇵🇱', nativeText: 'Polski' },
  [Languages.Portuguese]: {
    name: 'Portuguese',
    flag: '🇵🇹',
    nativeText: 'Português',
  },
  [Languages.Romanian]: { name: 'Romanian', flag: '🇷🇴', nativeText: 'Română' },
  [Languages.Russian]: { name: 'Russian', flag: '🇷🇺', nativeText: 'Русский' },
  [Languages.Serbian]: { name: 'Serbian', flag: '🇷🇸', nativeText: 'Српски' },
  [Languages.Slovak]: { name: 'Slovak', flag: '🇸🇰', nativeText: 'Slovenčina' },
  [Languages.Slovenian]: {
    name: 'Slovenian',
    flag: '🇸🇮',
    nativeText: 'Slovenščina',
  },
  [Languages.Spanish]: { name: 'Spanish', flag: '🇪🇸', nativeText: 'Español' },
  [Languages.Swahili]: { name: 'Swahili', flag: '🇰🇪', nativeText: 'Kiswahili' },
  [Languages.Swedish]: { name: 'Swedish', flag: '🇸🇪', nativeText: 'Svenska' },
  [Languages.Tagalog]: { name: 'Tagalog', flag: '🇵🇭', nativeText: 'Tagalog' },
  [Languages.Tamil]: { name: 'Tamil', flag: '🇮🇳', nativeText: 'தமிழ்' },
  [Languages.Thai]: { name: 'Thai', flag: '🇹🇭', nativeText: 'ไทย' },
  [Languages.Turkish]: { name: 'Turkish', flag: '🇹🇷', nativeText: 'Türkçe' },
  [Languages.Ukrainian]: {
    name: 'Ukrainian',
    flag: '🇺🇦',
    nativeText: 'Українська',
  },
  [Languages.Urdu]: { name: 'Urdu', flag: '🇵🇰', nativeText: 'اردو' },
  [Languages.Vietnamese]: {
    name: 'Vietnamese',
    flag: '🇻🇳',
    nativeText: 'Tiếng Việt',
  },
  [Languages.Welsh]: { name: 'Welsh', flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', nativeText: 'Cymraeg' },
};

// Helper function to get language options with flags for select component

// Helper function to get full language info
export const getLanguageInfo = (code: Languages): LanguageMetadata => {
  return LANGUAGE_METADATA[code];
};

export const isRTL = (languageCode: Languages): boolean => {
  const rtlLanguages = [
    Languages.Arabic,
    Languages.Hebrew,
    Languages.Persian,
    Languages.Urdu,
  ];
  return rtlLanguages.includes(languageCode);
};
