import React, { useCallback, useEffect } from 'react';
import { useVideoContext } from '../../../hooks/use-video';
import { buildConfig, RemotionPlayer } from '@monorepo/remotion-ninja';

import './video-player.scss';
import { Divider, Flex } from 'antd';
import PlayPause from './controls/play-pause';
import { divider } from '../video-layout/video-header/video-header';
import { calculateDurationInFrames } from '@monorepo/remotion-ninja';
import { TimeDisplay } from './controls/time-display';
import { SeekBar } from './controls/seek-bar';
import { VolumeSlider } from './controls/volume';
import { MuteButton } from './controls/mute';
import { useStore } from '../../../helpers/use-store';
import { Languages } from '@monorepo/types';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';

const VideoPlayer = () => {
  const {
    dataStore: { musicStore },
  } = useStore();
  const { video, scenes, assets, playerRef, setPlayerReady } =
    useVideoContext();
  const { isMobile } = useResponsiveReady();

  const music = musicStore.items.find(
    (music) => music._id === video?.backgroundMusicId
  );

  const config = buildConfig({
    backgroundMusicUrl: music?.audioUrl,
    subtitlesConfig: video?.subtitlesConfig,
    scenes,
    assets,
    language: video?.language as Languages,
  });

  const myRef = useCallback((node) => {
    if (node !== null) {
      playerRef.current = node; // Update state or trigger any action
      setPlayerReady(true);
    }
  }, []);

  console.log('config', config);

  const durationInFrames = calculateDurationInFrames(config);

  return (
    <div
      className="video-player"
      style={{
        position: 'relative',
        marginTop: isMobile ? 0 : '50px',
      }}
    >
      <div>
        <RemotionPlayer
          width={isMobile ? 'auto' : '500px'}
          ref={myRef}
          config={config}
        />
      </div>
      <div className="controls">
        <Flex justify={'center'} align={'center'}>
          <PlayPause />
          {divider}
          <TimeDisplay durationInFrames={durationInFrames} />
          {divider}
          <SeekBar durationInFrames={durationInFrames} />
          {divider}
          <MuteButton />
        </Flex>
      </div>
    </div>
  );
};

export default VideoPlayer;
