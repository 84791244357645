import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { Music } from '@monorepo/types';
import MusicService from '../../../services/entities/music-service';

export default class MusicStore extends EntityStore<MusicService, Music> {
  constructor() {
    const {
      apiFactory: { musicService },
    } = getEnv();

    super(musicService);
  }
}
