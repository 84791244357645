import { IdIdentifier, Timestamps, UserIdentifier } from '../common';
import { tiktokDefaultMetadata, TiktokMetadata } from './tiktok-types';

export type SocialAccount = IdIdentifier &
  Timestamps &
  UserIdentifier & {
    provider: SocialProvider;
    accessToken?: string;
    refreshToken?: string;
    expiresAt: Date;
    avatarUrl?: string;
    externalId: string;
    title: string;
    tokenInvalid: boolean;
    metadata: SocialAccountMetadata;
  };

export enum SocialProvider {
  TikTok = 'tik-tok',
  Instagram = 'instagram',
  YouTube = 'youtube',
  Facebook = 'facebook',
}

export type SocialAccountMetadata = TiktokMetadata | any;

export const getDefaultSocialAccountMetadata = (provider: SocialProvider) => {
  if (provider === SocialProvider.TikTok) {
    return tiktokDefaultMetadata;
  }

  return {};
};
