import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';
import Login from '../features/auth/login';
import Register from '../features/auth/register';
import Confirm from '../features/auth/confirm';
import ForgetPassword from '../features/auth/forget-password';
import ResetPassword from '../features/auth/reset-password';
import TikTokCallback from '../features/social-accounts/tiktok/tiktok-callback';

export const loggedOutRouter = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'confirm/:confirmCode',
    element: <Confirm />,
  },
  {
    path: 'forget-password',
    element: <ForgetPassword />,
  },
  {
    path: 'reset-password/:confirmCode',
    element: <ResetPassword />,
  },
  {
    path: 'tiktok-callback',
    element: <TikTokCallback />,
  },
  {
    path: 'logout',
    element: <div>Logout</div>,
  },
  {
    path: 'forgot-password',
    element: <div>forgot</div>,
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  },
]);
