import { IdIdentifier, Timestamps } from '../common';

export type Plan = IdIdentifier &
  Timestamps & {
    name: string;
    videosPerMonth: number;
    price: number;
    credits: number;
    billingCycle: BillingCycle;
    series: number;
    externalId: string;
    features: string[];
    isActive: boolean;
  };

export enum BillingCycle {
  Monthly = 'monthly',
  Annual = 'annual',
  OneTime = 'one-time',
}
