import React, { FunctionComponent, useRef, useState } from 'react';
import { Button, List, Typography } from 'antd';
import { Music } from '@monorepo/types';
import CardWithSound from '../card-with-sound/card-with-sound';

import './music-list.scss';

interface MusicListProps {
  musicId?: string;
  shouldDisplayFirst?: boolean;
  gridColumns?: number;
  musics: Music[];
  onMusicSelect?: (musicId: string) => void;
  onButtonClick?: (musicId: string) => void;
  showUpdateButton?: boolean;
  updateButtonText?: string;
  updateDescription?: string;
  loading?: boolean;
}

export const sortItemsById = <T extends { _id: string }>(
  items: T[],
  priorityId?: string
): T[] => {
  if (!items || items.length === 0) {
    return [];
  }

  return [...items].sort((a, b) => {
    if (!priorityId) return 0;
    if (a._id === priorityId) return -1;
    if (b._id === priorityId) return 1;
    return 0;
  });
};

export const MusicList: FunctionComponent<MusicListProps> = ({
  musicId,
  musics,
  onMusicSelect,
  onButtonClick,
  gridColumns = 1,
  shouldDisplayFirst = true,
  showUpdateButton = false,
  updateButtonText = 'Update',
  updateDescription,
  loading = false,
}) => {
  const [selectedMusic, setSelectedMusic] = useState<string | null>(
    musicId || null
  );
  const [playingMusic, setPlayingMusic] = useState<string | null>(null);
  const [speed, setSpeed] = useState<number>(1);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const selectMusic = (id: string) => {
    setSelectedMusic(id);
    onMusicSelect && onMusicSelect(id);
  };

  const toggleAudio = (id: string, url: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (playingMusic === id) {
      audioRef.current?.pause();
      setPlayingMusic(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(url);
      audioRef.current.playbackRate = speed;
      audioRef.current.play();
      audioRef.current.onended = () => setPlayingMusic(null);
      setPlayingMusic(id);
    }
  };

  const sortedItems = shouldDisplayFirst
    ? sortItemsById(musics, musicId)
    : musics;

  return (
    <div
      className="music"
      style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
    >
      <div className="music-content scroller">
        <List
          grid={{
            gutter: 5,
            column: gridColumns,
          }}
          dataSource={sortedItems}
          renderItem={(item) => (
            <List.Item
              className={`list-item ${
                selectedMusic === item._id ? 'selected' : ''
              }`}
              style={{
                padding: '10px 15px 15px 15px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 0,
                alignItems: 'stretch',
              }}
            >
              <CardWithSound
                id={item._id}
                name={item.name}
                description={item.description}
                audioUrl={item.audioUrl}
                tags={item.tags}
                isSelected={selectedMusic === item._id}
                isPlaying={playingMusic === item._id}
                onPlay={toggleAudio}
                onSelect={selectMusic}
              />
            </List.Item>
          )}
        />
      </div>

      {showUpdateButton && (
        <>
          <Button
            disabled={selectedMusic === musicId}
            loading={loading}
            onClick={() =>
              selectedMusic && onButtonClick && onButtonClick(selectedMusic)
            }
            type="primary"
            style={{ width: '100%' }}
          >
            {updateButtonText}
          </Button>
          {updateDescription && (
            <Typography.Text type="secondary">
              {updateDescription}
            </Typography.Text>
          )}
        </>
      )}
    </div>
  );
};
