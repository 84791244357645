import { wrapRoot } from 'mobx-easy';
import RootStore from '../stores/root-store';
import ApiFactory from '../services/api-factory';
import { HttpService } from '@monorepo/client-common';

export interface Environment {
  api_end_point: string;
  google_client_id: string;
  youtube_client_id: string;
}

export interface RootEnv {
  envConfig: Environment;
  apiFactory: ApiFactory;
}

export interface CreateStoreResult {
  rootStore: RootStore;
  env: RootEnv;
}

export interface CreateStoreOptions {
  envConfig: Environment;
}

const createStore = ({ envConfig }: CreateStoreOptions): CreateStoreResult => {
  const httpService = new HttpService({ baseURL: envConfig.api_end_point });
  const apiFactory = new ApiFactory({ httpService });

  const env: RootEnv = {
    apiFactory,
    envConfig,
  };

  const rootStore = wrapRoot({ RootStore: RootStore, env });

  return {
    rootStore,
    env,
  };
};

export default createStore;
