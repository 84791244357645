import React, { FunctionComponent, useState } from 'react';
import { Input, message, Space, Button } from 'antd';
import { Asset } from '@monorepo/types';
import { useStore } from '../../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import SelectableAssetList from '../../../../../components/selectable-asset/selectable-asset-list';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';

interface Props {
  asset: Asset | PartialAsset;
  onSelect: (partialAsset: PartialAsset) => void;
}

const AIImageGenerator: FunctionComponent<Props> = ({ asset, onSelect }) => {
  const [generatedAsset, setGeneratedAsset] = useState<Asset | null>(null);
  const [prompt, setPrompt] = useState(asset.prompt || '');
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore },
  } = useStore();

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      message.warning('Please enter a prompt for the AI image');
      return;
    }

    updateLoadingState(LoadingState.Loading);
    try {
      const result = await assetStore.generateAIImage(prompt);

      setGeneratedAsset(result);
    } catch (error) {
      console.error('Error generating AI image:', error);
      message.error('Not enough credits.');
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Input.TextArea
        rows={4}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the image you want the AI to generate..."
      />
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={handleGenerate}
        loading={isLoading}
      >
        Generate AI Image
      </Button>
      {generatedAsset && (
        <SelectableAssetList
          height={'370px'}
          currentAsset={asset}
          assets={[{ ...generatedAsset, prompt }]}
          onSelect={onSelect}
        />
      )}
    </Space>
  );
};

export default AIImageGenerator;
