import type { PlayerRef } from '@remotion/player';
import React, { useEffect, useState } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { AudioMutedOutlined, AudioOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const MuteButton = () => {
  const { playerRef, isPlayerReady } = useVideoContext();
  const [muted, setMuted] = useState(playerRef.current?.isMuted() ?? false);

  const onClick = React.useCallback(() => {
    if (!playerRef.current) {
      return;
    }

    if (playerRef.current.isMuted()) {
      playerRef.current.unmute();
    } else {
      playerRef.current.mute();
    }
  }, [isPlayerReady]);

  useEffect(() => {
    const { current } = playerRef;
    if (!current) {
      return;
    }

    const onMuteChange = () => {
      setMuted(current.isMuted());
    };

    current.addEventListener('mutechange', onMuteChange);
    return () => {
      current.removeEventListener('mutechange', onMuteChange);
    };
  }, [isPlayerReady]);

  return (
    <Button type="text" onClick={onClick}>
      {muted ? <AudioMutedOutlined /> : <AudioOutlined />}
    </Button>
  );
};
