import { Asset, AssetType } from '@monorepo/types';
import ImagePreview from '../image-preview/image-preview';
import VideoAssetPreview from '../video-asset-preview/video-asset-preview';
import React, { FunctionComponent, memo } from 'react';
import { Avatar } from 'antd';
import AssetTypeIcon from '../asset-type-icon/asset-type-icon';
import { PartialAsset } from '../selectable-asset/selectable-asset';
import LazyLoad from 'react-lazy-load';

interface Props {
  asset?: Asset | PartialAsset;
  preview?: boolean;
  width?: string;
  height?: string;
  shouldLazy?: boolean;
}

const AssetComponent: FunctionComponent<Props> = ({
  asset,
  shouldLazy = true,
  preview = true,
  width = '100%',
  height = '350px',
}) => {
  if (!asset) {
    return null;
  }

  const { type, url } = asset;

  const renderAsset = () => {
    if (type === AssetType.Image) {
      return (
        <ImagePreview
          key={url}
          src={url}
          preview={preview}
          alt="image"
          style={{
            width,
            height,
            objectFit: 'cover',
            marginBottom: '5px',
          }}
        />
      );
    }
    if (type === AssetType.Video) {
      return (
        <VideoAssetPreview key={url} width={width} height={height} url={url} />
      );
    }
    return null;
  };

  return (
    <div style={{ position: 'relative' }} className="asset-component">
      <Avatar
        size={'small'}
        style={{ position: 'absolute', zIndex: 1, top: 15, right: 15 }}
      >
        <AssetTypeIcon assetType={type} />
      </Avatar>
      {shouldLazy ? (
        <LazyLoad height={height} width={width}>
          {renderAsset()}
        </LazyLoad>
      ) : (
        renderAsset()
      )}
    </div>
  );
};

export default memo(AssetComponent, (oldProps, newProps) => {
  return oldProps?.asset?.url === newProps?.asset?.url;
});
