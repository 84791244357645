import React, { useMemo } from 'react';
import { AbsoluteFill, interpolate, Easing } from 'remotion';
import type {
  TransitionPresentation,
  TransitionPresentationComponentProps,
} from '@remotion/transitions';

export type RotateCwOneProps = {
  exitRotation?: number;
  enterRotation?: number;
  exitZoomFactor?: number;
  enterZoomFactor?: number;
};

const RotateCwOnePresentation: React.FC<
  TransitionPresentationComponentProps<RotateCwOneProps>
> = ({
  children,
  presentationProgress,
  presentationDirection,
  passedProps,
}) => {
  const { exitRotation = 80, enterRotation = -80 } = passedProps;

  const animationMidPoint = 0.4;

  const style: React.CSSProperties = useMemo(() => {
    if (presentationDirection === 'exiting') {
      const rotation = interpolate(
        presentationProgress,
        [0, animationMidPoint],
        [0, exitRotation],
        { extrapolateRight: 'clamp', easing: Easing.in(Easing.poly(4)) }
      );
      const scale = interpolate(
        presentationProgress,
        [0, animationMidPoint],
        [1, 0.5],
        { extrapolateRight: 'clamp', easing: Easing.in(Easing.poly(4)) }
      );
      return {
        transform: `rotate(${rotation}deg) scale(${scale})`,
        opacity: presentationProgress < animationMidPoint ? 1 : 0,
      };
    } else {
      const rotation = interpolate(
        presentationProgress,
        [animationMidPoint, 1],
        [enterRotation, 0],
        { extrapolateLeft: 'clamp', easing: Easing.out(Easing.poly(4)) }
      );
      const scale = interpolate(
        presentationProgress,
        [animationMidPoint, 1],
        [0.5, 1],
        { extrapolateLeft: 'clamp', easing: Easing.out(Easing.poly(4)) }
      );
      return {
        transform: `rotate(${rotation}deg) scale(${scale})`,
        opacity: presentationProgress >= animationMidPoint ? 1 : 0,
      };
    }
  }, [
    presentationDirection,
    presentationProgress,
    exitRotation,
    enterRotation,
  ]);

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>;
};

export const rotateCwOne = (
  props?: RotateCwOneProps
): TransitionPresentation<RotateCwOneProps> => {
  return {
    component: RotateCwOnePresentation,
    props: props ?? {},
  };
};
