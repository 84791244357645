// GLPresentationComponent.tsx
import { Node, Shaders, GLSL } from 'gl-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AbsoluteFill,
  continueRender,
  delayRender,
  interpolate,
} from 'remotion';
import type { TransitionPresentationComponentProps } from '@remotion/transitions';
import type { TransitionPresentation } from '@remotion/transitions';
import { Surface, Texture } from 'gl-react-dom';
import GLTransitions from 'gl-transitions';
import { LinearCopy, NearestCopy } from 'gl-react';
import GLTransition from './gl-code/gl-transition';

export type CustomGLPresentationProps = {
  width: number;
  height: number;
  fromSrc: string;
  toSrc: string;
  transitionName: string; // GLSL shader code to load dynamically
  uniforms?: Record<string, any>; // Optional additional uniforms (e.g., zoom_quickness)
};

const GLPresentationComponent: React.FC<
  TransitionPresentationComponentProps<CustomGLPresentationProps>
> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps,
}) => {
  const [fromCanvas, setFromCanvas] = useState<HTMLCanvasElement | null>(null);
  const [toCanvas, setToCanvas] = useState<HTMLCanvasElement | null>(null);
  // const [handle] = useState(() => delayRender());
  const [isReady, setReady] = useState(false);
  const { transitionName, uniforms = {}, width, height } = passedProps;

  const transition = GLTransitions.find((t) => t.name === transitionName);

  console.log(passedProps);
  const fromTexture = passedProps.fromSrc;
  const toTexture = passedProps.toSrc;

  console.log(presentationProgress);
  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();

    img1.crossOrigin = 'Anonymous';
    img2.crossOrigin = 'Anonymous';

    Promise.all([
      new Promise<HTMLCanvasElement>((resolve) => {
        img1.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img1.width;
          canvas.height = img1.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img1, 0, 0);
          resolve(canvas);
        };
        img1.src = fromTexture;
      }),
      new Promise<HTMLCanvasElement>((resolve) => {
        img2.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img2.width;
          canvas.height = img2.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img2, 0, 0);
          resolve(canvas);
        };
        img2.src = toTexture;
      }),
    ]).then(([fromCanvasLoaded, toCanvasLoaded]) => {
      console.log('loaded canvases');
      setFromCanvas(fromCanvasLoaded);
      setToCanvas(toCanvasLoaded);
      // continueRender(handle);
      setReady(true);
    });
  }, [fromTexture, toTexture]);

  console.log(children);

  // if (presentationProgress === 0) {
  //   return children;
  // }
  //
  // if (presentationProgress > 0.8) {
  //   return children;
  // }

  console.log(transition);
  console.log(fromCanvas);
  console.log(toCanvas);

  const isInProgress =
    isReady && presentationProgress > 0 && presentationProgress < 1;

  const isDone = presentationProgress === 1;

  console.log(isReady, isInProgress);

  // Calculate opacity for the children container
  const childrenOpacity = interpolate(
    presentationProgress,
    [0, 0.01, 0.99, 1],
    [1, 1, 1, 1],
    {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    }
  );

  // Calculate opacity for the transition surface
  const transitionOpacity = interpolate(
    presentationProgress,
    [0, 0.01, 0.99, 1],
    [1, 1, 1, 1],
    {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    }
  );

  return (
    <AbsoluteFill>
      <AbsoluteFill>{children}</AbsoluteFill>
      <Surface
        width={width}
        height={height}
        style={{ zIndex: isInProgress ? 6 : 1, opacity: transitionOpacity }}
      >
        {isReady && !isDone ? (
          <GLTransition
            from={fromCanvas}
            width={width}
            height={height}
            to={toCanvas}
            progress={presentationProgress}
            transition={transition}
            transitionParams={{ ...transition.defaultParams, ...uniforms }}
          />
        ) : (
          <LinearCopy></LinearCopy>
        )}
      </Surface>
    </AbsoluteFill>
  );
};

export type CustomPresentationProps = {
  width: number;
  height: number;
  fromSrc: string;
  toSrc: string;
  transitionName: string;
  uniforms?: Record<string, any>;
};

export const glTransition = (
  props: CustomPresentationProps
): TransitionPresentation<CustomPresentationProps> => {
  return {
    component: GLPresentationComponent,
    props,
  };
};
