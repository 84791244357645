import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Faq from './faq';
import { useStore } from '../../../helpers/use-store';
import { BillingCycle, Plan, SubscriptionStatusName } from '@monorepo/types';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import SubscriptionInfo from './subscription-info';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { checkoutStyles } from './biiling-custom-css';
import PlanChangeModal, {
  PlanChangeModalActions,
} from './billing-modals/plan-change-modal';
import CouponDisplay from './coupon';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';

const { Title, Text } = Typography;

export const BillingPage = () => {
  const {
    dataStore: { userStore, planStore },
  } = useStore();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    BillingCycle.Monthly
  );
  const { loadingState, updateLoadingState } = useLoading();
  const planChangeModalRef = useRef<PlanChangeModalActions>(null);

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        planStore.fetchAll();
      } catch (e) {
        console.error(`failed loading plans`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, []);

  const plans = orderBy(
    planStore.items.filter((item) => item.billingCycle === billingCycle),
    'price',
    'asc'
  );

  const showCheckout = useCallback(
    (event: React.MouseEvent, plan: Plan) => {
      event.preventDefault();

      const productId = plan.externalId;
      let checkoutUrl = `https://store.payproglobal.com/checkout?billing-first-name=${userStore.currentUser.firstName}&billing-last-name=${userStore.currentUser.lastName}&billing-email=${userStore.currentUser.email}&products[1][id]=${productId}&page-template=19332&exfo=742`;

      if (reactEnv !== 'production') {
        checkoutUrl += `&use-test-mode=true&secret-key=WqhzzzE5k5`;
      }

      const body = document.getElementsByTagName('body')[0];

      const modalHtml = `
      <div class="ppg-checkout-modal ppg-show" id="ppg-checkout-modal">
        <div class="ppg-btn-close" id="ppg-btn-close">
          <img src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDQzLjYgMzg3LjEgMzEyLjQgMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQgNS40LTE0LjIgMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTQtMy43IDAtNy4yIDEuNS05LjggNEwyNTYgMTk3LjggMTI0LjkgNjguM2MtMi42LTIuNi02LjEtNC05LjgtNC0zLjcgMC03LjIgMS41LTkuOCA0TDY4IDEwNS45Yy01LjQgNS40LTUuNCAxNC4yIDAgMTkuNmwxMzEuNSAxMzBMNjguNCAzODcuMWMtMi42IDIuNi00LjEgNi4xLTQuMSA5LjggMCAzLjcgMS40IDcuMiA0LjEgOS44bDM3LjQgMzcuNmMyLjcgMi43IDYuMiA0LjEgOS44IDQuMSAzLjUgMCA3LjEtMS4zIDkuOC00LjFMMjU2IDMxMy4xbDEzMC43IDEzMS4xYzIuNyAyLjcgNi4yIDQuMSA5LjggNC4xIDMuNSAwIDcuMS0xLjMgOS44LTQuMWwzNy40LTM3LjZjMi42LTIuNiA0LjEtNi4xIDQuMS05LjgtLjEtMy42LTEuNi03LjEtNC4yLTkuN3oiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJmaWxsLTAwMDAwMCI+PC9wYXRoPjwvc3ZnPg==" >
        </div>
        <div class="ppg-loader"></div>
        <iframe class="ppg-iframe" id="ppg-iframe" src="${checkoutUrl}" frameborder="0"></iframe>
      </div>
    `;

      body.insertAdjacentHTML('beforeend', modalHtml);

      const iframe = document.getElementById('ppg-iframe');
      const closeBtn = document.getElementById('ppg-btn-close');

      if (iframe) {
        iframe.onload = function () {
          const loader = document.querySelector('.ppg-loader');
          if (loader) loader.remove();
          if (closeBtn) closeBtn.classList.add('ppg-show');
        };
      }

      if (closeBtn) {
        //@ts-expect-error zain ab ain
        closeBtn.onclick = hideCheckout;
      }
    },
    [billingCycle]
  );

  const hideCheckout = useCallback((event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    const modal = document.getElementById('ppg-checkout-modal');
    if (modal) modal.remove();

    userStore.getLatestUser();
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = checkoutStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <CouponDisplay />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <SubscriptionInfo />
      </div>

      <div style={{ textAlign: 'center', marginBottom: 32 }}>
        <Title style={{ margin: 0 }} level={2}>
          Choose Your Plan
        </Title>
        <Space align="center">
          <Text>Monthly</Text>
          <Switch
            checked={billingCycle === BillingCycle.Annual}
            onChange={(value: boolean) => {
              if (value) {
                setBillingCycle(BillingCycle.Annual);
              } else {
                setBillingCycle(BillingCycle.Monthly);
              }
            }}
          />
          <Text>Annually</Text>
        </Space>
      </div>

      <Row gutter={[24, 24]}>
        {plans.map((plan) => {
          const isUserHasActivePlan =
            userStore.userSubscription.status === SubscriptionStatusName.Active;
          const isActivePlan =
            isUserHasActivePlan &&
            userStore.userSubscription.planId === plan._id;
          const buttonText = isUserHasActivePlan
            ? `Change To ${plan.price} $`
            : 'Buy Now!';

          const handlePayment = (e) => {
            if (isUserHasActivePlan) {
              planChangeModalRef.current?.open(plan._id);
              return;
            }
            if (
              userStore.userSubscription.status ===
              SubscriptionStatusName.Suspended
            ) {
              // TODO: open warning of suspended
              // return;
            }

            showCheckout(e, plan);
          };

          return (
            <Col xs={24} md={12} lg={6} key={plan.name}>
              <Card
                hoverable
                style={{
                  height: '100%',
                  background: plan.name === 'Creator' ? '#f0f0ff' : 'white',
                }}
                bordered
              >
                <Title level={3}>{plan.name}</Title>
                <Text>{plan.videosPerMonth} videos per month</Text>

                <div style={{ margin: '24px 0' }}>
                  <Title level={2} style={{ marginBottom: 0 }}>
                    ${plan.price}
                    <Text style={{ fontSize: 14, fontWeight: 'normal' }}>
                      {billingCycle === BillingCycle.Annual
                        ? '/year'
                        : '/month'}
                    </Text>
                  </Title>
                </div>

                <div style={{ marginBottom: 24 }}>
                  <div style={{ marginBottom: 8 }}>{plan.credits} Credits</div>
                  <div style={{ marginBottom: 8 }}>
                    {plan.series === 0 ? 'No' : plan.series} Series
                  </div>
                  {plan.features.map((feature, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                      }}
                    >
                      <CheckOutlined
                        style={{ marginRight: 8, color: '#52c41a' }}
                      />
                      <Text>{feature}</Text>
                    </div>
                  ))}
                </div>

                <Button
                  disabled={isActivePlan}
                  type="primary"
                  block
                  onClick={handlePayment}
                  style={
                    plan.name === 'Creator' ? { background: '#722ed1' } : {}
                  }
                >
                  {buttonText}
                </Button>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Faq />
      <PlanChangeModal ref={planChangeModalRef} />
    </div>
  );
};

export default observer(BillingPage);
