import {
  createContext,
  MutableRefObject,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Asset,
  Scene,
  SubtitlesConfig,
  Video,
  VideoStatus,
} from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../helpers/use-store';
import { VideoPart } from '../features/video/video-editor-types';
import { debounce, orderBy } from 'lodash';
import { PlayerRef } from '@remotion/player';

export enum VideoPlayerActionType {
  UpdateSubtitles = 'update-subtitles',
}

export interface VideoPlayerAction {
  type: VideoPlayerActionType;
  dto: any;
}

interface VideoContextType {
  init: () => Promise<void>;
  fetchAsset: (assetId: string) => Promise<Asset>;
  loadingState: LoadingState;
  requestLoadingState: LoadingState;
  renderVideo: () => Promise<void>;
  video: Video | undefined;
  scenes: Scene[];
  scenesMap: Map<string, Scene>;
  assets: Asset[];
  download: () => Promise<void>;
  fetchVideoOnly: () => Promise<void>;
  updateSubtitles: (subtitlesConfig: SubtitlesConfig) => Promise<void>;
  updateMetadata: (
    metadata: Pick<Video, 'title' | 'description'>
  ) => Promise<void>;
  updateBackgroundMusic: (musicId?: string) => Promise<void>;
  updateVoiceover: (voiceover: { voiceoverId: string }) => Promise<void>;
  updateScene: (updateDto: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
    shouldUpdateState?: boolean;
  }) => Promise<void>;
  updateSceneDebounced: (updateDto: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
  }) => Promise<void>;
  fetchVideoAndAssets: () => Promise<void>;
  currentVideoPart: VideoPart;
  setCurrentVideoPart: (videoPart: VideoPart) => void;
  selectedScene: Scene | undefined;
  setScene: (scene: Scene) => void;
  getSceneStartFrame: (sceneId: string) => number;
  playerRef: MutableRefObject<PlayerRef | null>;
  movePlayerToFrame: (frame: number, shouldStart?: boolean) => Promise<void>;
  isPlayerReady: boolean;
  setPlayerReady: (flag: boolean) => void;
}

export const VideoContext = createContext<VideoContextType | undefined>(
  undefined
);

export const useVideoContext = () => {
  const context = useContext(VideoContext);

  if (context === undefined) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }

  return context;
};

export const useVideo = (videoId: string) => {
  const playerRef = useRef<PlayerRef>(null);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [video, setVideo] = useState<Video>();
  const [selectedScene, setScene] = useState<Scene>();
  const [scenesMap, setScenes] = useState<Map<string, Scene>>(new Map());
  const [assets, setAssets] = useState<Asset[]>([]);
  const [currentVideoPart, setCurrentVideoPart] = useState<VideoPart>(
    VideoPart.Scenes
  );
  const { loadingState, updateLoadingState } = useLoading();
  const {
    loadingState: requestLoadingState,
    updateLoadingState: updateRequestLoadingState,
  } = useLoading(LoadingState.Loaded);
  const {
    dataStore: { videoStore, assetStore },
  } = useStore();

  const scenes: Scene[] = Array.from(scenesMap.values());

  const fetchVideoOnly = async () => {
    const videoResult = await videoStore.fetch(videoId);

    setVideo(videoResult);
  };

  const fetchVideoAndAssets = async () => {
    const [videoResult, scenesResult] = await Promise.all([
      videoStore.fetch(videoId),
      videoStore.getScenes(videoId),
    ]);

    const scenesMapResult = new Map(
      scenesResult.map((item) => [item._id, item])
    );

    setVideo(videoResult);
    setScenes(scenesMapResult);

    const sceneAssetIds = scenesResult.map((scene) => scene.assetId);
    const assetIdsToFetch = sceneAssetIds.filter((assetId) => {
      const foundAsset = assets.find((asset) => asset._id === assetId);

      return !foundAsset;
    });

    const assetsPromises = assetIdsToFetch.map((assetId) => {
      return assetStore.fetch(assetId);
    });

    const assetsResult = await Promise.all(assetsPromises);

    const newSet = new Set([...assetsResult, ...assets]);

    setAssets(Array.from(newSet.values()));
  };

  const fetchAsset = async (assetId: string): Promise<Asset> => {
    const asset = await assetStore.fetch(assetId);

    setAssets([...assets, asset]);

    return asset;
  };

  const init = async () => {
    if (!videoId) {
      return;
    }
    updateLoadingState(LoadingState.Loading);

    try {
      await fetchVideoAndAssets();
    } catch (e) {
      console.error(`failed getting video`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const renderVideo = async () => {
    await videoStore.render(videoId);

    // setVideo((prevVideo) => {
    //   return { ...(prevVideo as Video), status: VideoStatus.InProgress };
    // });
  };

  const download = async () => {
    // setIsDownloading(true);
    try {
      const response = await videoStore.download(videoId);

      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.mp4');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download the file. Please try again.');
    } finally {
      // setIsDownloading(false);
    }
  };

  const movePlayerToFrame = async (frame: number, shouldStart = true) => {
    playerRef?.current?.pause();
    playerRef.current?.seekTo(frame);
    // TODO: add player loading
    await Promise.resolve((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    if (shouldStart) {
      playerRef?.current?.play();
    }
  };

  const updateScene = async ({
    sceneId,
    videoId,
    updateDto,
    frameToSeek,
    shouldUpdateState = false,
  }: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
    shouldUpdateState?: boolean;
  }) => {
    try {
      updateRequestLoadingState(LoadingState.Loading);

      const sceneResult = await videoStore.updateScene(
        sceneId,
        videoId,
        updateDto
      );

      if (shouldUpdateState) {
        setScenes((prevScenes) => {
          return new Map(prevScenes.set(sceneId, sceneResult as Scene));
        });
      }

      if (frameToSeek !== -1) {
        movePlayerToFrame(frameToSeek);
      }
    } catch (e) {
      console.error(`failed updating scene: ${sceneId}: ${e}`);
      throw e;
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateScene = useMemo(() => {
    return debounce(updateScene, 1000);
  }, [isPlayerReady]);

  const updateSceneDebounced = async ({
    sceneId,
    videoId,
    updateDto,
    frameToSeek,
  }: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
  }) => {
    let currentScene = scenesMap.get(sceneId);

    debouncedUpdateScene({ sceneId, videoId, updateDto, frameToSeek: -1 });

    if (currentScene) {
      currentScene = {
        ...currentScene,
        ...updateDto,
      };

      setScenes((prevScenes) => {
        return new Map(prevScenes.set(sceneId, currentScene as Scene));
      });
    }

    movePlayerToFrame(frameToSeek);
  };

  const _updateSubtitlesRequest = async (subtitlesConfig: SubtitlesConfig) => {
    if (!video) {
      return;
    }

    try {
      updateRequestLoadingState(LoadingState.Loading);

      await videoStore.updateSubtitles(video._id.toString(), subtitlesConfig);
    } catch (e) {
      console.error(`failed`, e);
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateSubtitles = useMemo(() => {
    return debounce(_updateSubtitlesRequest, 1000);
  }, [isPlayerReady]);

  const updateSubtitles = async (subtitlesConfig: SubtitlesConfig) => {
    if (!video) {
      return;
    }

    debouncedUpdateSubtitles(subtitlesConfig);

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        subtitlesConfig,
      };
    });
  };

  const _updateMetadataRequest = async (
    metadata: Pick<Video, 'title' | 'description'>
  ) => {
    if (!video) {
      return;
    }

    try {
      updateRequestLoadingState(LoadingState.Loading);

      await videoStore.updateMetadata(video._id.toString(), metadata);
    } catch (e) {
      console.error(`failed`, e);
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateMetadata = useMemo(() => {
    return debounce(_updateMetadataRequest, 1000);
  }, [isPlayerReady]);

  const updateMetadata = async (
    metadata: Pick<Video, 'title' | 'description'>
  ) => {
    if (!video) {
      return;
    }

    debouncedUpdateMetadata(metadata);

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        ...metadata,
      };
    });
  };

  const updateVoiceover = async (voiceover: { voiceoverId: string }) => {
    if (!video) {
      return;
    }

    await videoStore.updateVoiceover(video._id.toString(), voiceover);
    await fetchVideoAndAssets();

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        voiceoverId: voiceover.voiceoverId,
      };
    });

    await movePlayerToFrame(0, false);
  };

  const updateBackgroundMusic = async (musicId?: string) => {
    if (!video) {
      return;
    }

    await videoStore.updateBackgroundMusic(video._id.toString(), musicId);
    await fetchVideoAndAssets();

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        backgroundMusicId: musicId || '',
      };
    });
  };

  const getSceneStartFrame = (sceneId: string) => {
    const scene = scenesMap.get(sceneId);

    if (!scene) {
      return 0;
    }

    const orderScenes = orderBy(scenes, 'index');

    // Find current scene index in ordered array
    const currentSceneIndex = orderScenes.findIndex((s) => s._id === sceneId);

    // Sum up durations of all scenes before this one
    const time = orderScenes
      .slice(0, currentSceneIndex)
      .reduce((sum, scene) => sum + scene.audioDuration, 0);
    // 15 buffer
    return time * 30;
  };

  return {
    init,
    loadingState,
    renderVideo,
    updateSubtitles,
    video,
    scenes,
    assets,
    download,
    updateScene,
    updateSceneDebounced,
    fetchVideoAndAssets,
    updateVoiceover,
    updateBackgroundMusic,
    currentVideoPart,
    setCurrentVideoPart,
    selectedScene,
    setScene,
    requestLoadingState,
    scenesMap,
    fetchAsset,
    getSceneStartFrame,
    playerRef,
    movePlayerToFrame,
    isPlayerReady,
    setPlayerReady,
    updateMetadata,
    fetchVideoOnly,
  };
};
