import { CrudService, HttpService } from '@monorepo/client-common';
import { ClientUser, User } from '@monorepo/types';

export default class UserService extends CrudService {
  override route = 'user';

  constructor({ httpService }: { httpService: HttpService }) {
    const route = 'user';

    super({ route: 'user', httpService });

    this.route = route;
  }

  async uploadAvatar(file: FormData) {
    const response = await this.httpService.post<
      FormData,
      { data: ClientUser }
    >(`${this.path}/upload-avatar`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  async updateDetails(userDto: Pick<User, 'firstName' | 'lastName' | 'email'>) {
    const response = await this.httpService.post<
      Pick<User, 'firstName' | 'lastName' | 'email'>,
      { data: ClientUser }
    >(`${this.path}/update-details`, userDto);

    return response.data;
  }

  async updatePassword(newPassword: string) {
    const response = await this.httpService.post<
      { newPassword: string },
      { data: boolean }
    >(`${this.path}/update-password`, { newPassword });

    return response.data;
  }
}
