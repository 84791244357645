import React, { FunctionComponent, useRef, useState } from 'react';
import { Button, List, Typography } from 'antd';
import { Voiceover } from '@monorepo/types';
import CardWithSound from '../card-with-sound/card-with-sound';

import './voiceover-list.scss';

interface VoiceoverListProps {
  voiceoverId?: string;
  gridColumns?: number;
  shouldDisplayFirst?: boolean;
  voiceovers: Voiceover[];
  onVoiceoverSelect?: (voiceoverId: string) => void;
  onButtonClick?: (voiceoverId: string) => void;
  showUpdateButton?: boolean;
  updateButtonText?: string;
  updateDescription?: string;
  loading?: boolean;
}

export const sortItemsById = <T extends { _id: string }>(
  items: T[],
  priorityId?: string
): T[] => {
  if (!items || items.length === 0) {
    return [];
  }

  return [...items].sort((a, b) => {
    if (!priorityId) return 0;
    if (a._id === priorityId) return -1;
    if (b._id === priorityId) return 1;
    return 0;
  });
};

export const VoiceoverList: FunctionComponent<VoiceoverListProps> = ({
  voiceoverId,
  voiceovers,
  onVoiceoverSelect,
  onButtonClick,
  gridColumns = 1,
  shouldDisplayFirst = true,
  showUpdateButton = false,
  updateButtonText = 'Update',
  updateDescription,
  loading = false,
}) => {
  const [selectedVoice, setSelectedVoice] = useState<string | null>(
    voiceoverId || null
  );
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [speed, setSpeed] = useState<number>(1);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const selectVoice = (id: string) => {
    setSelectedVoice(id);
    onVoiceoverSelect && onVoiceoverSelect(id);
  };

  const toggleAudio = (id: string, url: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (playingVoice === id) {
      audioRef.current?.pause();
      setPlayingVoice(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(url);
      audioRef.current.playbackRate = speed;
      audioRef.current.play();
      audioRef.current.onended = () => setPlayingVoice(null);
      setPlayingVoice(id);
    }
  };

  const sortedItems = shouldDisplayFirst
    ? sortItemsById(voiceovers, voiceoverId)
    : voiceovers;

  return (
    <div
      className="voiceover"
      style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
    >
      <div className="voiceover-content scroller">
        <List
          grid={{
            gutter: 5,
            column: gridColumns,
          }}
          dataSource={sortedItems}
          renderItem={(item) => (
            <List.Item
              className={`list-item ${
                selectedVoice === item._id ? 'selected' : ''
              }`}
              style={{
                padding: '10px 15px 15px 15px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                marginBottom: 0,
              }}
            >
              <CardWithSound
                id={item._id}
                name={item.name}
                description={item.description}
                audioUrl={item.audioUrl}
                tags={item.tags}
                isSelected={selectedVoice === item._id}
                isPlaying={playingVoice === item._id}
                onPlay={toggleAudio}
                onSelect={selectVoice}
              />
            </List.Item>
          )}
        />
      </div>

      {showUpdateButton && (
        <>
          <Button
            disabled={selectedVoice === voiceoverId}
            loading={loading}
            onClick={() =>
              selectedVoice && onButtonClick && onButtonClick(selectedVoice)
            }
            type="primary"
            style={{ width: '100%' }}
          >
            {updateButtonText}
          </Button>
          {updateDescription && (
            <Typography.Text type="secondary">
              {updateDescription}
            </Typography.Text>
          )}
        </>
      )}
    </div>
  );
};
