import { CrudService, HttpService } from '@monorepo/client-common';
import { Voiceover } from '@monorepo/types';

const routeName = 'music';

class VoiceoverService extends CrudService<Voiceover> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }
}

export default VoiceoverService;
