import {
  AbsoluteFill,
  Img,
  OffthreadVideo,
  spring,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { TransitionSeries } from '@remotion/transitions';
import { Fragment } from 'react';
import { Asset, TRANSITION_FRAMES } from '../remotion-types';
import { PanDirection, TransitionEffect, ZoomType } from '@monorepo/types';
import { calculateZoom } from '../remotion-helpers/calculate-zoom';
import { calculatePan } from '../remotion-helpers/calculate-pan';
import { createTransition } from '../remotion-helpers/create-transition';
import { SpaceDust } from '../remotion-overlay-effects/space-dust';
import { ShinyLightEffect } from '../remotion-overlay-effects/shiney-light';
import { NoiseComp } from '../remotion-overlay-effects/noise-comp';

interface AnimationConfig {
  panDirection?: PanDirection;
  zoomType?: ZoomType;
}

export const AssetDisplay: React.FC<{
  asset: Asset;
  startFrom?: number;
  animationConfig?: AnimationConfig;
}> = ({ asset, startFrom = 0, animationConfig }) => {
  if (!asset.src) {
    return null;
  }

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const durationInFrames = fps * (asset?.duration || 1);

  const progress = spring({
    frame,
    fps,
    config: {
      damping: 200,
      mass: 0.8,
      stiffness: 80,
    },
    durationInFrames,
  });

  // Calculate zoom and pan values
  const zoom = animationConfig?.zoomType
    ? calculateZoom(animationConfig.zoomType, progress)
    : { scale: 1 };

  const pan = animationConfig?.panDirection
    ? calculatePan(animationConfig.panDirection, progress)
    : { x: 0, y: 0, scale: 1 };

  const finalScale = zoom.scale * pan.scale;

  return (
    <AbsoluteFill style={{ overflow: 'hidden' }}>
      <AbsoluteFill
        style={{
          transform: `scale(${finalScale}) translate(${pan.x}%, ${pan.y}%)`,
          transformOrigin: 'center center',
          backfaceVisibility: 'hidden',
          willChange: 'transform',
        }}
      >
        {asset.type === 'video' ? (
          <OffthreadVideo
            pauseWhenBuffering={false}
            src={asset.src}
            startFrom={startFrom}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Img
            pauseWhenLoading={false}
            src={asset.src}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export const AssetTransitionSeries: React.FC<{
  assets: Array<Asset>;
}> = ({ assets }) => {
  const { fps, width, height } = useVideoConfig();
  const absoulteFrame = useCurrentFrame();
  let cumulative = 0;

  return (
    <AbsoluteFill>
      <TransitionSeries>
        {assets.map((asset, index) => {
          const durationInFrames = (asset.duration || 0) * fps;
          cumulative += durationInFrames;

          const animationConfig: AnimationConfig = {
            panDirection: asset.panDirection,
            zoomType: asset.zoomType,
          };

          const nextAsset = assets[index + 1];
          const nextAssetSrc = nextAsset?.src || asset.src;

          const transitionDuration = TRANSITION_FRAMES;

          const transitionResult = createTransition({
            width,
            height,
            effect: asset.transition || TransitionEffect.None,
            fromSrc: asset.src,
            toSrc: nextAssetSrc,
          });

          return (
            <Fragment key={index}>
              <TransitionSeries.Sequence
                // premountFor={200}
                durationInFrames={
                  durationInFrames + transitionResult.durationInFrames
                }
              >
                <AssetDisplay asset={asset} animationConfig={animationConfig} />
              </TransitionSeries.Sequence>
              {index < assets.length - 1 && (
                <TransitionSeries.Transition
                  timing={transitionResult.timing}
                  //@ts-expect-error zubi
                  presentation={transitionResult.presentation}
                />
              )}
            </Fragment>
          );
        })}
      </TransitionSeries>
      {/*<NoiseComp />*/}
      {/*<SpaceDust />*/}
    </AbsoluteFill>
  );
};
