// Asset display and audio components remain the same
import { Audio, Series, useVideoConfig } from 'remotion';
import {
  AUDIO_DELAY_FRAMES,
  AudioTrack,
  TRANSITION_FRAMES,
} from '../remotion-types';

export const AudioTrackPlayer: React.FC<{
  track: AudioTrack;
}> = ({ track }) => {
  return (
    <Audio
      pauseWhenBuffering={false}
      loop={track.loop ?? false}
      src={track.src}
      playbackRate={1}
      volume={track.volume ?? 1}
    />
  );
};

export const AudioTrackGroup: React.FC<{
  tracks: AudioTrack[];
}> = ({ tracks }) => {
  const { fps } = useVideoConfig();

  return (
    <Series>
      {tracks.map((track, index) => {
        const durationInFrames = (track.duration || 0) * fps;
        return (
          <Series.Sequence key={index} durationInFrames={durationInFrames}>
            <AudioTrackPlayer track={track} />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
