import {
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Select,
  Space,
  Typography,
} from 'antd';
import SocialAccountSelect from '../../../social-accounts/social-account';
import React, { useEffect, useState } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useNotification } from '../../../../hooks/use-notification';
import {
  DEFAULT_TIMEZONE,
  tiktokDefaultMetadata,
  SocialAccountMetadata,
  SocialProvider,
  TiktokMetadata,
  Timezone,
  TimezoneLabels,
  VideoStatus,
} from '@monorepo/types';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TiktokForm from './tiktok-form/tiktok-form';
import TiktokAgreement from './tiktok-form/tiktok-agreement';
import { observer } from 'mobx-react-lite';

dayjs.extend(utc);
dayjs.extend(timezone);

const SocialPublish = () => {
  const { video, fetchVideoAndAssets } = useVideoContext();
  const {
    dataStore: { videoStore, socialAccountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const notificationApi = useNotification();
  const [publishAt, setPublishAt] = useState<Date | undefined>(
    video?.socialPublishInfo?.publishAt
  );
  const [socialAccountId, setSocialAccountId] = useState<string | undefined>(
    video?.socialPublishInfo?.socialAccountId
  );
  const [timezone, setTimezone] = useState<Timezone>(
    video?.socialPublishInfo?.timezone || DEFAULT_TIMEZONE
  );

  const selectedAccount = socialAccountStore.get(socialAccountId || '');

  const [socialAccountMetadata, updateSocialAccountMetada] =
    useState<SocialAccountMetadata>(selectedAccount?.metadata);

  useEffect(() => {
    console.log('huy');
  }, [selectedAccount, socialAccountId]);

  const handleChange = (datetime) => {
    setPublishAt(datetime);
  };

  const publish = async () => {
    if (!video) {
      return;
    }

    if (!socialAccountId) {
      return;
    }

    try {
      updateLoadingState(LoadingState.Loading);

      let publishAtUTC;

      if (publishAt) {
        publishAtUTC = dayjs(publishAt).tz(timezone).utc().toDate();
      }

      const result = await videoStore.updateSocialPublish({
        videoId: video._id,
        socialAccountId,
        timezone,
        publishAt: publishAtUTC,
        metadata: socialAccountMetadata,
      });

      if (!result) {
        throw new Error(`failed publish`);
      }

      notificationApi.success({
        placement: 'top',
        message: 'Video published successfully',
        description: `It may take a few minutes for the content to process and be visible on their profile`,
      });

      // TODO: just refetch video
      fetchVideoAndAssets();
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        message: 'Publish Failed',
        description: 'We encountered an error during publish',
      });

      console.error(`failed publishing`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isMoreThanFiveMinutesBefore = video?.socialPublishInfo?.publishAt
    ? dayjs()
        .utc()
        .isBefore(
          dayjs(video?.socialPublishInfo.publishAt).utc().subtract(5, 'minutes')
        )
    : true;

  const isVideoPublished = video?.status === VideoStatus.Published;

  const handleMetadataUpdate = (updates: Partial<TiktokMetadata>) => {
    updateSocialAccountMetada((prev) => {
      return {
        ...tiktokDefaultMetadata,
        ...(selectedAccount?.metadata || {}),
        ...prev,
        ...updates,
      };
    });
  };

  const getSocialProviderAdditionalInfo = () => {
    if (!selectedAccount) {
      return null;
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      return (
        <>
          <TiktokForm
            onUpdate={handleMetadataUpdate}
            metadata={
              socialAccountMetadata ||
              selectedAccount.metadata ||
              tiktokDefaultMetadata
            }
          />
          <Divider style={{ margin: '15px 0' }} />
        </>
      );
    }

    return null;
  };

  const getSocialAgreement = () => {
    if (!selectedAccount) {
      return null;
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      return (
        <TiktokAgreement
          metadata={
            socialAccountMetadata ||
            selectedAccount.metadata ||
            tiktokDefaultMetadata
          }
        />
      );
    }

    return null;
  };

  const isMetadataValid = () => {
    if (!selectedAccount) {
      return {
        valid: false,
        message: 'No Social Account is selected',
      };
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      const tiktokMetadata = (socialAccountMetadata ||
        tiktokDefaultMetadata) as TiktokMetadata;

      // Check if disclosure is enabled but no brand options selected
      if (
        tiktokMetadata.isDisclosureEnabled &&
        !tiktokMetadata.brandOptions.yourBrand &&
        !tiktokMetadata.brandOptions.brandedContent
      ) {
        return {
          valid: false,
          message:
            'You need to indicate if your content promotes yourself, a third party, or both',
        };
      }
    }

    return {
      valid: true,
      message: '',
    };
  };

  const metadataValidity = isMetadataValid();

  return (
    <Card title="Social Media Publishing">
      <Space size={'small'} direction="vertical" style={{ width: '100%' }}>
        <div>
          <Typography.Text>Social Account</Typography.Text>
          <SocialAccountSelect
            onChange={(value) => {
              setSocialAccountId(value);
            }}
            value={socialAccountId}
          />
          <Divider style={{ margin: '15px 0' }} />
          {getSocialProviderAdditionalInfo()}
        </div>
        <div>
          <Typography.Text>Timezone</Typography.Text>
          <Select
            style={{ width: '100%' }}
            placeholder={'Timezone'}
            value={timezone}
            onChange={setTimezone}
          >
            {Object.entries(Timezone).map(([key, value]) => (
              <Select.Option key={value} value={value}>
                {TimezoneLabels[value]}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <span>
            Publish Date{' '}
            <Typography.Text type={'secondary'}>(Local Time)</Typography.Text>
          </span>
          <Flex style={{ flexDirection: 'column' }} justify={'space-between'}>
            <DatePicker
              disabled={!isMoreThanFiveMinutesBefore}
              style={{ width: '100%' }}
              placeholder="Select Publish Date"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={publishAt ? dayjs(publishAt) : null}
              onChange={handleChange}
              allowClear
            />
            <Typography.Text type="secondary">
              <ul>
                <li>Selecting no date will publish the video immediately</li>
                <li>publish date can be up to 5 minutes before the date.</li>
              </ul>
            </Typography.Text>
          </Flex>
        </div>
        <Button
          onClick={publish}
          loading={loadingState === LoadingState.Loading}
          disabled={
            !metadataValidity.valid ||
            !socialAccountId ||
            isVideoPublished ||
            !isMoreThanFiveMinutesBefore ||
            !video?.videoUrl
          }
          style={{ width: '100%' }}
          type="primary"
        >
          Publish / Schedule
        </Button>
        {!metadataValidity.valid ? (
          <Typography.Text type="secondary">
            {metadataValidity.message}
          </Typography.Text>
        ) : null}
        {isVideoPublished ? null : getSocialAgreement()}
        {!video?.videoUrl ? (
          <Typography.Text type="secondary">
            Video needs to be rendered first.
          </Typography.Text>
        ) : null}
        {isVideoPublished ? (
          <Typography.Text type="secondary">
            Video is already published to the channel.
          </Typography.Text>
        ) : null}
      </Space>
    </Card>
  );
};

export default observer(SocialPublish);
