export enum Timezone {
  UTC = 'UTC',
  Eastern = 'America/New_York',
  CentralEuropean = 'Europe/Berlin',
  Indian = 'Asia/Kolkata',
  China = 'Asia/Shanghai',
  Japan = 'Asia/Tokyo',
  Pacific = 'America/Los_Angeles',
  Brazil = 'America/Sao_Paulo',
}

export const DEFAULT_TIMEZONE = Timezone.UTC;

export const TimezoneLabels = {
  [Timezone.UTC]: 'Coordinated Universal Time (UTC)',
  [Timezone.Eastern]: 'Eastern Time (US & Canada)',
  [Timezone.CentralEuropean]: 'Central European Time (Berlin)',
  [Timezone.Indian]: 'Indian Standard Time',
  [Timezone.China]: 'China Standard Time',
  [Timezone.Japan]: 'Japan Standard Time',
  [Timezone.Pacific]: 'Pacific Time (US & Canada)',
  [Timezone.Brazil]: 'Brazil Time (BRT)',
};
