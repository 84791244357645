import { Empty, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const EmptySeries = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: '50px 0' }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            You haven't created any series yet. <br />
            Start your first series now!
          </span>
        }
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate('/series/form/new')}
        >
          Create New Series
        </Button>
      </Empty>
    </div>
  );
};

export default EmptySeries;
