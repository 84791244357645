// Helper to calculate duration for Player
import { RemotionConfig } from '../remotion-types';

export const calculateDurationInFrames = (config: RemotionConfig): number => {
  const fps = config.fps || 30;

  // Calculate end times from all content types
  const subtitleEndTime = Math.max(
    0,
    ...(config.subtitles?.map((s) => s.endTime) || [])
  );
  const audioEndTime = Math.max(
    0,
    ...(config.audioTracks?.flat().map((t) => t.startAt + (t.duration || 0)) ||
      [])
  );
  const assetEndTime = config.assets.reduce(
    (sum, asset) => sum + (asset.duration || 0),
    0
  );

  // Use the latest end time
  const maxDuration = Math.max(subtitleEndTime, audioEndTime, assetEndTime);

  // Add a small buffer and convert to frames
  return Math.ceil((maxDuration + 0.5) * fps);
};
