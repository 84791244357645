import { useNavigate, useParams } from 'react-router-dom';
import { LoadingState, Space, useLoading } from '@monorepo/react-components';
import { Button, Card, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';
import { BulbOutlined, EditOutlined } from '@ant-design/icons';
import { SeriesSettingsModal, SeriesSettingsRef } from './series-settings';
import { useStore } from '../../../helpers/use-store';
import { SeriesInformation } from '../series-information';
import { observer } from 'mobx-react-lite';
import SeriesVideos, { SeriesVideosRef } from '../series-videos/series-videos';
import { useNotification } from '../../../hooks/use-notification';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';

const SeriesPage = () => {
  const modalRef = useRef<SeriesSettingsRef>(null);
  const seriesVideosRef = useRef<SeriesVideosRef>(null);
  const {
    loadingState: generateTitlesLoadingState,
    updateLoadingState: updateGenerateTitlesLoadingState,
  } = useLoading();
  const {
    dataStore: { seriesStore },
  } = useStore();
  const navigate = useNavigate();
  const notificationApi = useNotification();
  const { seriesId } = useParams();
  const series = seriesStore.get(seriesId || '') || null;
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    if (!series) {
      seriesStore.fetchAll();
    }
  }, [seriesId]);

  const openModal = () => {
    modalRef.current?.open();
  };

  if (!series) {
    navigate('/series');
    return null;
  }

  const generateNextTitles = async () => {
    try {
      updateGenerateTitlesLoadingState(LoadingState.Loading);
      await seriesStore.generateNextVideosMetadata(series._id);
      seriesVideosRef.current?.reFetch(1);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Next videos titles created successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'Failed creating next videos titles, please contact support.',
      });

      console.error(`failed generating next videos titles`, e);
    } finally {
      updateGenerateTitlesLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <Space direction="vertical">
      <Typography.Title level={3}>Details</Typography.Title>
      <SeriesInformation
        columns={isMobile ? 1 : 2}
        actions={
          <div style={{ marginTop: isMobile ? '15px' : 0 }}>
            <Tooltip
              placement={'bottom'}
              title={`We are generating 7 days ahead from now, according to the request frequency`}
            >
              <Button
                loading={generateTitlesLoadingState === LoadingState.Loading}
                icon={<BulbOutlined />}
                onClick={generateNextTitles}
              >
                Generate Next Titles
              </Button>
            </Tooltip>
            <Button icon={<EditOutlined />} onClick={openModal}>
              Edit
            </Button>
          </div>
        }
        series={series}
      />

      <Typography.Title level={3}>Videos</Typography.Title>
      <Card>
        <SeriesVideos ref={seriesVideosRef} series={series} />
      </Card>
      <SeriesSettingsModal seriesId={series._id} ref={modalRef} />
    </Space>
  );
};

export default observer(SeriesPage);
