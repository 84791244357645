import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Select, SelectProps } from 'antd';

export const FormSelect: FunctionComponent<
  Omit<FormFieldProps, 'render'> & SelectProps
> = (formFieldProps) => {
  const fieldProps: SelectProps = {
    placeholder: formFieldProps.placeholder,
    mode: formFieldProps.mode,
    style: { width: '100%' },
    options: formFieldProps.options,
    showSearch: formFieldProps.showSearch,
    allowClear: formFieldProps.allowClear,
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Select
            className="form-field__select"
            size="middle"
            {...fieldProps}
            {...field}
            style={formFieldProps.style}
            onChange={(val) => field.onChange(val || '')}
          />
        );
      }}
    />
  );
};
