import React, { useRef } from 'react';

interface VideoPlayerProps {
  url: string;
  width?: string;
  height?: string;
}

const VideoAssetPreview: React.FC<VideoPlayerProps> = ({
  url,
  width,
  height,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <video
      ref={videoRef}
      src={url}
      style={{
        width,
        height,
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: '5px',
      }}
      muted
      loop
      onMouseEnter={() => videoRef.current?.play()}
      onMouseLeave={() => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }}
    />
  );
};

export default VideoAssetPreview;
