import { Button, Table, Tag, Typography } from 'antd';
import { LoadingState, Space, useLoading } from '@monorepo/react-components';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ColumnsType } from 'antd/es/table';
import { Series, SocialPublishInfo, Video, VideoStatus } from '@monorepo/types';
import { startCase } from 'lodash';
import dayjs from 'dayjs';
import { BuildOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../helpers/use-store';
import { useNotification } from '../../../hooks/use-notification';

const { Text } = Typography;

export const getStatusColor = (status: VideoStatus) => {
  switch (status) {
    case VideoStatus.Published:
      return 'green';
    case VideoStatus.Ready:
      return 'blue';
    // case VideoStatus.InProgress:
    //   return 'orange';
    // case VideoStatus.Failed:
    //   return 'red';
    default:
      return 'default';
  }
};

interface Props {
  series: Series;
}

export interface SeriesVideosRef {
  reFetch: (page?: number) => Promise<void>;
}

const SeriesVideos = forwardRef<SeriesVideosRef, Props>(({ series }, ref) => {
  const navigate = useNavigate();
  const notificationApi = useNotification();
  const [totalVideos, setTotalVideos] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [videos, setVideos] = useState<Video[]>([]);
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { seriesStore },
  } = useStore();
  const {
    loadingState: createAssetsLoading,
    updateLoadingState: updateCreateAssetsLoading,
  } = useLoading();
  const [currentVideoCreatingAssetsId, updateCurrentVideoCreatingAssetsId] =
    useState<string | null>(null);

  const fetch = async (page?: number) => {
    updateLoadingState(LoadingState.Loading);

    try {
      const videosResult = await seriesStore.paginateVideos(series._id, {
        page: page || currentPage,
        limit: 10,
      });

      setVideos(videosResult.results);
      setTotalVideos(videosResult.total);
    } catch (e) {
      console.error(`failed getting videos`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage]);

  useImperativeHandle(ref, () => ({
    reFetch: fetch,
  }));

  const createAssets = async (videoId: string) => {
    updateCreateAssetsLoading(LoadingState.Loading);
    updateCurrentVideoCreatingAssetsId(videoId);

    try {
      await seriesStore.generateSeriesVideoAssets(series._id, videoId);
      await fetch();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Assets Created successfully, video is ready.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed creating video assets, please contact support.',
      });

      console.error(`failed creating assets for video: ${videoId}`, e);
    } finally {
      updateCreateAssetsLoading(LoadingState.Loaded);
      updateCurrentVideoCreatingAssetsId(null);
    }
  };

  const columns: ColumnsType<Video> = [
    {
      title: 'Title',
      dataIndex: 'title',
      align: 'left',
      key: 'title',
      width: '400px',
      render: (text, record) => (
        <Space style={{ minWidth: '350px' }} direction="vertical">
          <Text strong>{text}</Text>
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      align: 'center',
      render: (status: VideoStatus, item: Video) => (
        <Space size={0} direction={'vertical'}>
          <Tag
            style={{ width: '72px', textAlign: 'center' }}
            color={getStatusColor(status)}
          >
            {startCase(status)}
          </Tag>
          {item.videoUrl && status === VideoStatus.Ready ? (
            <Typography.Text
              type={'secondary'}
              style={{ width: '72px', textAlign: 'center', fontSize: '12px' }}
            >
              Rendered
            </Typography.Text>
          ) : null}
        </Space>
      ),
    },
    {
      title: 'Publish Date',
      dataIndex: 'socialPublishInfo',
      key: 'publishAt',
      width: '15%',
      render: (socialPublishInfo: SocialPublishInfo) =>
        dayjs(socialPublishInfo?.publishAt).format('MM/DD/YYYY HH:mm'),
    },
    {
      title: '',
      align: 'center',
      width: '25%',
      render: (item: Video) => {
        const isInitial = item.status === VideoStatus.Initial;

        if (isInitial) {
          const isLoading = createAssetsLoading === LoadingState.Loading;
          const shouldButtonLoad =
            isLoading && item._id === currentVideoCreatingAssetsId;

          return (
            <Button
              disabled={isLoading && item._id !== currentVideoCreatingAssetsId}
              loading={shouldButtonLoad}
              onClick={() => createAssets(item._id)}
              icon={<BuildOutlined />}
            >
              Create Assets
            </Button>
          );
        }

        return (
          <Button
            style={{ minWidth: '150px' }}
            icon={<EyeOutlined />}
            onClick={() => navigate(`/videos/${item._id}`)}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      size={'small'}
      rowHoverable={true}
      columns={columns}
      scroll={{ x: 400 }}
      loading={loadingState === LoadingState.Loading}
      dataSource={videos}
      rowKey="_id"
      pagination={{
        pageSize: 10,
        showTotal: (total) => `Total ${total} Videos`,
        current: currentPage,
        total: totalVideos,
        onChange: (page) => setCurrentPage(page),
      }}
    />
  );
});

export default SeriesVideos;
