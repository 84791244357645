import React, { useEffect } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { Typography } from 'antd';

export const formatTime = (frame: number, fps: number) => {
  const hours = Math.floor(frame / fps / 3600);

  const remainingMinutes = frame - hours * fps * 3600;
  const minutes = Math.floor(remainingMinutes / 60 / fps);

  const remainingSec = frame - hours * fps * 3600 - minutes * fps * 60;
  const seconds = Math.floor(remainingSec / fps);

  const frameAfterSec = Math.round(frame % fps);

  const hoursStr = String(hours);
  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(seconds).padStart(2, '0');
  const frameStr = String(frameAfterSec).padStart(2, '0');

  return {
    hoursStr,
    minutesStr,
    secondsStr,
    frameStr,
  };
};

export const TimeDisplay = ({ durationInFrames }) => {
  const { playerRef, isPlayerReady } = useVideoContext();
  const fps = 30;
  const [time, setTime] = React.useState(0);

  useEffect(() => {
    const { current } = playerRef;
    if (!current) {
      return;
    }

    const onTimeUpdate = () => {
      setTime(current.getCurrentFrame());
    };

    current.addEventListener('frameupdate', onTimeUpdate);

    return () => {
      current.removeEventListener('frameupdate', onTimeUpdate);
    };
  }, [isPlayerReady]);

  const currenTime = formatTime(time, fps);
  const totalTime = formatTime(durationInFrames, fps);

  return (
    <div className="time-display">
      <Typography.Text style={{ width: '100px' }}>
        <span>{currenTime.minutesStr}</span>
        <span>:</span>
        <span>{currenTime.secondsStr}</span>
        <span>/</span>
        <span>{totalTime.minutesStr}</span>
        <span>:</span>
        <span>{totalTime.secondsStr}</span>
      </Typography.Text>
    </div>
  );
};
