import { AbsoluteFill, useCurrentFrame, useVideoConfig } from 'remotion';
import { DEFAULT_CAPTION_SETTINGS, RemotionConfig } from '../remotion-types';
import { AssetTransitionSeries } from '../remotion-assets/remotion-assets';
import { AudioTrackGroup } from '../remotion-audio/remotion-audio';
import { SubtitleSeries } from '../remotion-subtitles/remotion-subtitles';
import { FunctionComponent } from 'react';
import * as React from 'react';

export const RemotionMainContainer: FunctionComponent<{
  config: RemotionConfig;
}> = ({ config }) => {
  const frame = useCurrentFrame();
  const { width, height } = useVideoConfig();
  const subtitlesSettings = {
    ...DEFAULT_CAPTION_SETTINGS,
    ...config.captionSettings,
  };

  return (
    <AbsoluteFill
      style={{
        backgroundColor: 'black',
        width,
        height,
      }}
    >
      <AssetTransitionSeries assets={config.assets} />

      {config.audioTracks?.map((trackGroup, groupIndex) => (
        <AudioTrackGroup key={groupIndex} tracks={trackGroup} />
      ))}

      {config.subtitles && config.captionSettings?.display && (
        <SubtitleSeries
          isRtl={config.isRtl}
          absoluteFrame={frame}
          subtitles={config.subtitles}
          settings={subtitlesSettings}
        />
      )}
    </AbsoluteFill>
  );
};
