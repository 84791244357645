import { Button, Col, Flex, Row, Typography } from 'antd';
import {
  FormSelect,
  FormTextArea,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import {
  PanDirection,
  Scene,
  TransitionEffect,
  ZoomType,
} from '@monorepo/types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useVideoContext } from '../../../hooks/use-video';
import TransitionSelector from './transition-selector/transition-selector';
import EditSceneText from './edit-scene-text/edit-scene-text';

export type EditSceneForm = Pick<
  Scene,
  'transition' | 'panDirection' | 'zoomType'
>;

const EditScene = () => {
  const form = useForm<EditSceneForm>();
  const {
    updateSceneDebounced,
    selectedScene: scene,
    getSceneStartFrame,
    playerRef,
    movePlayerToFrame,
  } = useVideoContext();

  const resetForm = () => {
    if (scene) {
      form.reset({
        transition: scene.transition,
        panDirection: scene.panDirection,
        zoomType: scene.zoomType,
      });
    }
  };

  useEffect(() => {
    resetForm();

    if (scene) {
      const startFrame = getSceneStartFrame(scene._id);
      movePlayerToFrame(startFrame + 8, false);
    }
  }, [scene?._id]);

  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      if (scene) {
        const frame = getSceneStartFrame(scene._id);
        updateSceneDebounced({
          sceneId: scene._id,
          videoId: scene.videoId,
          updateDto: value,
          frameToSeek: frame + 8,
        });
      }
    });
    return () => unsubscribe();
  }, [form.watch]);

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: '30px' }}>
      <Col span={24}>
        <EditSceneText />
      </Col>
      <Col span={24}>
        <Typography.Title style={{ margin: 0, marginBottom: '5px' }} level={5}>
          Transition
        </Typography.Title>
        <TransitionSelector form={form} />
      </Col>
      <Col span={12}>
        <div>
          <Typography.Title
            style={{ margin: 0, marginBottom: '5px' }}
            level={5}
          >
            Pan Direction
          </Typography.Title>
          <FormSelect
            allowClear={true}
            style={{ height: '60px' }}
            form={form}
            placeholder={'Select Direction'}
            controllerProps={{
              name: 'panDirection',
            }}
            options={[
              ...Object.entries(PanDirection).map(([key, value]) => {
                const normalizedVal = value === PanDirection.Idle ? '' : value;

                return {
                  value: normalizedVal,
                  label: key,
                };
              }),
            ]}
          />
        </div>
      </Col>
      <Col span={12}>
        <div>
          <Typography.Title
            style={{ margin: 0, marginBottom: '5px' }}
            level={5}
          >
            Zoom
          </Typography.Title>
          <FormSelect
            allowClear={true}
            style={{ height: '60px' }}
            form={form}
            placeholder={'Select Zoom'}
            controllerProps={{
              name: 'zoomType',
            }}
            options={[
              ...Object.entries(ZoomType).map(([key, value]) => {
                const normalizedVal = value === ZoomType.Idle ? '' : value;

                return {
                  value: normalizedVal,
                  label: key,
                };
              }),
            ]}
          />
        </div>
      </Col>
    </Row>
  );
};

export default EditScene;
