import { createContext, useContext } from 'react';
import { UseResponsivenessProps } from '@monorepo/react-components';

export const ResponsiveContext = createContext<UseResponsivenessProps>(
  {} as UseResponsivenessProps
);
export const ResponsiveProvider = ResponsiveContext.Provider;

export const useResponsiveReady = () => {
  return useContext(ResponsiveContext);
};
