import React, { FunctionComponent, useEffect, useState } from 'react';
import { Empty, Pagination, Row, Skeleton } from 'antd';
import { useStore } from '../../../../../helpers/use-store';
import { Asset, PaginationResult } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';

import './my-assets.scss';
import SelectableAssetList from '../../../../../components/selectable-asset/selectable-asset-list';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';

interface Props {
  selectedAsset: Asset | PartialAsset | null;
  onSelect: (asset: Asset) => void;
}

const MyAssets: FunctionComponent<Props> = ({ onSelect, selectedAsset }) => {
  const {
    dataStore: { assetStore },
  } = useStore();

  const [paginationResult, setPaginationResult] = useState<
    PaginationResult<Asset>
  >({ results: [], total: -1 });
  const [currentPage, setCurrentPage] = useState(1);
  const { loadingState, updateLoadingState } = useLoading();
  const pageSize = 12; // Fixed page size as per requirement

  useEffect(() => {
    const init = async () => {
      updateLoadingState(LoadingState.Loading);
      try {
        const result = await assetStore.paginate({
          page: currentPage,
          limit: pageSize,
        });

        setPaginationResult(result);
      } catch (e) {
        console.error(`failed getting assets`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [currentPage, assetStore]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (paginationResult.total === 0 && loadingState === LoadingState.Loaded) {
    return <Empty style={{ marginTop: '25px' }} description={'No Assets'} />;
  }

  return (
    <div className="my-assets">
      {paginationResult.total === -1 &&
      loadingState === LoadingState.Loading ? (
        <Skeleton active={true} />
      ) : null}
      <SelectableAssetList
        currentAsset={selectedAsset as Asset}
        assets={paginationResult.results}
        onSelect={(asset) => {
          onSelect(asset as Asset);
        }}
      />
      <Pagination
        align={'end'}
        current={currentPage}
        total={paginationResult.total}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: '40px' }}
      />
    </div>
  );
};

export default MyAssets;
