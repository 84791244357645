import { Card } from 'antd';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Menu } from 'antd';
import './settings.scss';
import Profile from './profile/profile';
import SocialAccountsTab from './social-accounts-tab/social-accounts-tab';
import BillingPage from './billing/billing';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Helper to get current active key from path
  const getCurrentKey = () => {
    const path = location.pathname.split('/').pop();
    return path || 'profile';
  };

  const menuItems = [
    {
      label: 'Profile',
      key: 'profile',
    },
    {
      label: 'Billing',
      key: 'billing',
    },
    {
      label: 'Social Accounts',
      key: 'social-accounts',
    },
  ];

  return (
    <Card className="settings">
      <Menu
        mode="horizontal"
        selectedKeys={[getCurrentKey()]}
        onClick={({ key }) => navigate(`/settings/${key}`)}
        items={menuItems}
      />

      <div style={{ padding: '10px 0' }}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="billing" element={<BillingPage />} />
          <Route path="social-accounts" element={<SocialAccountsTab />} />
          <Route path="" element={<Navigate to="profile" replace />} />
        </Routes>
      </div>
    </Card>
  );
};

export default Settings;
