import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Slider } from 'antd';
import { SliderRangeProps, SliderSingleProps } from 'antd/es/slider';

export const FormSlider: FunctionComponent<
  Omit<FormFieldProps, 'render'> & {
    props?: SliderSingleProps | SliderRangeProps;
    label?: string;
  }
> = (formFieldProps) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <>
            {formFieldProps.label}
            <Slider {...field} {...formFieldProps.props} />
          </>
        );
      }}
    />
  );
};
