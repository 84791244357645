import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import {
  PaginationRequest,
  PaginationResult,
  Series,
  Video,
} from '@monorepo/types';

const routeName = 'series';

class SeriesService extends CrudService<Series> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    const route = routeName;

    super({ route: routeName, httpService });

    this.route = route;
  }

  async paginateVideos(seriesId: string, paginateRequest: PaginationRequest) {
    const response = await this.httpService.get<BaseResponse<PaginationResult>>(
      `${this.path}/${seriesId}/videos`,
      {
        params: paginateRequest,
      }
    );

    return response.data as PaginationResult<Video>;
  }

  async generateNextVideosMetadata(seriesId: string) {
    const response = await this.httpService.post<null, BaseResponse<boolean>>(
      `${this.path}/${seriesId}/generate-next`
    );

    return response.data;
  }

  async generateSeriesVideoAssets(seriesId: string, videoId: string) {
    const response = await this.httpService.post<null, BaseResponse<boolean>>(
      `${this.path}/${seriesId}/create-assets/${videoId}`
    );

    return response.data;
  }
}

export default SeriesService;
