import { EntityStore } from '@monorepo/client-common';
import { Scene, SubtitlesConfig, Timezone, Video } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import VideoService, {
  UpdateSocialPublishParams,
} from '../../../services/entities/video-service';

export default class VideoStore extends EntityStore<VideoService, Video> {
  constructor() {
    const {
      apiFactory: { videoService },
    } = getEnv();

    super(videoService);
  }

  getScenes(videoId: string) {
    return this.service.getScenes(videoId);
  }

  getProgress(videoId: string) {
    return this.service.getProgress(videoId);
  }

  render(videoId: string) {
    return this.service.render(videoId);
  }

  download(videoId: string) {
    return this.service.download(videoId);
  }

  async updateScene(
    sceneId: string,
    videoId: string,
    updateDto: Partial<Scene>
  ) {
    return this.service.updateScene(sceneId, videoId, updateDto);
  }

  async updateSubtitles(videoId: string, subtitlesConfig: SubtitlesConfig) {
    return this.service.updateSubtitles(videoId, subtitlesConfig);
  }

  async updateMetadata(
    videoId: string,
    metadata: Pick<Video, 'title' | 'description'>
  ) {
    return this.service.updateMetadata(videoId, metadata);
  }

  async updateVoiceover(
    videoId: string,
    voiceoverConfig: { voiceoverId: string }
  ) {
    return this.service.updateVoiceover(videoId, voiceoverConfig);
  }

  async updateBackgroundMusic(videoId: string, musicId?: string) {
    return this.service.updateBackgroundMusic(videoId, musicId);
  }

  async updateSocialPublish(params: UpdateSocialPublishParams) {
    return this.service.updateSocialPublish(params);
  }
}
