import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import AuthLayout from './auth-layout';
import {
  FormInput,
  FormPassword,
  LoadingState,
  Space,
  useLoading,
} from '@monorepo/react-components';
import { formEmailValidation, formPasswordValidation } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';
import GoogleLoginButton from './social/google-login-button';

interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const form = useForm<LoginFormData>();
  const notificationApi = useNotification();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);

  const onSubmit = async (data: LoginFormData) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.login(data.email, data.password);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed logging in, please contact support.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const handleGoogleLogin = () => {
    console.log('Google login clicked');
    // Handle Google login logic here
  };

  return (
    <AuthLayout title="Login">
      <Form onFinish={form.handleSubmit(onSubmit)} layout="vertical">
        <Space size={'large'} direction="vertical">
          <FormInput
            placeholder="Email"
            form={form}
            controllerProps={{
              name: 'email',
              rules: formEmailValidation(),
            }}
          />
          <FormPassword
            placeholder="Password"
            form={form}
            controllerProps={{
              name: 'password',
              rules: formPasswordValidation(),
            }}
          />
          <Button
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
            block
          >
            Login
          </Button>
        </Space>
      </Form>
      <Divider plain>Or</Divider>
      <GoogleLoginButton />

      <div className="auth-link">
        Don't have an account? <Link to="/register">Register here</Link>
      </div>
      <div className="auth-link">
        Forgot your password? <Link to="/forget-password">Click here</Link>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
