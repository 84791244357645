export enum VideoPart {
  Scenes = 'scenes',
  Subtitles = 'subtitles',
  Voiceover = 'voiceover',
  // Effects = 'effects',
  Music = 'music',
  Metadata = 'Metadata',
  EditScene = 'edit-scene',
}

export const TITLE_BY_VIDEO_PAGE = {
  [VideoPart.Scenes]: 'Scenes',
  [VideoPart.Subtitles]: 'Subtitles',
  [VideoPart.Voiceover]: 'Voiceover',
  // [VideoPart.Effects]: 'Effects',
  [VideoPart.Music]: 'Music',
  [VideoPart.Metadata]: 'Metadata',
  [VideoPart.EditScene]: 'Edit Scene',
};

export const BACK_BY_SCENE: {
  [key: string]: VideoPart;
} = {
  [VideoPart.EditScene]: VideoPart.Scenes,
};
