import { Divider, Space, Typography } from 'antd';
import AvatarUpload from './avatar-upload';
import { UserInfo } from './user-info';
import UpdatePassword from './update-password';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';

const { Title } = Typography;

const Profile = () => {
  const { isMobile } = useResponsiveReady();

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <div>
        <Title level={4}>Personal Information</Title>
        <Space
          direction={isMobile ? 'vertical' : 'horizontal'}
          align="start"
          size={100}
          style={{ width: '100%' }}
        >
          <AvatarUpload />
          <UserInfo />
        </Space>
      </div>
      <Divider />
      <div>
        <Title level={4}>Update Password</Title>
        <UpdatePassword />
      </div>
    </Space>
  );
};

export default Profile;
