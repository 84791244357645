import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Typography, Card, List } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../../../../helpers/use-store';
import { useNotification } from '../../../../hooks/use-notification';

const { Title, Text, Paragraph } = Typography;

export interface PlanChangeModalActions {
  open: (targetPlanId: string) => void;
}

const PlanChangeModal = forwardRef<PlanChangeModalActions>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetPlanId, setTargetPlanId] = useState<string>('');
  const { loadingState, updateLoadingState } = useLoading();
  const notificationApi = useNotification();
  const {
    dataStore: { planStore, userStore },
  } = useStore();

  useImperativeHandle(ref, () => ({
    open: (planId: string) => {
      setTargetPlanId(planId);
      setIsOpen(true);
    },
  }));

  const currentPlan = planStore.items.find(
    (plan) => plan._id === userStore.userSubscription.planId
  );
  const targetPlan = planStore.items.find((plan) => plan._id === targetPlanId);
  const isUpgrade = (targetPlan?.price || 0) > (currentPlan?.price || 0);

  const handleOk = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.changePlan(targetPlanId);

      // For Webhook
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await userStore.getLatestUser();

      notificationApi.success({
        placement: 'top',
        message: 'Plan change successful',
        description: isUpgrade
          ? 'Your plan has been upgraded. The partial amount has been charged.'
          : 'Your plan will be downgraded at the start of your next billing cycle.',
      });

      handleCancel();
    } catch (e) {
      console.error('Failed to change plan:', e);
      notificationApi.error({
        placement: 'top',
        message: 'Plan change failed',
        description:
          'We encountered an error while changing your plan. Please try again or contact support.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setTargetPlanId('');
  };

  if (!targetPlan || !currentPlan) {
    return null;
  }

  return (
    <Modal
      title={
        <div>
          <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
            {isUpgrade ? 'Upgrade' : 'Downgrade'} Plan
          </Title>
          <Text type="secondary">
            {isUpgrade
              ? 'Upgrade to enjoy more features immediately'
              : 'Your new plan will take effect in the next billing cycle'}
          </Text>
        </div>
      }
      open={isOpen}
      okText={isUpgrade ? 'Upgrade Now' : 'Confirm Downgrade'}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        loading: loadingState === LoadingState.Loading,
        type: isUpgrade ? 'primary' : 'default',
      }}
      width={600}
    >
      <ul style={{ paddingLeft: '20px' }}>
        {isUpgrade ? (
          <>
            <li>
              Your account will be immediately upgraded to {targetPlan.name}
            </li>
            <li>
              You'll be charged only for the price difference between your
              current and new plan for the remaining time in your billing cycle
            </li>
            <li>
              Your new credit balance will be calculated based on both the time
              remaining in your billing cycle and your current credit usage
            </li>
            <li>
              All new features and increased limits will be available right away
            </li>
            <li>Coupons are not applied on plan changes.</li>
          </>
        ) : (
          <>
            <li>
              Your current plan ({currentPlan.name}) will remain active until
              the end of this billing cycle
            </li>
            <li>
              At the start of your next billing cycle, you'll be moved to{' '}
              {targetPlan.name}
            </li>
            <li>
              No immediate changes will be made to your current features or
              limits
            </li>
          </>
        )}
      </ul>
    </Modal>
  );
});

export default PlanChangeModal;
