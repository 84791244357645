import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Button, Flex, Layout, Typography } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from './navbar/navbar';
import Header from './header/header';
import { useCurrentPage } from '../../hooks/use-current-page';

import './layout.scss';
import Footer from './footer/footer';
import { useResponsiveness } from '@monorepo/react-components';
import { useResponsiveReady } from '../../hooks/use-responive-ready';

const { Content } = Layout;
const { Title } = Typography;

const AppLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { firstPageTitle, menuItem, currentPage, isSinglePage } =
    useCurrentPage();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveReady();
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);

  useEffect(() => {
    setCollapsed(isMobile ? true : false);
  }, [isMobile]);

  const getCreateButton = () => {
    if (!menuItem?.createButton) {
      return null;
    }

    return (
      <Button
        type="primary"
        onClick={() => navigate(menuItem.createButton?.path || '')}
      >
        Create
      </Button>
    );
  };

  const isEditor =
    currentPage.includes('videos') &&
    !currentPage.includes('form') &&
    !isSinglePage;

  if (isEditor) {
    return children;
  }

  const shouldHideHeader = !menuItem?.label && !getCreateButton();

  // Add this function to handle overlay clicks
  const handleOverlayClick = () => {
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    <Layout
      className={`layout ${isMobile ? 'mobile' : ''} ${
        collapsed ? 'collapsed' : 'open'
      }`}
    >
      <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
      {isMobile && !collapsed && (
        <div className="mobile-overlay" onClick={handleOverlayClick} />
      )}
      <Layout>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="content-wrapper">
          <Content>
            {shouldHideHeader ? null : (
              <Flex
                style={{ marginTop: '20px', marginBottom: '20px' }}
                align={'center'}
                justify={'space-between'}
              >
                <Title style={{ margin: '0' }} level={4}>
                  {menuItem?.label}
                </Title>
                {getCreateButton()}
              </Flex>
            )}
            {/*<div className="body-bg">*/}
            {children}
            {/*</div>*/}
          </Content>
        </div>
        {/*<Footer />*/}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
