import React, { FunctionComponent } from 'react';
import { useYouTubeAuth } from './use-youtube-auth';
import { Button } from 'antd';
import { YoutubeOutlined } from '@ant-design/icons';
import { useStore } from '../../../helpers/use-store';

export interface YouTubeAuthModalProps {
  onAccountAdded: () => void;
}

export const YouTubeAuthModal: FunctionComponent<YouTubeAuthModalProps> = ({
  onAccountAdded,
}) => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { handleAuth } = useYouTubeAuth({
    onSuccess: onAccountAdded,
  });

  const isDisabled = userStore.currentUser.applicationConfig?.disableYoutube;

  return (
    <Button
      disabled={isDisabled}
      type="primary"
      icon={<YoutubeOutlined />}
      block
      onClick={handleAuth}
      danger
    >
      <span style={{ width: '100px' }}>YouTube</span>
    </Button>
  );
};
