import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { Plan } from '@monorepo/types';
import PlanService from '../../../services/entities/plan-service';

export default class PlanStore extends EntityStore<PlanService, Plan> {
  constructor() {
    const {
      apiFactory: { planService },
    } = getEnv();

    super(planService);
  }
}
