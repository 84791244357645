import {
  BaseEntityService,
  BaseResponse,
  HttpService,
} from '@monorepo/client-common';

const routeName = 'payments';

class PaymentsService extends BaseEntityService {
  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }

  async cancelSubscription(): Promise<boolean> {
    const url = `${this.path}/cancel-subscription`;

    const result = await this.httpService.post<null, BaseResponse<boolean>>(
      url
    );

    return result.data;
  }

  async renewSubscription(): Promise<boolean> {
    const url = `${this.path}/renew-subscription`;

    const result = await this.httpService.post<null, BaseResponse<boolean>>(
      url
    );

    return result.data;
  }

  async updatePaymentDetails(): Promise<boolean> {
    const url = `${this.path}/update-payment-details`;

    const result = await this.httpService.post<null, BaseResponse<boolean>>(
      url
    );

    return result.data;
  }

  async changePlan(planId: string): Promise<boolean> {
    const url = `${this.path}/change-plan`;

    const result = await this.httpService.post<
      { planId: string },
      BaseResponse<boolean>
    >(url, { planId });

    return result.data;
  }
}

export default PaymentsService;
