import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Skeleton,
  Tag,
  Typography,
} from 'antd';
import { Asset as AssetTypez, AssetType, Video } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import AssetComponent from '../../components/asset/asset';
import { LoadingState, Space, useLoading } from '@monorepo/react-components';
import EmptyVideos from './empty-videos';

import './videos.scss';
import { upperFirst } from 'lodash';
import { getStatusColor } from '../series/series-videos/series-videos';
import { useResponsiveReady } from '../../hooks/use-responive-ready';

const Videos = () => {
  const navigate = useNavigate();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);
  const [totalVideos, setTotalVideos] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [videos, setVideos] = useState<Video[]>([]);
  const {
    dataStore: { videoStore },
  } = useStore();
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        const videosResult = await videoStore.paginate({
          page: currentPage,
          limit: 10,
        });

        setVideos(videosResult.results);
        setTotalVideos(videosResult.total);
      } catch (e) {
        console.error(`failed fetching videos`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [currentPage]);

  const isLoading = loadingState === LoadingState.Loading;

  if (!isLoading && videoStore.items.length === 0) {
    return <EmptyVideos />;
  }

  return (
    <Row className="video-cards-container" gutter={[15, 15]}>
      {totalVideos > 6 ? (
        <Col style={{ marginTop: '25px' }} span={24}>
          <Flex justify={isMobile ? 'start' : 'end'}>
            <Pagination
              pageSize={10}
              showTotal={(total) => `Total ${total} Videos`}
              current={currentPage}
              total={totalVideos}
              onChange={(page) => setCurrentPage(page)}
            />
          </Flex>
        </Col>
      ) : null}
      <Col span={24}>
        {isLoading ? (
          <Skeleton active={true} />
        ) : (
          <Flex justify={'start'} gap={'large'} wrap={'wrap'}>
            {videos.map((video) => {
              const videoWithAsset = video as Video & {
                firstAsset: AssetTypez;
              };

              return (
                <Card
                  style={{
                    height: '550px',
                    width: isMobile ? 'auto' : '250px',
                  }}
                  key={video._id}
                  hoverable={true}
                  onClick={() => navigate(`/videos/${video._id}`)}
                  styles={{
                    cover: {
                      height: '70%',
                    },
                  }}
                  cover={
                    <AssetComponent
                      width={'100%'}
                      height={'100%'}
                      preview={false}
                      shouldLazy={false}
                      asset={
                        video.videoUrl
                          ? { url: video.videoUrl, type: AssetType.Video }
                          : videoWithAsset.firstAsset
                      }
                    />
                  }
                  className="video-card"
                >
                  <Space size={0} direction={'vertical'}>
                    <Flex justify={'space-between'}>
                      <Typography.Text type={'secondary'}>
                        {new Date(video.createdAt).toLocaleDateString()}
                      </Typography.Text>
                      <Tag color={getStatusColor(video.status)}>
                        {upperFirst(video.status)}
                      </Tag>
                    </Flex>

                    <Typography.Title
                      style={{ margin: 0, marginBottom: '25px' }}
                      level={5}
                    >
                      {video.title}
                    </Typography.Title>
                    {/*<Flex*/}
                    {/*  style={{*/}
                    {/*    bottom: '25px',*/}
                    {/*    position: 'absolute',*/}
                    {/*    width: '80%',*/}
                    {/*  }}*/}
                    {/*  justify={'center'}*/}
                    {/*  align={'center'}*/}
                    {/*>*/}
                    {/*  <Button>View</Button>*/}
                    {/*</Flex>*/}
                  </Space>
                </Card>
              );
            })}
          </Flex>
        )}
      </Col>
    </Row>
  );
};

export default observer(Videos);
