import React from 'react';
import {
  YoutubeOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TikTokOutlined,
} from '@ant-design/icons';
import { SocialProvider } from '@monorepo/types';

export interface SocialIconProps {
  provider: SocialProvider;
  className?: string;
  style?: React.CSSProperties;
}

// Custom TikTok icon since Ant Design doesn't have one
const TikTokIcon = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
    className={className}
    style={style}
  >
    <path d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 015.9 5.82s-.51.5 0 0A4.278 4.278 0 0116.6 5.82z" />
    <path d="M8.9 16.1c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1z" />
    <path d="M20.5 11c-.2-4.2-3.6-7.6-7.8-7.8V1.5h-3v1.7C5.4 3.4 2 6.8 1.8 11H0v3h1.8c.2 4.2 3.6 7.6 7.8 7.8v1.7h3v-1.7c4.2-.2 7.6-3.6 7.8-7.8H23v-3h-2.5zm-7.8 7.8v-3h-3v3c-2.7-.2-4.8-2.3-5-5h3v-3h-3c.2-2.7 2.3-4.8 5-5v3h3v-3c2.7.2 4.8 2.3 5 5h-3v3h3c-.2 2.7-2.3 4.8-5 5z" />
  </svg>
);

const SocialIcon: React.FC<SocialIconProps> = ({
  provider,
  className,
  style,
}) => {
  const iconProps = {
    className,
    style: {
      fontSize: '1.5em', // Default size
      ...style,
    },
  };

  switch (provider) {
    case SocialProvider.YouTube:
      return (
        <YoutubeOutlined
          {...iconProps}
          style={{ ...iconProps.style, color: '#FF0000' }}
        />
      );

    case SocialProvider.Facebook:
      return (
        <FacebookOutlined
          {...iconProps}
          style={{ ...iconProps.style, color: '#1877F2' }}
        />
      );

    case SocialProvider.Instagram:
      return (
        <InstagramOutlined
          {...iconProps}
          style={{ ...iconProps.style, color: '#E4405F' }}
        />
      );

    case SocialProvider.TikTok:
      return (
        <TikTokOutlined
          {...iconProps}
          style={{ ...iconProps.style, color: '#000' }}
        />
      );

    default:
      return null;
  }
};

export default SocialIcon;
