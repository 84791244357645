import React, { useRef } from 'react';
import { Button, Divider, Flex, Layout, Space, Typography } from 'antd';
import {
  CaretLeftOutlined,
  CloudOutlined,
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useVideoContext } from '../../../../hooks/use-video';
import { Link } from 'react-router-dom';
import { LoadingState } from '@monorepo/react-components';
import PublishVideoModal, {
  PublishVideoModalRef,
} from '../../export-modal/export-modal';
import HideMobile from '../../../../components/responsiveness/hide-mobile';

export const divider = (
  <Divider style={{ borderColor: '#e3e3e3', height: '30px' }} type="vertical" />
);

const VideoHeader = () => {
  const exportModalRef = useRef<PublishVideoModalRef>(null);
  const { video, requestLoadingState } = useVideoContext();

  const hasSeries = Boolean(video?.seriesId);
  const isLoading = requestLoadingState === LoadingState.Loading;

  return (
    <>
      <Layout.Header>
        <Flex
          justify={'space-between'}
          style={{ flexDirection: 'column' }}
          className="video-header"
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Video Editor
          </Typography.Title>
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            <Link to={hasSeries ? `/series/${video?.seriesId}` : `/videos`}>
              <CaretLeftOutlined /> Back to {hasSeries ? 'Series' : 'Videos'}
            </Link>
          </Typography.Text>
        </Flex>

        <Space size={16}>
          {/*<Tooltip placement={'bottom'} title={'Undo'}>*/}
          {/*  <Button*/}
          {/*    disabled={true}*/}
          {/*    type="text"*/}
          {/*    icon={<UndoOutlined />}*/}
          {/*    style={{ border: 'none' }}*/}
          {/*  />*/}
          {/*</Tooltip>*/}
          {/*<Tooltip placement={'bottom'} title={'Redo'}>*/}
          {/*  <Button*/}
          {/*    disabled={true}*/}
          {/*    type="text"*/}
          {/*    icon={<RedoOutlined />}*/}
          {/*    style={{ border: 'none' }}*/}
          {/*  />*/}
          {/*</Tooltip>*/}
          <HideMobile>
            {isLoading ? (
              <LoadingOutlined style={{ fontSize: '18px' }} />
            ) : (
              <CloudOutlined style={{ fontSize: '18px' }} />
            )}
            {divider}
          </HideMobile>
          <Button
            size={'middle'}
            type={'primary'}
            onClick={() => exportModalRef.current?.open()}
            icon={<ExportOutlined />}
          >
            Export / Share
          </Button>
        </Space>
      </Layout.Header>
      <PublishVideoModal ref={exportModalRef} />
    </>
  );
};

export default VideoHeader;
