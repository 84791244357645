import { observer } from 'mobx-react-lite';
import SocialAccountSelect from '../../social-accounts/social-account';

const SocialAccountsTab = () => {
  return (
    <div className="social-accounts-tab">
      <SocialAccountSelect />
    </div>
  );
};

export default observer(SocialAccountsTab);
