import { Player, PlayerRef } from '@remotion/player';
import {
  forwardRef,
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { calculateDurationInFrames } from './remotion-helpers/calculate-duration-frames';
import { RemotionMainContainer } from './remotion-containers/remotion-main-container';
import { RemotionConfig } from './remotion-types';
import { Skeleton } from 'antd';
import { prefetch } from 'remotion';

interface Props {
  config: RemotionConfig;
  style?: any;
  width?: string;
}

const _RemotionPlayer = forwardRef<PlayerRef, Props>(
  ({ config, style = {}, width = '500px' }, playerRef) => {
    const [assetsLoading, setAssetsLoading] = useState(true);
    const durationInFrames = calculateDurationInFrames(config);

    useEffect(() => {
      const freeFns: any = [];

      const init = async () => {
        const promises: any = [];

        config.assets.forEach((asset) => {
          const { free, waitUntilDone } = prefetch(asset.src, {
            method: 'blob-url',
            contentType: asset.contentType,
          });

          promises.push(waitUntilDone);
          freeFns.push(free);
        });

        config.audioTracks?.forEach((audioTrack) => {
          audioTrack.forEach((track) => {
            const { free, waitUntilDone } = prefetch(track.src, {
              method: 'blob-url',
              contentType: 'mp3',
            });

            promises.push(waitUntilDone);
            freeFns.push(free);
          });
        });

        await Promise.all(promises);

        setTimeout(() => {
          setAssetsLoading(false);
        }, 500);

        return freeFns;
      };

      init();

      return () => {
        freeFns.forEach((fn) => fn());
      };
    }, []);

    console.log('remotion re -render');

    return (
      <div
        style={{
          margin: '0 auto',
          width,
          height: '70vh',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            aspectRatio: `${config.width} / ${config.height}`,
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        >
          {assetsLoading ? (
            <Skeleton />
          ) : (
            <Player
              ref={playerRef}
              playbackRate={1}
              className={'remotion-ninja-player'}
              component={RemotionMainContainer}
              durationInFrames={durationInFrames}
              compositionWidth={config.width}
              compositionHeight={config.height}
              fps={30}
              style={{ width: '100%', borderRadius: '12px', ...style }}
              controls={false}
              inputProps={{ config }}
            />
          )}
        </div>
      </div>
    );
  }
);

export const RemotionPlayer = memo(_RemotionPlayer, (prevProps, nextProps) => {
  // console.log(`oldProps`, prevProps);
  // console.log(`nextProps`, nextProps);
  // console.log(
  //   `diff: ${
  //     JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config)
  //   }`
  // );
  return JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config);
});
