import React, { FunctionComponent } from 'react';
import { Asset } from '@monorepo/types';
import { Button } from 'antd';
import AssetComponent from '../asset/asset';

export type PartialAsset = Pick<Asset, 'url' | 'type' | 'prompt'>;

interface Props {
  asset: PartialAsset;
  isSelected?: boolean;
  onSelect: (partialAsset: PartialAsset) => void;
  height?: string;
}

const SelectableAsset: FunctionComponent<Props> = ({
  asset,
  onSelect,
  height,
  isSelected = false,
}) => {
  const { type, url } = asset;

  return (
    <div
      key={type}
      className={`${isSelected ? 'selected' : ''}  normal-border`}
      style={{
        padding: '5px',
        position: 'relative',
        borderRadius: 4,
        overflow: 'hidden',
      }}
    >
      <AssetComponent height={height} asset={asset} />
      <div>
        <Button
          size={'small'}
          onClick={() => onSelect(asset)}
          style={{ width: '100%' }}
          type={'primary'}
        >
          Select
        </Button>
      </div>
    </div>
  );
};

export default SelectableAsset;
