import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { Asset } from '@monorepo/types';
import AssetService from '../../../services/entities/asset-service';

export default class AssetStore extends EntityStore<AssetService, Asset> {
  constructor() {
    const {
      apiFactory: { assetService },
    } = getEnv();

    super(assetService);
  }

  async upload(file: File) {
    return this.service.upload(file);
  }

  async uploadByUrl(asset: Pick<Asset, 'url' | 'type' | 'origin' | 'prompt'>) {
    return this.service.uploadByUrl(asset);
  }

  async generateAIImage(prompt: string) {
    return this.service.generateAIImage(prompt);
  }

  async searchStockFootage(prompt: string) {
    return this.service.searchStockFootage(prompt);
  }
}
