import { Button, Col, Row, Typography } from 'antd';
import {
  FormTextArea,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { Scene } from '@monorepo/types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useVideoContext } from '../../../../hooks/use-video';
import { useNotification } from '../../../../hooks/use-notification';

export type EditSceneTextForm = Pick<Scene, 'text'>;

const EditSceneText = () => {
  const { loadingState, updateLoadingState } = useLoading();
  const form = useForm<EditSceneTextForm>();
  const {
    updateScene,
    selectedScene: scene,
    getSceneStartFrame,
  } = useVideoContext();
  const notificationApi = useNotification();

  const resetForm = () => {
    if (scene) {
      form.reset({
        text: scene.text,
      });
    }
  };

  useEffect(() => {
    resetForm();
  }, [scene]);

  const save = async () => {
    if (!scene) {
      return;
    }

    if (!form.formState.isDirty) {
      return;
    }

    const values = form.getValues();

    try {
      updateLoadingState(LoadingState.Loading);

      const frame = getSceneStartFrame(scene._id);

      await updateScene({
        sceneId: scene._id,
        videoId: scene.videoId,
        updateDto: {
          text: values.text,
        },
        frameToSeek: frame,
        shouldUpdateState: true,
      });

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Text and Voiceover updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Not enough credits.',
      });

      console.error(`failed`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);

      form.reset({ text: values.text });
    }
  };

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: '30px' }}>
      <Col span={24}>
        <Typography.Title style={{ margin: 0, marginBottom: '5px' }} level={5}>
          Text
        </Typography.Title>
        <FormTextArea
          textareaProps={{
            rows: 6,
            maxLength: 1500,
            showCount: true,
          }}
          form={form}
          controllerProps={{
            name: 'text',
          }}
        />

        <Button
          type={'primary'}
          style={{ width: '100%', marginTop: '15px' }}
          {...{
            loading: loadingState === LoadingState.Loading,
            disabled: !form.formState.isDirty,
            onClick: save,
          }}
        >
          Update
        </Button>
        <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
          Updating the text will regenerate the voiceover of this scene.
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default EditSceneText;
