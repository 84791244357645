export interface SubtitlesConfig {
  display: boolean;
  numberOfWords: number;
  uppercase?: boolean;
  fontName: string;
  fontSize: number;
  primaryColor: string;
  highlightColor?: string;
  outline: number;
  outlineColor: string;
  backColor?: string;
  positionY: number;
}

export const requiredFontFileFonts = [
  'Titan One',
  'Ranchers',
  'Rampart One',
  'Permanent Marker',
  'Luckiest Guy',
  'Knewave',
  'Jua',
  'Creepster',
  'Caveat',
  'Bungee',
  'Bebas Neue',
  'Bangers',
  'Bakbak One',
];

export const subtitleFonts = [
  // New fonts added at the beginning
  ...requiredFontFileFonts,
  // Existing fonts
  'Arial',
  'Helvetica',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
  'Georgia',
  'Garamond',
  'Courier New',
  'Brush Script MT',
  'Impact',
  'Comic Sans MS',
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Oswald',
  'Raleway',
  'Merriweather',
  'PT Sans',
  'Ubuntu',
  'Playfair Display',
  'Fira Sans',
  'Noto Sans',
  'Source Sans Pro',
  'Nunito',
  'Titillium Web',
  'Poppins',
  'Rubik',
  'Work Sans',
  'Quicksand',
  'Karla',
];

export const baseSubtitles: SubtitlesConfig = {
  display: true,
  numberOfWords: 2,
  fontSize: 80,
  uppercase: true,
  fontName: 'Luckiest Guy',
  primaryColor: '#FFFFFF',
  highlightColor: '#f14e4e',
  outlineColor: '#000000',
  outline: 2,
  backColor: '#00000000',
  positionY: 50,
};
