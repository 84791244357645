export const SAMPLE_SCRIPT = `Want to know how I went from 0 to 100,000 subscribers in just one month using YouTube Shorts? Let me show you my exact process.

I struggled for years trying to grow on YouTube. Long-form videos took forever to make, and nobody was watching them. I was about to give up when I discovered the power of Shorts.

Here's what changed everything. First, I analyzed the top 100 performing Shorts in my niche. I noticed they all followed a similar pattern - a hook in the first 2 seconds, a clear problem-solution format, high-energy delivery, and a strong call-to-action.

My winning formula became simple but effective. I filmed in vertical format, kept everything under 60 seconds, started with a pattern interrupt, used trending music, added captions for accessibility, and posted 3-4 times daily.

The results were incredible. My first video hit 1.2 million views. I was getting an average view duration of 75%, consistent 100k+ views per video, and I got monetization enabled in just 2 weeks.

Here's what made the difference: consistency over perfection, batch filming - I made 20 videos in one day, researched trending hashtags, used strong call-to-actions, and engaged with my community in the first hour.

Let me show you my actual results. In the first week, I hit 1,000 subscribers. By week two, 15,000. Week three brought me to 45,000. And by the end of the month, I crossed 100,000 subscribers.

Want to know the biggest secret? It's not about being perfect. It's about being consistent and authentic. Every single day, I showed up and posted, even when the videos weren't perfect.

Want to learn more about my exact process? Follow for daily tips on growing your YouTube channel. Drop a 🔥 if you want a detailed breakdown of my content strategy.`;
