import { Card, Col, Empty, Image, Row, Skeleton, Typography } from 'antd';
import { Asset, AssetType } from '@monorepo/types';
import React, { FunctionComponent } from 'react';
import { PartialAsset } from '../../../../components/selectable-asset/selectable-asset';
import AssetComponent from '../../../../components/asset/asset';

const { Title, Paragraph } = Typography;

interface Props {
  asset?: Asset | PartialAsset;
}

const CurrentAsset: FunctionComponent<Props> = ({ asset }) => {
  if (!asset) {
    return <Empty style={{ marginTop: '25px' }} description={'No Asset Yet'} />;
  }

  return (
    <Card key={asset.url} style={{ width: '100%', marginBottom: 16 }}>
      <Row gutter={16} align="top">
        <Col span={8}>
          <AssetComponent shouldLazy={false} height={'400px'} asset={asset} />
        </Col>
        <Col span={16}>
          <Title level={5}>Prompt</Title>
          <Paragraph ellipsis={{ rows: 20, expandable: true, symbol: 'more' }}>
            {asset.prompt ? asset.prompt : 'No prompt available'}
          </Paragraph>
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentAsset;
