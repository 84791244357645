import React from 'react';
import { Layout, Card, Typography } from 'antd';

import './auth.scss';

const { Content } = Layout;
const { Title } = Typography;

interface AuthLayoutProps {
  title: string;
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title }) => {
  return (
    <div className="auth-page">
      <Layout className="auth-layout">
        <Content className="auth-content">
          <Card className="auth-card">
            <Title level={2} className="auth-title">
              {title}
            </Title>
            {children}
          </Card>
        </Content>
      </Layout>
    </div>
  );
};

export default AuthLayout;
