import { orderBy } from 'lodash';
import {
  Asset as RemotionAsset,
  AUDIO_DELAY_FRAMES,
  CaptionSettings,
  RemotionConfig,
  Subtitle,
  TRANSITION_FRAMES,
} from '../remotion-types';
import {
  Asset,
  isRTL,
  Languages,
  Scene,
  SubtitlesConfig,
  TransitionEffect,
  Video,
} from '@monorepo/types';
import { calculateDurationInFrames } from '../remotion-helpers/calculate-duration-frames';

function createSubtitlesWithWordGroups(
  orderedScenes: any[],
  wordsPerGroup = 4
): Subtitle[] {
  let currentTime = 0;
  const subtitles: Subtitle[] = [];

  orderedScenes.forEach((scene, sceneIndex) => {
    const additionInSeconds = AUDIO_DELAY_FRAMES / 30;

    for (let i = 0; i < scene.words.length; i += wordsPerGroup) {
      const wordGroup = scene.words.slice(i, i + wordsPerGroup);

      if (wordGroup.length > 0) {
        const startTime = currentTime + wordGroup[0].start;
        const endTime = currentTime + wordGroup[wordGroup.length - 1].end;

        subtitles.push({
          startTime,
          endTime,
          words: wordGroup.map((word) => ({
            startTime: currentTime + word.start,
            endTime: currentTime + word.end,
            text: word.word,
          })),
        });
      }
    }

    // 25 frames
    currentTime += scene.audioDuration;

    // subtitles.push({
    //   startTime:  currentTime,
    //   endTime: currentTime + additionInSeconds,
    //   words: []
    // });
    //
    // currentTime += additionInSeconds;
  });

  return subtitles;
}

export function buildConfig({
  backgroundMusicUrl,
  subtitlesConfig,
  assets,
  scenes,
  language = Languages.English,
}: {
  backgroundMusicUrl?: string;
  scenes: Scene[];
  subtitlesConfig?: SubtitlesConfig;
  assets: Asset[];
  language?: Languages;
}) {
  // Create asset URL lookup map
  const assetMap = new Map<string, Asset>(
    assets.map((asset) => [asset._id.toString(), asset])
  );

  // Sort scenes by index
  const sortedScenes = orderBy(scenes, 'index', 'asc');

  // Calculate total duration for timing
  let cumulativeDuration = 0;

  const audioTracks: any = [
    // Voiceover tracks
    sortedScenes.map((scene) => {
      const track = {
        src: `${scene.audioUrl}?date=${Date.now()}`,
        startAt: cumulativeDuration,
        duration: scene.audioDuration,
        volume: 1,
      };
      cumulativeDuration += scene.audioDuration;
      return track;
    }),
  ];

  if (backgroundMusicUrl) {
    audioTracks.push([
      {
        src: backgroundMusicUrl,
        startAt: 0,
        volume: 0.1,
        loop: true,
        duration: cumulativeDuration,
      },
    ]);
  }

  let captionSettings: Partial<CaptionSettings> = {};

  if (subtitlesConfig) {
    captionSettings = {
      display: subtitlesConfig.display,
      numberOfWords: subtitlesConfig.numberOfWords,
      uppercase: subtitlesConfig.uppercase,
      fontSize: subtitlesConfig.fontSize,
      fontFamily: subtitlesConfig.fontName,
      textColor: subtitlesConfig.primaryColor,
      currentWordColor: subtitlesConfig.highlightColor,
      shadowColor: subtitlesConfig.outlineColor,
      shadowBlur: subtitlesConfig.outline,
      currentWordBackgroundColor: subtitlesConfig.backColor || 'transparent',
      positionY: subtitlesConfig.positionY,
    };
  }

  const remotionAssets: RemotionAsset[] = sortedScenes.map((scene) => {
    const asset = assetMap.get(scene.assetId);
    const type = asset?.type || 'image';
    const src = asset?.url || '';
    const format = asset?.format || '';

    return {
      type,
      src,
      contentType: format,
      duration: scene.audioDuration,
      transition: scene.transition,
      panDirection: scene.panDirection,
      zoomType: scene.zoomType,
    };
  });

  const config: RemotionConfig = {
    assets: remotionAssets,
    audioTracks,
    subtitles: createSubtitlesWithWordGroups(
      sortedScenes,
      captionSettings?.numberOfWords || 2
    ),
    isRtl: isRTL(language),
    captionSettings: captionSettings,
    width: 1080,
    height: 1920,
    fps: 30,
    durationInFrames: 90,
  };

  const durationInFrames = calculateDurationInFrames(config);

  config.durationInFrames = durationInFrames;

  return config;
}
