const removeWhitespace = (str: string) => str.replace(/\s/g, '');

export const importFont = async (fontName: string): Promise<any> => {
  const importName = removeWhitespace(fontName);

  switch (importName) {
    case 'TitanOne':
      return await import('@remotion/google-fonts/TitanOne');
    case 'Ranchers':
      return await import('@remotion/google-fonts/Ranchers');
    case 'RampartOne':
      return await import('@remotion/google-fonts/RampartOne');
    case 'PermanentMarker':
      return await import('@remotion/google-fonts/PermanentMarker');
    case 'LuckiestGuy':
      return await import('@remotion/google-fonts/LuckiestGuy');
    case 'Knewave':
      return await import('@remotion/google-fonts/Knewave');
    case 'Jua':
      return await import('@remotion/google-fonts/Jua');
    case 'Creepster':
      return await import('@remotion/google-fonts/Creepster');
    case 'Caveat':
      return await import('@remotion/google-fonts/Caveat');
    case 'Bungee':
      return await import('@remotion/google-fonts/Bungee');
    case 'BebasNeue':
      return await import('@remotion/google-fonts/BebasNeue');
    case 'Bangers':
      return await import('@remotion/google-fonts/Bangers');
    case 'BakbakOne':
      return await import('@remotion/google-fonts/BakbakOne');
    case 'Roboto':
      return await import('@remotion/google-fonts/Roboto');
    case 'OpenSans':
      return await import('@remotion/google-fonts/OpenSans');
    case 'Lato':
      return await import('@remotion/google-fonts/Lato');
    case 'Montserrat':
      return await import('@remotion/google-fonts/Montserrat');
    case 'Oswald':
      return await import('@remotion/google-fonts/Oswald');
    case 'Raleway':
      return await import('@remotion/google-fonts/Raleway');
    case 'Merriweather':
      return await import('@remotion/google-fonts/Merriweather');
    case 'PTSans':
      return await import('@remotion/google-fonts/PTSans');
    case 'Ubuntu':
      return await import('@remotion/google-fonts/Ubuntu');
    case 'PlayfairDisplay':
      return await import('@remotion/google-fonts/PlayfairDisplay');
    case 'FiraSans':
      return await import('@remotion/google-fonts/FiraSans');
    case 'NotoSans':
      return await import('@remotion/google-fonts/NotoSans');
    case 'Nunito':
      return await import('@remotion/google-fonts/Nunito');
    case 'TitilliumWeb':
      return await import('@remotion/google-fonts/TitilliumWeb');
    case 'Poppins':
      return await import('@remotion/google-fonts/Poppins');
    case 'Rubik':
      return await import('@remotion/google-fonts/Rubik');
    case 'WorkSans':
      return await import('@remotion/google-fonts/WorkSans');
    case 'Quicksand':
      return await import('@remotion/google-fonts/Quicksand');
    case 'Karla':
      return await import('@remotion/google-fonts/Karla');
  }
};
