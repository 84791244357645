import React, { useMemo } from 'react';
import { AbsoluteFill, interpolate, spring } from 'remotion';
import type {
  TransitionPresentation,
  TransitionPresentationComponentProps,
} from '@remotion/transitions';

type PullInTransitionProps = {
  width: number;
  height: number;
};

const PullInPresentation: React.FC<
  TransitionPresentationComponentProps<PullInTransitionProps>
> = ({ children, presentationDirection, presentationProgress }) => {
  const progress = spring({
    fps: 30,
    from: 0,
    to: 1,
    frame: presentationProgress * 15,
    config: {
      damping: 200,
      stiffness: 200,
      mass: 0.5,
    },
  });

  const scale = interpolate(
    progress,
    [0, 1],
    presentationDirection === 'entering' ? [5, 1] : [1, 5]
  );

  const opacity = interpolate(
    progress,
    [0, 0.2, 1],
    presentationDirection === 'entering' ? [1, 1, 1] : [1, 1, 1]
  );

  const style: React.CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      transform: `scale(${scale})`,
      opacity,
    };
  }, [scale, opacity]);

  return (
    <AbsoluteFill>
      <AbsoluteFill style={style}>{children}</AbsoluteFill>
    </AbsoluteFill>
  );
};

export const pullInTransition = (
  props: PullInTransitionProps
): TransitionPresentation<PullInTransitionProps> => {
  return { component: PullInPresentation, props };
};
