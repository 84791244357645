import { EntityStore } from '@monorepo/client-common';
import SeriesService from '../../../services/entities/series-service';
import { PaginationRequest, Series } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export default class SeriesStore extends EntityStore<SeriesService, Series> {
  constructor() {
    const {
      apiFactory: { seriesService },
    } = getEnv();

    super(seriesService);
  }

  async paginateVideos(seriesId: string, paginateRequest: PaginationRequest) {
    return this.service.paginateVideos(seriesId, paginateRequest);
  }

  async generateNextVideosMetadata(seriesId: string) {
    return this.service.generateNextVideosMetadata(seriesId);
  }

  async generateSeriesVideoAssets(seriesId: string, videoId: string) {
    return this.service.generateSeriesVideoAssets(seriesId, videoId);
  }
}
