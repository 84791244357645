import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider, notification } from 'antd';

import './app.scss';
import { loggedInRouter } from '../routers/logged-in';
import { loggedOutRouter } from '../routers/logged-out';
import { useStore } from '../helpers/use-store';
import { observer } from 'mobx-react-lite';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
  UseResponsivenessProps,
  useThemeToken,
} from '@monorepo/react-components';
import { NotificationProvider } from '../hooks/use-notification';
import { ResponsiveProvider } from '../hooks/use-responive-ready';

const App = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);
  const [responsiveReady, setResponsiveReady] = useState(false);
  const responsivenessValues = useResponsiveness();
  const {
    dataStore: { userStore },
  } = useStore();
  const { theme } = useThemeToken();

  useEffect(() => {
    const init = async () => {
      try {
        await userStore.login();
      } catch (e) {
        console.error(`failed login`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (responsivenessValues.isMobile !== undefined) {
      setResponsiveReady(true);
    }
  }, [responsivenessValues.isMobile]);

  const router = userStore.isLoggedIn ? loggedInRouter : loggedOutRouter;

  if (loadingState === LoadingState.Loading || !responsiveReady) {
    return null;
  }

  return (
    <ConfigProvider theme={{ cssVar: true, algorithm: theme.defaultAlgorithm }}>
      <ResponsiveProvider
        value={responsivenessValues as UseResponsivenessProps}
      >
        <NotificationProvider value={notificationApi}>
          <RouterProvider router={router} />
          {contextHolder}
        </NotificationProvider>
      </ResponsiveProvider>
    </ConfigProvider>
  );
};

export default observer(App);
