import { IdIdentifier, Timestamps, Timezone, UserIdentifier } from '../common';
import { VideoSettings } from '../video/video';

export type Series = IdIdentifier &
  Timestamps &
  UserIdentifier &
  VideoSettings & {
    name: string;
    socialAccountId: string;
    subjectType: SeriesSubject;
    duration: number;
    script: string;
    videosPerDay: number;
    isActive: boolean;
    timezone: Timezone;
  };

export enum SeriesSubject {
  Custom = 'custom',
  RandomAIStory = 'random-ai-story',
  ScaryStories = 'scary-stories',
  BedtimeStories = 'bedtime-stories',
  InterestingHistory = 'interesting-history',
  UrbanLegend = 'urban-legend',
  MotivationalStories = 'motivational-stories',
  FunFacts = 'fun-facts',
  LongFormJokes = 'long-form-jokes',
  LifeProTip = 'life-pro-tip',
  ELI5 = 'eli5',
  Philosophy = 'philosophy',
  ProductMarketing = 'product-marketing',
}
