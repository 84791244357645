import React, { FunctionComponent } from 'react';
import {
  Alert,
  Checkbox,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { privacyLabels, PrivacyLevel, TiktokMetadata } from '@monorepo/types';

const { Text, Title } = Typography;

interface Props {
  metadata: TiktokMetadata;
  onUpdate: (metadata: Partial<TiktokMetadata>) => void;
}

const TikTokPostOptions: FunctionComponent<Props> = ({
  metadata,
  onUpdate,
}) => {
  const handlePrivacyChange = (value: PrivacyLevel) => {
    if (
      metadata.brandOptions.brandedContent &&
      value === PrivacyLevel.SELF_ONLY
    )
      return;
    onUpdate({ privacy: value });
  };

  const handleInteractionChange = (
    key: keyof Pick<
      TiktokMetadata['interactions'],
      'comment' | 'duet' | 'stitch'
    >,
    checked: boolean
  ) => {
    onUpdate({
      interactions: { ...metadata.interactions, [key]: checked },
    });
  };

  const privacyLevels =
    metadata.privacyLevels && metadata.privacyLevels.length > 0
      ? metadata.privacyLevels
      : Object.values(PrivacyLevel);

  const handleBrandOptionChange = (
    key: 'yourBrand' | 'brandedContent',
    checked: boolean
  ) => {
    if (
      key === 'brandedContent' &&
      checked &&
      metadata.privacy === PrivacyLevel.SELF_ONLY
    ) {
      const privacyLevel = privacyLevels.includes(
        PrivacyLevel.PUBLIC_TO_EVERYONE
      )
        ? PrivacyLevel.PUBLIC_TO_EVERYONE
        : PrivacyLevel.MUTUAL_FOLLOW_FRIENDS;

      onUpdate({
        privacy: privacyLevel,
        brandOptions: { ...metadata.brandOptions, [key]: checked },
      });
    } else {
      onUpdate({
        brandOptions: { ...metadata.brandOptions, [key]: checked },
      });
    }
  };

  const getAlertMessage = () => {
    if (
      metadata.brandOptions.brandedContent &&
      metadata.brandOptions.yourBrand
    ) {
      return "Your video will be labeled as 'Paid partnership'";
    }
    if (metadata.brandOptions.brandedContent) {
      return "Your video will be labeled as 'Paid partnership'";
    }
    if (metadata.brandOptions.yourBrand) {
      return "Your video will be labeled as 'Promotional content'";
    }
    return 'You need to indicate if your content promotes yourself, a third party, or both';
  };

  const interactionItems = [
    { key: 'comment', disabled: metadata.interactions.commentDisabled },
    { key: 'duet', disabled: metadata.interactions.duetDisabled },
    { key: 'stitch', disabled: metadata.interactions.stitchDisabled },
  ] as const;

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <div>
          {/*<Title level={5} style={{ margin: '0px' }}>Who can view this video</Title>*/}
          <Select
            placeholder="Who can view this video"
            style={{ width: '100%' }}
            value={metadata.privacy}
            onChange={handlePrivacyChange}
          >
            {privacyLevels.map((privacyLevel) => (
              <Select.Option
                key={privacyLevel}
                value={privacyLevel}
                disabled={
                  privacyLevel === PrivacyLevel.SELF_ONLY &&
                  metadata.brandOptions.brandedContent
                }
              >
                <Tooltip
                  title={
                    privacyLevel === PrivacyLevel.SELF_ONLY &&
                    metadata.brandOptions.brandedContent
                      ? 'Branded content visibility cannot be set to private'
                      : ''
                  }
                >
                  {privacyLabels[privacyLevel]}
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <Title level={5} style={{ margin: '0px' }}>
            Allow users to
          </Title>
          <Space>
            {interactionItems.map(({ key, disabled }) => (
              <Checkbox
                key={key}
                checked={metadata.interactions[key]}
                disabled={disabled}
                onChange={(e) => handleInteractionChange(key, e.target.checked)}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Checkbox>
            ))}
          </Space>
        </div>

        <div>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <div>
                <Title level={5} style={{ margin: 0 }}>
                  Disclose video content
                </Title>
                <Text type="secondary">
                  Indicate whether this content promotes yourself, a brand,
                  product or service
                </Text>
              </div>
              <Switch
                checked={metadata.isDisclosureEnabled}
                onChange={(checked) =>
                  onUpdate({
                    isDisclosureEnabled: checked,
                    brandOptions: { yourBrand: false, brandedContent: false },
                  })
                }
              />
            </div>

            {metadata.isDisclosureEnabled && (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                  message={getAlertMessage()}
                  type="info"
                  showIcon
                  icon={<InfoCircleOutlined />}
                />

                <Space direction="vertical" style={{ width: '100%' }}>
                  <Tooltip title="This content will be classified as Brand Organic">
                    <Checkbox
                      checked={metadata.brandOptions.yourBrand}
                      onChange={(e) =>
                        handleBrandOptionChange('yourBrand', e.target.checked)
                      }
                    >
                      <div>
                        <Text strong>Your brand</Text>
                        <br />
                        <Text type="secondary">
                          You are promoting yourself or your own business
                        </Text>
                      </div>
                    </Checkbox>
                  </Tooltip>

                  <Tooltip title="This content will be classified as Branded Content">
                    <Checkbox
                      checked={metadata.brandOptions.brandedContent}
                      onChange={(e) =>
                        handleBrandOptionChange(
                          'brandedContent',
                          e.target.checked
                        )
                      }
                    >
                      <div>
                        <Text strong>Branded content</Text>
                        <br />
                        <Text type="secondary">
                          You are promoting another brand or a third party
                        </Text>
                      </div>
                    </Checkbox>
                  </Tooltip>
                </Space>
              </Space>
            )}
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default TikTokPostOptions;
