import React, { useCallback, useRef } from 'react';
import { Node } from 'gl-react';

type GLTransitionProps = {
  transition: {
    glsl: string;
    defaultParams?: Record<string, any>;
  };
  transitionParams?: Record<string, any>;
  progress: number;
  from: any;
  to: any;
  width: number;
  height: number;
};

const GLTransition: React.FC<GLTransitionProps> = ({
  transition,
  transitionParams,
  progress,
  from,
  to,
  width,
  height,
}) => {
  const nodeRef = useRef<any>(null);

  const getUniformsWithProgress = (progress: number) => {
    const { defaultParams } = transition;
    return {
      ...defaultParams,
      ...transitionParams,
      progress,
      from,
      to,
      ratio: width / height,
    };
  };

  const setProgress = (progress: number) => {
    if (nodeRef.current) {
      nodeRef.current.setDrawProps({
        uniforms: getUniformsWithProgress(progress),
      });
    }
  };

  const shader = {
    frag: `
      precision highp float;
      varying vec2 uv;
      uniform float progress, ratio;
      uniform sampler2D from, to;
      vec4 getFromColor(vec2 uv){return texture2D(from, uv);}
      vec4 getToColor(vec2 uv){return texture2D(to, uv);}
      ${transition.glsl}
      void main(){gl_FragColor=transition(uv);}
    `,
  };

  return (
    <Node
      ref={nodeRef}
      shader={shader}
      ignoreUnusedUniforms={['ratio']}
      uniforms={getUniformsWithProgress(progress)}
    />
  );
};

export default GLTransition;
