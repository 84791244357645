import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export interface UseResponsivenessProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopXl: boolean;
  isDesktopXxl: boolean;
}

export const useResponsiveness = () => {
  const screens = useBreakpoint();

  const isMobile = screens['xs'];
  const isDesktop = screens['lg'] || screens['xl'] || screens['xxl'];
  const isDesktopXl = screens['xl'] || screens['xxl'];
  const isDesktopXxl = screens['xxl'];
  const isTablet = (!isMobile && !isDesktop) || (screens['sm'] && !isDesktop);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopXl,
    isDesktopXxl,
  };
};
