import { Button, Tooltip } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';

interface Props {
  sceneId?: string;
}

const ReplayScene: FunctionComponent<Props> = ({ sceneId }) => {
  const { selectedScene, movePlayerToFrame, getSceneStartFrame } =
    useVideoContext();

  const replayScene = () => {
    const currentSceneId = sceneId || selectedScene?._id;

    if (currentSceneId) {
      const startFrame = getSceneStartFrame(currentSceneId);
      movePlayerToFrame(startFrame);
    }
  };

  return (
    <Tooltip placement={'right'} title={'Replay Scene'}>
      <Button
        onClick={() => replayScene()}
        type={'text'}
        icon={
          <PlayCircleOutlined style={{ marginTop: '2px', fontSize: '18px' }} />
        }
      />
    </Tooltip>
  );
};

export default ReplayScene;
