import { PanDirection } from '@monorepo/types';
import { interpolate } from 'remotion';

export const calculatePan = (
  direction: PanDirection,
  progress: number
): { x: number; y: number; scale: number } => {
  const START_SCALE = 1.3;
  const panAmount = 10;

  const positions = {
    [PanDirection.Bottom]: {
      x: 0,
      y: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.Top]: {
      x: 0,
      y: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.Right]: {
      x: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: 0,
      scale: START_SCALE,
    },
    [PanDirection.Left]: {
      x: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: 0,
      scale: START_SCALE,
    },
    [PanDirection.BottomLeft]: {
      x: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.BottomRight]: {
      x: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.TopLeft]: {
      x: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.TopRight]: {
      x: interpolate(progress, [0, 1], [0, -panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      y: interpolate(progress, [0, 1], [0, panAmount], {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }),
      scale: START_SCALE,
    },
    [PanDirection.Idle]: {
      x: 0,
      y: 0,
      scale: 1,
    },
  };

  return positions[direction] || { x: 0, y: 0, scale: 1 };
};
