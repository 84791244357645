import { IdIdentifier, Timestamps, UserIdentifier } from '../common';

export type Asset = IdIdentifier &
  Timestamps &
  UserIdentifier & {
    url: string;
    type: AssetType;
    origin: AssetOrigin;
    isManual: boolean;
    prompt?: string;
    format: string;
  };

export enum AssetType {
  Image = 'image',
  Video = 'video',
}

export enum AssetOrigin {
  AIImages = 'ai-images',
  AIVideos = 'ai-videos',
  StockVideos = 'stock-videos',
  StockImages = 'stock-images',
  Manual = 'manual',
}

export interface SearchResult {
  url: string;
  type: AssetType;
}

export enum AiAssetStyles {
  REALISTIC_4K = '4k realistic',
  LINE_ART = 'Line Art',
  CARTOON = 'Cartoon',
  COLLAGE = 'Collage',
  KAWAII = 'Kawaii',
  CINEMATIC = 'Cinematic',
  DIGITAL_ART = 'Digital Art',
  NEON_FUTURISTIC = 'Neon Futuristic',
  JAPANESE_INK = 'Japanese Ink',
  COMIC_BOOK = 'Comic Book',
  PLAYGROUND = 'Playground',
  ANIME = 'Anime',
}
