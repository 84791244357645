import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import {
  Scene,
  Asset,
  SubtitlesConfig,
  Video,
  Timezone,
  SocialAccountMetadata,
} from '@monorepo/types';

export interface UpdateSocialPublishParams {
  videoId: string;
  socialAccountId: string;
  timezone: Timezone;
  publishAt?: Date;
  metadata: SocialAccountMetadata;
}

const routeName = 'video';

class VideoService extends CrudService<Video> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    const route = routeName;

    super({ route: routeName, httpService });

    this.route = route;
  }

  async getScenes(videoId: string) {
    const response = await this.httpService.get<BaseResponse<Scene[]>>(
      `${this.path}/${videoId}/scenes`
    );

    return response.data;
  }

  async getProgress(videoId: string) {
    const response = await this.httpService.get<
      BaseResponse<
        Pick<
          Video,
          | 'progress'
          | 'isRendering'
          | 'renderProgress'
          | 'renderRequested'
          | 'renderStartDate'
        >
      >
    >(`${this.path}/${videoId}/progress`);

    return response.data;
  }

  async render(videoId: string) {
    const response = await this.httpService.get<BaseResponse<boolean>>(
      `${this.path}/${videoId}/render`
    );

    return response.data;
  }

  async download(videoId: string) {
    const response = await this.httpService.get<Blob>(
      `${this.path}/${videoId}/download`,
      {
        responseType: 'blob',
      }
    );

    return response;
  }

  async updateScene(
    sceneId: string,
    videoId: string,
    updateDto: Partial<Scene>
  ) {
    const response = await this.httpService.post<
      Partial<Scene>,
      BaseResponse<Scene>
    >(`${this.path}/${videoId}/scene/${sceneId}`, updateDto);

    return response.data;
  }

  async updateSubtitles(videoId: string, subtitlesConfig: SubtitlesConfig) {
    const response = await this.httpService.post<
      SubtitlesConfig,
      BaseResponse<boolean>
    >(`${this.path}/${videoId}/subtitles`, subtitlesConfig);

    return response.data;
  }

  async updateMetadata(
    videoId: string,
    metadata: Pick<Video, 'title' | 'description'>
  ) {
    const response = await this.httpService.post<
      Pick<Video, 'title' | 'description'>,
      BaseResponse<boolean>
    >(`${this.path}/${videoId}/metadata`, metadata);

    return response.data;
  }

  async updateVoiceover(
    videoId: string,
    voiceoverConfig: { voiceoverId: string }
  ) {
    const response = await this.httpService.post<
      { voiceoverId: string },
      BaseResponse<boolean>
    >(`${this.path}/${videoId}/voiceover`, voiceoverConfig);

    return response.data;
  }

  async updateBackgroundMusic(videoId: string, musicId?: string) {
    const response = await this.httpService.post<
      { musicId?: string },
      BaseResponse<boolean>
    >(`${this.path}/${videoId}/background-music`, { musicId });

    return response.data;
  }

  async updateSocialPublish({
    videoId,
    socialAccountId,
    timezone,
    publishAt,
    metadata,
  }: UpdateSocialPublishParams) {
    const response = await this.httpService.post<
      Omit<UpdateSocialPublishParams, 'videoId'>,
      BaseResponse<boolean>
    >(`${this.path}/${videoId}/social-publish`, {
      socialAccountId,
      publishAt,
      timezone,
      metadata,
    });

    return response.data;
  }
}

export default VideoService;
