import React, { FunctionComponent } from 'react';
import SocialAccountSelect, {
  SocialAccountSelectProps,
} from '../social-accounts/social-account';
import { FormField, FormFieldProps } from '@monorepo/react-components';

export const FormSocialSelect: FunctionComponent<
  Omit<FormFieldProps, 'render'> & SocialAccountSelectProps
> = (formFieldProps) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        const { ref, ...rest } = field;

        return (
          <SocialAccountSelect {...rest} onChange={formFieldProps.onChange} />
        );
      }}
    />
  );
};
