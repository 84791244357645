import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import AuthLayout from './auth-layout';
import {
  FormInput,
  Space,
  useLoading,
  LoadingState,
} from '@monorepo/react-components';
import { formEmailValidation } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const form = useForm<ForgotPasswordFormData>();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);
  const notificationApi = useNotification();
  const {
    dataStore: { userStore },
  } = useStore();

  const onSubmit = async (data: ForgotPasswordFormData) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.forgetPassword(data.email);

      notificationApi.success({
        placement: 'top',
        message: 'Email Sent',
        description:
          'A password reset link has been sent to your email address.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description: 'Failed to send password reset email. Please try again.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <AuthLayout title="Forgot Password">
      <Form onFinish={form.handleSubmit(onSubmit)} layout="vertical">
        <Space size={'large'} direction={'vertical'}>
          <FormInput
            placeholder="Email"
            form={form}
            controllerProps={{
              name: 'email',
              rules: formEmailValidation(),
            }}
          />
          <Button
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
            block
          >
            Send Reset Link
          </Button>
        </Space>
      </Form>
      <div className="auth-link">
        Remembered your password? <Link to="/login">Login here</Link>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
