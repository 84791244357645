import { useEffect, useState } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const PlayPause = () => {
  const { playerRef, isPlayerReady } = useVideoContext();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const { current } = playerRef;
    setPlaying(current?.isPlaying() ?? false);

    if (!current) return;

    const onPlay = () => {
      setPlaying(true);
    };

    const onPause = () => {
      setPlaying(false);
    };

    current.addEventListener('play', onPlay);
    current.addEventListener('pause', onPause);

    return () => {
      current.removeEventListener('play', onPlay);
      current.removeEventListener('pause', onPause);
    };
  }, [isPlayerReady]);

  const onToggle = () => {
    playerRef.current?.toggle();
  };

  return (
    <Button onClick={onToggle} type="text">
      {playing ? <PauseOutlined /> : <PlayCircleOutlined />}
    </Button>
  );
};

export default PlayPause;
